import React, { useEffect, useState } from "react";

import { Checkbox, Form, Input } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  RegisterUserConfig,
  ResetConfig,
} from "../../Connection/server/config/CrudUrls";
import GetMoreButton from "../Button/GetMoreButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function SetPasswordForm({
  setKey,
  data,
  render,
  key,
  reset,
  setKeyMain,
  // setTab,
}) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (val) => {
    setLoading(true);
    let obj = {
      password: val.password,
      phone_number: data.phone_number,
      token: data.token,
    };

    if (reset) {
      ResetConfig(obj)
        .then((res) => {
          toast.success(langObj.password_changed);
          setKeyMain();
          setKey("1");
        })
        .finally(() => setLoading(false));
    } else {
      RegisterUserConfig({ ...obj, name: val.name })
        .then((res) => {
          toast.success(langObj.royxatdan_otdingiz);
          // setTab("1");
          setKey("1");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setLoading(false);
    form.resetFields();
  }, [render, key]);

  return (
    <>
      {reset && (
        <>
          <h2>{langObj.enter_new_password}</h2>
          <br />
        </>
      )}
      <div className="login-main-button-back" onClick={() => setKey("1")}>
        <RightOutlined />
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {!reset && (
          <Form.Item
            name="name"
            label={langObj.ismingizni_kiriting}
            rules={[
              {
                required: true,
                message: langObj.ismingizni_kiriting,
              },
            ]}
          >
            <Input size="large" placeholder={langObj.ismingizni_kiriting} />
          </Form.Item>
        )}

        <Form.Item
          name="password"
          label={langObj.parolni_kiriting}
          rules={[
            {
              required: true,
              message: langObj.parolni_kiriting,
            },
            {
              min: 6,
              message: langObj.password_error,
            },
          ]}
          hasFeedback
        >
          <Input.Password size="large" placeholder={langObj.parolni_kiriting} />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={langObj.parolni_qayta_kiriting}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: langObj.parolni_qayta_kiriting,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(langObj.parol_mos_emas));
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            placeholder={langObj.parolni_qayta_kiriting}
          />
        </Form.Item>

        {!reset && (
          <Form.Item
            name="agreement"
            key="agreement_form_item"
            className="writeResume_universitet__checked"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error(langObj.privacy)),
              },
            ]}
          >
            <Checkbox>
              <div className="aferta_text">
                {langObj.aferta1}
                <a
                  href="https://simyogoch-privacy-policy.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {langObj.aferta2}{" "}
                </a>
                {langObj.aferta3}
              </div>
            </Checkbox>
          </Form.Item>
        )}

        <GetMoreButton text={langObj.tasdiqlash} loading={loading} />
      </Form>
    </>
  );
}

export default SetPasswordForm;
