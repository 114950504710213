import { Alert, Form } from "antd";
import { PaymentStep2Config } from "../../Connection/server/config/CrudUrls";
import { RightOutlined } from "@ant-design/icons";
import React from "react";
import BasicButton from "../Button/BasicButton";
import OtpInput from "../Input/OtpInput";

function PayingStep2({ setKey, response, langObj }) {
  const [form] = Form.useForm();
  const changeValue = (val) => {
    form.setFieldsValue({ code: val });
  };
  const submitFunc = () => {
    form.validateFields().then((value) => {
      let obj = {
        card_token: response.card_token,
        code: value.code,
        amount: "1000",
        object_type: "card",
      };
      setKey("3");
      PaymentStep2Config(obj).then((res) => {
        setKey("3");
      });
    });
  };
  return (
    <div className="paying_section">
      <div className="paying_back_button" onClick={() => setKey("1")}>
        <RightOutlined />
      </div>

      <p>{langObj.confirm_payment}</p>
      <Alert
        message={`${langObj.payment_alert} ${response.phone_number} ${langObj.payment_alert2}`}
        type="info"
      />
      <br />
      <Form form={form} layout="vertical">
        <Form.Item
          label={langObj.tasdiqlash_kodi}
          name="code"
          rules={[
            {
              required: true,
              message: langObj.tasdiqlash_kodi,
            },
            {
              min: 5,
              message: langObj.sms_message2,
            },
          ]}
        >
          <OtpInput changeValue={changeValue} />
        </Form.Item>
      </Form>
      <BasicButton
        className="paying_button"
        text={langObj.tasdiqlash}
        onClick={submitFunc}
        fullWidth
        html
      />
    </div>
  );
}

export default PayingStep2;
