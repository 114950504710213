import { combineReducers } from "redux";
import changeAdvertDataReducer from "./advertData";
import changeBurgerReducer from "./burger";
import changeCountReducer from "./drower";
import changeLanguageReducer from "./language";
import changeNoticeReducer from "./notice";
import changeRenderReducer from "./render";
import changeStatusReducer from "./status";

const allReducers = combineReducers({
  drawer: changeCountReducer,
  status: changeStatusReducer,
  advertData: changeAdvertDataReducer,
  burger: changeBurgerReducer,
  notice: changeNoticeReducer,
  lang: changeLanguageReducer,
  render: changeRenderReducer,
});
export default allReducers;
