import React from "react";

import { Modal } from "antd";
import { toast } from "react-toastify";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const SeeMoreFunc = (informs) => {
  toast.warning(`${informs}ni ko'rish uchun, Iltimos avval tizimga kiring.`, {
    autoClose: 2000,
    position: toast.POSITION.TOP_CENTER,
    style: { fontSize: "20px", lineHeight: "20px", width: "120%" },
  });
  setTimeout(() => {
    window.location.href = "/signin";
  }, 2500);
};

export const makeNum = (number) => {
  let num = number;
  if (num === "1000") return 999;
  return num;
};

const closeModal = () => {
  Modal.destroyAll();
};

export const ConfirmModal = (title, okText, cancelText, okFunc) => {
  Modal.confirm({
    icon: <ExclamationCircleOutlined />,
    maskClosable: true,
    content: (
      <div className="profile_delete__content">
        <p>{title}</p>
        <div className="profile_delete__buttons">
          <button onClick={closeModal}>{cancelText}</button>
          <button
            onClick={() => {
              closeModal();
              okFunc();
            }}
          >
            {okText}
          </button>
        </div>
      </div>
    ),
  });
};
