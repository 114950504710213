import React, { useEffect, useState } from "react";
import "../Styles/Filter.css";
import searchIcon from "../Images/searchButton.png";
import { Select, Modal } from "antd";
import FilterContent from "./FilterContent";
import {
  GetDistrictsConfig,
  GetDistrictsRestConfig,
  GetRegionsConfig,
} from "../Connection/server/config/CrudUrls";
import { useSelector } from "react-redux";
import { langData } from "../Languages";
import { ReactComponent as FilterSvg } from "../Images/Svg/filter.svg";
import { useLocation, useSearchParams } from "react-router-dom";

function Filter({ handleMakeParams }) {
  const lan = useSelector((state) => state.lang);
  const render = useSelector((state) => state.render);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  let langObj = langData[lan];

  const { Option } = Select;

  const [regions, setRegions] = useState([]);
  const [regionId, setRegionId] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState(null);
  const [value, setValue] = useState("");

  const getRegions = () => {
    GetRegionsConfig().then((res) => {
      let arr = res.data.results;
      arr.push({ id: 15, name: "Barcha viloyatlar" });
      setRegions(arr.reverse());

      //Initial values
      setValue(searchParams.get("search") ? searchParams.get("search") : "");
      let regId = +searchParams.get("region");
      let disId = +searchParams.get("district");
      setRegionId(regId ? regId : null);
      setDistrictId(disId ? disId : null);
      regId && getDistricts(regId);
    });
  };

  const changeRegion = (id) => {
    setRegionId(id);
    if (id === 15) {
      setDistricts([]);
      handleMakeParams("region", "");
    } else {
      handleMakeParams("region", id);
    }
    handleMakeParams("district", null);
    // getAdverts();

    setDistrictId(null);
    id !== 15 && getDistricts(id);
  };

  const getDistricts = (id) => {
    GetDistrictsConfig(id).then((res) => {
      let arr = res.data.results;
      if (res.data.next) {
        GetDistrictsRestConfig(id).then((value) => {
          let arr2 = value.data.results;
          let arr3 = arr2.concat(arr);
          setDistricts(arr3);
        });
      } else {
        setDistricts(arr);
      }
    });
  };

  const changeDistrict = (id) => {
    setDistrictId(id);
    handleMakeParams("district", id);
  };
  const filterPress = (e) => {
    if (e.key === "Enter") {
      handleMakeParams("search", value);
    }
  };
  const filterClick = () => {
    handleMakeParams("search", value);
  };
  useEffect(() => {
    getRegions();
  }, [render]);

  const showFilterModal = () => {
    Modal.success({
      visible: true,
      content: (
        <FilterContent
          searchParams={searchParams}
          langObj={langObj}
          handleMakeParams={handleMakeParams}
        />
      ),
      width: "400px",
      maskClosable: true,
      okText: langObj.filter,
    });
  };

  return (
    <div className="filter_section">
      <div className="filter_search__content">
        <input
          onKeyPress={filterPress}
          onChange={(e) => setValue(e.target.value)}
          className="filter_search__input"
          size="large"
          value={value}
          placeholder={
            location.pathname === "/searchworker"
              ? langObj.ishchi_qidirish
              : langObj.ish_qidirish
          }
        />
        <img
          onClick={filterClick}
          className="filter_search__icon"
          src={searchIcon}
          alt="error img"
        />
      </div>
      <div className="filter_location__content">
        <button className="filter_location__button">
          <Select
            size="large"
            value={regionId}
            onChange={changeRegion}
            placeholder={langObj.viloyat}
            className="filter_select"
            key="filterfirstselect"
            style={{ marginRight: "10px" }}
          >
            {regions.map((item) => {
              return (
                <Option value={item.id} key={item.name}>
                  {item.name}
                </Option>
              );
            })}
          </Select>{" "}
          <Select
            onChange={changeDistrict}
            size="large"
            className="filter_select"
            placeholder={langObj.tuman}
            key="filtersecondselect"
            value={districtId}
            allowClear
          >
            {districts.map((item) => {
              return (
                <Option value={item.id} key={item.name}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </button>
      </div>
      <div className="filter_location__button filter_modal_div">
        <FilterSvg
          className="filter_location__icon"
          onClick={showFilterModal}
        />
      </div>
    </div>
  );
}

export default Filter;
