import React, { useState } from "react";
import "../../Styles/Paying.css";

import { Tabs } from "antd";
import PayingStep1 from "./Step1";
import PayingStep2 from "./Step2";
import PayingStep3 from "./Step3";

function Paying({ langObj }) {
  const [key, setKey] = useState("1");
  const [response, setResponse] = useState({});
  return (
    <Tabs defaultActiveKey="1" className="paying_tab" activeKey={key}>
      <Tabs.TabPane key="1">
        <PayingStep1
          langObj={langObj}
          setKey={() => setKey("2")}
          setResponse={setResponse}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="2">
        <PayingStep2
          langObj={langObj}
          setKey={(val) => setKey(val)}
          response={response}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="3">
        <PayingStep3 langObj={langObj} setKey={setKey} />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default Paying;
