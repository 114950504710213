import React, { useState } from "react";

import Filter from "../Components/Filter";
import Works from "../Components/Works";

import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  GetAdvertisementConfig,
  GetAdvertsConfig,
} from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";
import { makeNum } from "../Functions";

function HomePage() {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [hasMore, setMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const handleMakeParams = (key, value) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const getAdverts = (load) => {
    if (!searchParams.has("page_size")) {
      searchParams.append("page_size", 20);
      setSearchParams(searchParams);
    }
    let url = "?";
    for (let key of searchParams.keys()) {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
    load && setLoading2(true);
    GetAdvertsConfig(url).then((res) => {
      if (res.data.results) {
        setMore(res.data.count > res.data.results.length);
        let arr1 = makePrices(res.data.results);
        let arr = [];
        GetAdvertisementConfig()
          .then((responce) => {
            let arr2 = responce.data.results;
            let count = 0;
            arr1.forEach((item, index) => {
              arr.push(item);
              if (index % 5 === 4) {
                arr.push(arr2[count % arr2.length]);
                count++;
              }
            });
            setData(arr);
          })
          .finally(() => {
            setLoading2(false);
            setLoading(false);
          });
      } else {
        setData([]);
        setMore(false);
        setLoading(false);
        setLoading2(false);
      }
    });
  };

  const makePrices = (arr1) => {
    arr1.forEach((item) => {
      if (item.from_sum >= 1000000000) {
        item.from_sum =
          makeNum((item.from_sum / 1000000000).toFixed(1)) + langObj.mlrd;
      } else if (item.from_sum >= 1000000) {
        item.from_sum =
          makeNum((item.from_sum / 1000000).toFixed(1)) + langObj.mln;
      } else if (item.from_sum >= 1000) {
        item.from_sum =
          makeNum((item.from_sum / 1000).toFixed(0)) + langObj.ming;
      } else if (item.from_sum >= 0) {
        item.from_sum = item.from_sum + langObj.sum;
      }
      if (item.to_sum >= 1000000000) {
        item.to_sum =
          makeNum((item.to_sum / 1000000000).toFixed(1)) + langObj.mlrd;
      } else if (item.to_sum >= 1000000) {
        item.to_sum = makeNum((item.to_sum / 1000000).toFixed(1)) + langObj.mln;
      } else if (item.to_sum >= 1000) {
        item.to_sum = makeNum((item.to_sum / 1000).toFixed(0)) + langObj.ming;
      } else if (item.to_sum >= 0) {
        item.to_sum = item.to_sum + langObj.sum;
      }
    });
    return arr1;
  };

  return (
    <div>
      <Filter handleMakeParams={handleMakeParams} />
      <Works
        data={data}
        handleMakeParams={handleMakeParams}
        getAdverts={getAdverts}
        loading={loading}
        loading2={loading2}
        hasMore={hasMore}
      />
    </div>
  );
}

export default HomePage;
