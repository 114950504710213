import { EyeOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { langData } from "../Languages";
import { changeAdvertData } from "../Redux/Actions";

function Advertisement({ item }) {
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  return (
    <div
      className="works_content works_advertize"
      style={{ padding: 0 }}
      key={item.id.toString()}
    >
      <div className="works_advertize__div">
        <img
          src={item.image}
          alt="error img"
          width={100}
          className="works_advertize__img"
          style={{ marginBottom: 10 }}
        />

        <p className="works_advertize__date">{item.title}</p>
        <div className="works_advertize_description" style={{ margin: 0 }}>
          {item.description.slice(0, 80)}
          {item.description.length > 80 ? "..." : ""}
        </div>
        <div className="works_footer">
          <div className="works_views">
            <Link
              onClick={() => dispatch(changeAdvertData(item))}
              to={`/advertize?id=${item.id}`}
            >
              <div
                className="works-btn works-btn-arrow-slide"
                style={{ float: "right" }}
              >
                <button className="works-btn-arrow-slide-cont works-btn-arrow-slide-cont--green">
                  <span
                    className="works-btn-arrow-slide-circle"
                    aria-hidden="true"
                  >
                    <span className="works-btn-arrow-slide-icon works-btn-arrow-slide-arrow"></span>
                  </span>
                  <span className="works-btn-arrow-slide-text">
                    {langObj.batafsil}
                  </span>
                </button>
              </div>
            </Link>
            <div>
              <EyeOutlined />
              {item.views > 1000
                ? Math.round(item.views / 1000) + "K"
                : item.views}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advertisement;
