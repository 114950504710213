import React, { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Alert, Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { SendResetSmsConfig } from "../../Connection/server/config/CrudUrls";
import GetMoreButton from "../Button/GetMoreButton";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function ResetForm({ setKey, setKeyMain, setData, keyMain, render }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (val) => {
    let obj = val;
    obj.phone_number =
      "998" +
      val.phone_number
        .replace("(", "")
        .replace(")", "")
        .replaceAll(" ", "");

    setData(obj);

    setLoading(true);
    SendResetSmsConfig(obj)
      .then((res) => {
        setKey("2");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    form.resetFields();
    setLoading(false);
  }, [render, keyMain]);

  return (
    <>
      <h2>{langObj.parol_esdan_chiqdimi}</h2>
      <br />
      <div
        className="login-main-button-back"
        onClick={() => setKeyMain("login")}
      >
        <RightOutlined />
      </div>
      <Alert message={langObj.parol_esdan_chiqish_text} type="info" />
      <br />
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={langObj.telefon_raqamingiz}
          name="phone_number"
          rules={[
            {
              required: true,
              message: langObj.telfon_raqami_kiriting,
            },
            {
              pattern: /^\(\d{2}\) \d{3} \d{2} \d{2}$/,
              message: langObj.number_message,
            },
          ]}
        >
          <MaskedInput prefix="+998" mask={"(00) 000 00 00"} size="large" />
        </Form.Item>

        <GetMoreButton text={langObj.change} loading={loading} />
      </Form>
    </>
  );
}

export default ResetForm;
