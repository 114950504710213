import React, { useEffect, useState } from "react";
import "../Styles/Works.css";

import Loading from "../Components/Loading";
import Empty from "../Components/Empty";
import GetMoreButton from "../Components/Button/GetMoreButton";
import Advert from "../Components/Advert";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../Redux/Actions";
import { Breadcrumb } from "antd";
import { GetUsersAdvertsConfig } from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";
import { makeNum } from "../Functions";

function UsersAdverts() {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(40);
  const [hasMore, setMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  //Get user adverts
  const getUserAdverts = () => {
    setLoading(true);
    GetUsersAdvertsConfig().then((res) => {
      let arr = makePrices(res.data.results);
      setData(arr);
      setLoading(false);
      setMore(res.data.count > res.data.results.length);
    });
  };

  //Get more user adverts
  const getMoreFunc = () => {
    setLoading2(true);
    GetUsersAdvertsConfig(pageSize).then((res) => {
      let arr = makePrices(res.data.results);
      setData(arr);
      setLoading2(false);
      setMore(res.data.count > res.data.results.length);
      setPageSize(pageSize + 20);
    });
  };

  //Make prettier prices function
  const makePrices = (arr1) => {
    arr1.forEach((item) => {
      if (item.from_sum >= 1000000000) {
        item.from_sum =
          makeNum((item.from_sum / 1000000000).toFixed(1)) + langObj.mlrd;
      } else if (item.from_sum >= 1000000) {
        item.from_sum =
          makeNum((item.from_sum / 1000000).toFixed(1)) + langObj.mln;
      } else if (item.from_sum >= 1000) {
        item.from_sum =
          makeNum((item.from_sum / 1000).toFixed(0)) + langObj.ming;
      } else if (item.from_sum >= 0) {
        item.from_sum = item.from_sum + langObj.sum;
      }
      if (item.to_sum >= 1000000000) {
        item.to_sum =
          makeNum((item.to_sum / 1000000000).toFixed(1)) + langObj.mlrd;
      } else if (item.to_sum >= 1000000) {
        item.to_sum = makeNum((item.to_sum / 1000000).toFixed(1)) + langObj.mln;
      } else if (item.to_sum >= 1000) {
        item.to_sum = makeNum((item.to_sum / 1000).toFixed(0)) + langObj.ming;
      } else if (item.to_sum >= 0) {
        item.to_sum = item.to_sum + langObj.sum;
      }
    });
    return arr1;
  };

  useEffect(() => {
    getUserAdverts();
  }, []);

  return (
    <>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link
            to="/searchworks"
            key="writebulletinLink"
            onClick={() => dispatch(changeStatus("work"))}
          >
            {langObj.ish_qidirish}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{langObj.mening_ish_elonlarim}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="works_section" style={{ marginTop: "40px" }}>
        {loading ? (
          <Loading count={8} />
        ) : data.length > 0 ? (
          data.map((item) => {
            return <Advert item={item} getFunc={getUserAdverts} user={true} />;
          })
        ) : (
          <Empty text={langObj.user_empty} />
        )}
        {loading2 && <Loading count={4} />}
        {!loading && hasMore && <GetMoreButton getMoreFunc={getMoreFunc} />}
      </div>
    </>
  );
}

export default UsersAdverts;
