import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { SendSmsConfig } from "../../Connection/server/config/CrudUrls";

import GetMoreButton from "../Button/GetMoreButton";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function SignupForm({ setKey, setData, tab }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (val) => {
    const obj = val;
    obj.phone_number =
      "998" +
      val.phone_number
        .replace("(", "")
        .replace(")", "")
        .replaceAll(" ", "");

    setData(obj);
    setLoading(true);
    SendSmsConfig(obj)
      .then((res) => {
        setKey("2");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(false);
    form.resetFields();
  }, [tab]);

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        label={langObj.telefon_raqamingiz}
        name="phone_number"
        rules={[
          {
            required: true,
            message: langObj.telfon_raqami_kiriting,
          },
          {
            pattern: /^\(\d{2}\) \d{3} \d{2} \d{2}$/,
            message: langObj.number_message,
          },
        ]}
      >
        <MaskedInput prefix="+998" mask={"(00) 000 00 00"} size="large" />
      </Form.Item>

      <GetMoreButton text={langObj.royxatdan_otish} loading={loading} />
    </Form>
  );
}

export default SignupForm;
