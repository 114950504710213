import React, { useEffect, useState } from "react";
import "../Styles/WriteBulletin.css";
import {
  Breadcrumb,
  Form,
  Select,
  Input,
  Checkbox,
  Radio,
  TimePicker,
  InputNumber,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../Redux/Actions";
import {
  CreateAdvertsConfig,
  EditAdvertsConfig,
  GetAdvertsByIdConfig,
  GetDistrictsConfig,
  GetDistrictsRestConfig,
  GetRegionsConfig,
  GetUsersAdvertsConfig,
} from "../Connection/server/config/CrudUrls";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { langData } from "../Languages";
import { userData } from "../Connection/server/host";
import { MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import BasicButton from "../Components/Button/BasicButton";

function WriteBulletin() {
  const navigate = useNavigate();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const location = useLocation();
  const advertId = location.pathname.split("&")[1];
  const dispatch = useDispatch();
  const workDay_data = [
    langObj.day[1],
    langObj.day[2],
    langObj.day[3],
    langObj.day[4],
    langObj.day[5],
    langObj.day[6],
    langObj.day[7],
  ];
  const { Option } = Select;
  const [leftForm] = Form.useForm();
  const [rightForm] = Form.useForm();
  const format = "HH:mm";
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const sendAdverts = () => {
    let error = 1;
    leftForm.validateFields().then((value) => {
      rightForm.validateFields().then((value2) => {
        error = 20;
        let obj = { ...value, ...value2, type: "job" };
        let {
          from_time,
          keyword,
          keywordArr,
          to_time,
          working_days,
          sex,
        } = obj;
        let keyArr = [keyword];
        console.log(obj);
        keywordArr &&
          keywordArr.forEach((item) => {
            if (item) if (item.replaceAll(" ", "")) keyArr.push(item);
          });
        if (sex.length === 1) obj.sex = sex[0];
        else if (sex.length === 2 || sex.length === 0) obj.sex = null;
        obj.keyword = keyArr;
        obj.from_time = from_time._d.toString().split(" ")[4];
        obj.to_time = to_time._d.toString().split(" ")[4];
        if (working_days.length === 0) {
          obj.working_days = null;
        } else
          obj.working_days = obj.working_days.sort(function(a, b) {
            return a - b;
          });
        if (+obj.to_sum >= +obj.from_sum) {
          delete obj["keywordArr"];
          if (advertId) {
            EditAdvertsConfig(advertId, obj).then((res) => {
              navigate("/user/adverts");
              toast.success(langObj.ish_eloni_tahrirlandi, {
                autoClose: 2000,
              });
            });
          } else {
            CreateAdvertsConfig(obj).then((res) => {
              navigate("/searchworks");
              toast.success(langObj.ish_eloni_qoshildi, { autoClose: 2000 });
              leftForm.resetFields();
              rightForm.resetFields();
            });
          }
        } else {
          message.error(
            "Maksimal ish xaqi minimal ish xaqidan ko'p bo'lishi kerak"
          );
        }
      });
    });
    setTimeout(() => {
      if (error === 1) toast.error(langObj.dataError, { autoClose: 2000 });
    }, 100);
  };
  const getRegions = () => {
    GetRegionsConfig().then((res) => {
      let arr = res.data.results.reverse();
      setRegions(arr);
    });
  };
  const changeRegion = (id, no) => {
    if (no !== "no") leftForm.setFieldsValue({ district: null });
    GetDistrictsConfig(id).then((res) => {
      let arr = res.data.results;
      if (res.data.next) {
        GetDistrictsRestConfig(id).then((value) => {
          let arr2 = value.data.results;
          let arr3 = arr2.concat(arr);
          setDistricts(arr3);
        });
      } else {
        setDistricts(arr);
      }
    });
  };
  const getAdvertsById = () => {
    GetAdvertsByIdConfig(advertId).then((res) => {
      let obj = res.data;
      let {
        about,
        district,
        experience,
        from_age,
        from_sum,
        from_time,
        is_has_diploma,
        is_negotiate,
        job_type,
        keyword,
        name,
        region,
        sex,
        to_age,
        to_sum,
        to_time,
        working_days,
        addition_number,
        address,
      } = obj;
      leftForm.setFieldsValue({
        address,
        district,
        from_sum,
        from_time: moment(from_time, "HH:mm"),
        is_negotiate,
        job_type,
        name,
        region,
        to_sum,
        to_time: moment(to_time, "HH:mm"),
        working_days: working_days ? working_days : [],
      });
      let arr = keyword;
      let key = arr.shift();
      rightForm.setFieldsValue({
        about,
        from_age,
        is_has_diploma,
        keyword: key,
        keywordArr: arr,
        to_age,
        experience,
        sex: sex ? sex : [],
        addition_number: addition_number
          ? addition_number
          : userData.phone_number,
      });
      console.log(arr);
      changeRegion(region, "no");
    });
  };

  useEffect(() => {
    getRegions();
    GetUsersAdvertsConfig();
    if (advertId) getAdvertsById();
    else {
      leftForm.resetFields();
      rightForm.resetFields();
    }
  }, []);

  const leftData = [
    {
      label: langObj.ish_nomi,
      name: "name",
      message: langObj.ish_nomi_error,
      element: <Input />,
      placeholder: langObj.ish_nomi_placeholder,
    },
    {
      label: langObj.ish_turi,
      name: "job_type",
      message: langObj.ish_turi_error,
      element: (
        <Radio.Group className="writeBulletin_workType" size="large">
          <Radio value="one-time">{langObj.bir_martalik}</Radio>
          <Radio value="permanent">{langObj.doimiy}</Radio>
          <Radio value="online">{langObj.onlayn}</Radio>
          <Radio value="half-time">{langObj.yarim_stavka}</Radio>
        </Radio.Group>
      ),
    },
    {
      location: true,
    },
    {
      label: langObj.toliq_adres,
      name: "address",
      element: <Input />,
    },
    {
      half: true,
      label: langObj.ish_kunlari,
      name: "working_days",
      class: "writeBulletin_workDay",
      style: { marginBottom: "-5px" },
      message: false,
      element: (
        <Checkbox.Group>
          <div className="checkbox_row">
            {workDay_data.map((item, index) => {
              return (
                <div className="checkbox_col">
                  <Checkbox
                    value={index + 1}
                    style={{ lineHeight: "30px" }}
                    key={index.toString() + "workday"}
                  >
                    {item}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </Checkbox.Group>
      ),
    },
    {
      half: true,
      label: langObj.ish_vaqti,
      difficult: true,
      style: { marginBottom: 0 },
      itemStyle: { display: "inline-block" },
      item1: {
        name: "from_time",
        element: (
          <TimePicker
            format={format}
            className="writeBulletin_timePicker"
            size="large"
          />
        ),
      },
      item2: {
        name: "to_time",
        element: (
          <TimePicker
            format={format}
            className="writeBulletin_timePicker"
            size="large"
          />
        ),
      },
    },
    {
      label: langObj.ish_haqqi,
      difficult: true,
      style: { marginBottom: 0 },
      itemClass: "writeBulletin_wotkPrice__inputs",
      handleRequired: true,
      item1: {
        name: "from_sum",
        element: (
          <InputNumber
            min={1}
            placeholder="Min"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
        message: langObj.min_error,
      },
      item2: {
        name: "to_sum",
        element: (
          <InputNumber
            min={1}
            placeholder="Max"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
        message: langObj.max_error,
      },
    },
    {
      name: "is_negotiate",
      valuePropName: "checked",
      class: "writeResume_universitet__checked",
      element: <Checkbox>{langObj.kelishiladi}</Checkbox>,
    },
  ];
  const rightData = [
    {
      label: langObj.tajriba,
      name: "experience",
      element: (
        <Radio.Group className="writeBulletin_workType" size="large">
          <Radio value="optional">{langObj.kerak_emas}</Radio>
          <Radio value="primary">{langObj.boshlangich}</Radio>
          <Radio value="middle">{langObj.ortacha}</Radio>
          <Radio value="high">{langObj.yuqori}</Radio>
        </Radio.Group>
      ),
    },
    {
      half: true,
      name: "sex",
      message: false,
      element: (
        <Checkbox.Group>
          <div
            className="checkbox_row writeResume_universitet__checked"
            style={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
          >
            <div className="writeBulletin_jins">{langObj.jinsi}</div>
            <div className="checkbox_row">
              <Checkbox
                value="woman"
                style={{ lineHeight: "30px" }}
                key="genderWoman"
              >
                {langObj.ayol}
              </Checkbox>
              <Checkbox
                value="man"
                style={{ lineHeight: "30px" }}
                key="genderMan"
              >
                {langObj.erkak}
              </Checkbox>
            </div>
          </div>
        </Checkbox.Group>
      ),
    },
    {
      half: true,
      label: langObj.yosh,
      difficult: true,
      style: { marginBottom: 0 },
      itemClass: "writeBulletin_wotkPrice__inputs",
      item1: {
        name: "from_age",
        element: <Input type="number" placeholder="18" />,
      },
      item2: {
        name: "to_age",
        element: <Input type="number" placeholder="55" />,
      },
    },
    {
      name: "is_has_diploma",
      valuePropName: "checked",
      class: "writeResume_universitet__checked",
      element: <Checkbox>{langObj.diplom}</Checkbox>,
    },
    {
      label: langObj.ish_haqida,
      name: "about",
      message: langObj.ish_haqida_error,
      element: (
        <Input.TextArea
          rows={7}
          placeholder={langObj.ish_haqida_placeholder}
          className="writeBulletin_description"
        />
      ),
    },
    {
      label: langObj.telefon_raqamingiz,
      name: "addition_number",
      element: <Input />,
    },
  ];

  return (
    <div>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link
            to="/searchworks"
            key="writebulletinLink"
            onClick={() => dispatch(changeStatus("work"))}
          >
            {langObj.ish_qidirish}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{langObj.elon_berish2}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="form_container">
        <h1 className="container_title">
          {advertId ? langObj.elonni_tahrirlash : langObj.ish_eloni_yozish}
        </h1>
        <div className="writeBulletin_section">
          <div className="container_title2">
            {langObj.elon_malumotlarini_kiriting}
          </div>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            initialValues={{
              job_type: "permanent",
              is_negotiate: true,
              from_time: moment("08:00", format),
              to_time: moment("16:00", format),
              working_days: [],
            }}
            key="writebulletinfirstform"
            form={leftForm}
            className="form_display"
          >
            {leftData.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className={item.half ? "half_width" : "full_width"}
                >
                  {item.location ? (
                    <div
                      className="writeBulletin_location"
                      key="writeBulletin_location"
                    >
                      <div>
                        <Form.Item
                          name="region"
                          key="writebulletinregion"
                          label={langObj.viloyat}
                          rules={[
                            {
                              required: true,
                              message: langObj.viloyat_error,
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            onChange={changeRegion}
                            placeholder={langObj.viloyat}
                            className="filter_select"
                            key="region_select"
                          >
                            {regions.map((res, index) => {
                              return (
                                <Option
                                  className="filter_option"
                                  value={res.id}
                                  key={res.name}
                                >
                                  {res.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div span={12}>
                        <Form.Item
                          name="district"
                          key="writebulletindistrict"
                          label={langObj.tuman}
                          rules={[
                            {
                              required: true,
                              message: langObj.tuman_error,
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            placeholder={langObj.tuman}
                            className="filter_select"
                            key="district_region"
                          >
                            {districts.map((res, index) => {
                              return (
                                <Option
                                  className="filter_option"
                                  value={res.id}
                                  key={res.name}
                                >
                                  {res.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  ) : item.difficult ? (
                    <div key={item.label}>
                      <p
                        className={
                          item.handleRequired
                            ? "label_p required_label"
                            : "label_p"
                        }
                      >
                        {item.label}
                      </p>
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          name={item.item1.name}
                          key={item.item1.name}
                          className={item.itemClass}
                          rules={[
                            {
                              required: item.item1.message,
                              message: item.item1.message,
                            },
                          ]}
                        >
                          {item.item1.element}
                        </Form.Item>
                        <span
                          style={{
                            display: "inline-block",
                            width: "24px",
                            lineHeight: "32px",
                            textAlign: "center",
                          }}
                        >
                          -
                        </span>
                        <Form.Item
                          name={item.item2.name}
                          className={item.itemClass}
                          key={item.item2.name}
                          rules={[
                            {
                              required: item.item2.message,
                              message: item.item2.message,
                            },
                          ]}
                        >
                          {item.item2.element}
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    <Form.Item
                      name={item.name}
                      label={item.label}
                      className={item.class}
                      key={item.name}
                      style={item.style}
                      valuePropName={item.valuePropName}
                      placeholder={item.placeholder}
                      rules={[
                        { required: item.message, message: item.message },
                      ]}
                    >
                      {item.element}
                    </Form.Item>
                  )}
                </div>
              );
            })}
          </Form>

          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            initialValues={{
              experience: "optional",
              is_has_diploma: false,
              sex: [""],
              addition_number: userData.phone_number,
            }}
            key="writebulletinsecondform"
            form={rightForm}
            className="form_display"
          >
            {rightData.map((item, index) => {
              return (
                <div
                  key={`${item.name}rightData`}
                  className={item.half ? "half_width" : "full_width"}
                >
                  {item.difficult ? (
                    <>
                      <p className="label_p">{item.label}</p>
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          className={item.itemClass}
                          name={item.item1.name}
                          key={index + "writebulletinseconddifficult1"}
                        >
                          {item.item1.element}
                        </Form.Item>
                        <span
                          style={{
                            display: "inline-block",
                            width: "24px",
                            lineHeight: "32px",
                            textAlign: "center",
                          }}
                        >
                          -
                        </span>
                        <Form.Item
                          className={item.itemClass}
                          name={item.item2.name}
                          key={index + "writebulletinseconddifficult2"}
                        >
                          {item.item2.element}
                        </Form.Item>
                      </div>
                    </>
                  ) : (
                    <Form.Item
                      name={item.name}
                      label={item.label}
                      className={item.class}
                      key={index + "writebulletinsecondformitem"}
                      style={item.style}
                      valuePropName={item.valuePropName}
                      rules={[
                        { required: item.message, message: item.message },
                      ]}
                    >
                      {item.element}
                    </Form.Item>
                  )}
                </div>
              );
            })}

            <Form.Item
              name="keyword"
              className="full_width"
              label={langObj.qidiruvdagi_sozlar}
              rules={[
                { required: true, message: langObj.qidiruvdagi_sozlar_error },
              ]}
            >
              <Input placeholder={langObj.qidiruvdagi_placeholder} />
            </Form.Item>

            <Form.List name="keywordArr" className="full_width">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <Form.Item
                      required={false}
                      key={field.key}
                      className="full_width"
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        noStyle
                      >
                        <Input
                          placeholder={langObj.qidiruvdagi_placeholder}
                          className="keyword_width"
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    </Form.Item>
                  ))}

                  <BasicButton
                    text={langObj.qidiruvdagi_button}
                    onClick={() => add()}
                    style={{ justifyContent: "flexStart", marginTop: 0 }}
                  />
                </>
              )}
            </Form.List>
          </Form>
        </div>
        <div className="buttons-section writeBulletin_buttons">
          <div className="btn-box">
            <div className="btn-container">
              <button
                onClick={sendAdverts}
                className="btn btn-moving-gradient btn-moving-gradient--green"
              >
                {advertId ? langObj.edit : langObj.filter}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WriteBulletin;
//https://reactjsexample.com/a-time-picker-react-component-no-jquery-rely/
