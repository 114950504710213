import React, { useState } from "react";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal } from "antd";
import { DeleteSvg, EditSvg } from "../../assets/svg";
import { DeleteMessageConfig } from "../../Connection/server/config/CrudUrls";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function Message({
  data,
  senderId,
  setMessage,
  setEditData,
  getForDeleteFunc,
  forceFocusInput,
}) {
  const [loading, setLoading] = useState(false);
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const menu = (
    <div className="chatmessage_more_content">
      <div className="chatmessage_more_box" onClick={() => handleEdit()}>
        <EditSvg /> {langObj.edit}
      </div>
      <div className="chatmessage_more_box" onClick={() => confirm()}>
        <DeleteSvg /> {langObj.delete}
      </div>
    </div>
  );

  const confirm = () => {
    console.log("aaa");
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      className: "chatmessage_confirm_modal",
      content: (
        <div>
          <h2>{langObj.want_delete_message}</h2>
          <div className="chatmessage_confirm_buttons">
            <Button onClick={closeModal}>{langObj.bekor_qilish}</Button>
            <Button onClick={handleDelete} type="primary" loading={loading}>
              {langObj.ha}
            </Button>
          </div>
        </div>
      ),
      width: 320,
    });
  };

  const closeModal = () => {
    Modal.destroyAll();
  };

  const handleEdit = () => {
    setMessage(data.message);
    setEditData(data);
    forceFocusInput();
  };

  const handleDelete = () => {
    setLoading(true);
    DeleteMessageConfig(data.id)
      .then((res) => {
        getForDeleteFunc(data.id);
      })
      .finally(() => {
        closeModal();
        setLoading(false);
      });
  };

  return (
    <div
      className={
        data.receiver.id === senderId
          ? "chatmessage_content_div chatmessage_mine"
          : "chatmessage_content_div chatmessage_notmine"
      }
      id="chatRoom"
    >
      <div className="chatmessage_wrap">
        {data.sender.id === senderId && (
          <div className="chatmessage_person_img">
            {data.sender.photo ? (
              <img src={data.sender.photo} alt="error img" />
            ) : (
              <div className="chatmessage_person_img_name">
                {data.sender.name[0]}
              </div>
            )}
          </div>
        )}
        <div className="chatmessage_box">
          <p>{data.message}</p>
          <p>
            {data.is_edited && (
              <span className="chatmessage_box__edited">tahrirlandi</span>
            )}
            {data.created_date.slice(11)}
            <span className="chatmessage_isread">
              {data.is_read && <CheckOutlined />} <CheckOutlined />
            </span>
          </p>
        </div>
      </div>
      {data.receiver.id === senderId && (
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <MoreOutlined className="chatmessage_more" />
        </Dropdown>
      )}
    </div>
  );
}

export default Message;
