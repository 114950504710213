import React from "react";

export const SearchIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
        stroke="black"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 21L15 15"
        stroke="black"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const ModalCancelSvg = ({ className, onClick }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <g opacity="0.7">
      <path
        d="M12.5607 10.4393C11.9749 9.85355 11.0251 9.85355 10.4393 10.4393C9.85355 11.0251 9.85355 11.9749 10.4393 12.5607L17.8787 20L10.4393 27.4393C9.85355 28.0251 9.85355 28.9749 10.4393 29.5607C11.0251 30.1464 11.9749 30.1464 12.5607 29.5607L20 22.1213L27.4393 29.5607C28.0251 30.1464 28.9749 30.1464 29.5607 29.5607C30.1464 28.9749 30.1464 28.0251 29.5607 27.4393L22.1213 20L29.5607 12.5607C30.1464 11.9749 30.1464 11.0251 29.5607 10.4393C28.9749 9.85355 28.0251 9.85355 27.4393 10.4393L20 17.8787L12.5607 10.4393Z"
        fill="#E96A00"
      />
    </g>
  </svg>
);

export const EditSvg = ({ className, onClick }) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <g clip-path="url(#clip0_1550_2012)">
      <path
        d="M5.29163 23.3334H9.95829L22.2083 11.0834C22.8271 10.4645 23.1748 9.6252 23.1748 8.75003C23.1748 7.87486 22.8271 7.03553 22.2083 6.41669C21.5895 5.79786 20.7501 5.4502 19.875 5.4502C18.9998 5.4502 18.1605 5.79786 17.5416 6.41669L5.29163 18.6667V23.3334Z"
        stroke="#919294"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.375 7.5835L21.0417 12.2502"
        stroke="#919294"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1550_2012">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(0.625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DeleteSvg = ({ className, onClick }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <g clip-path="url(#clip0_210_21055)">
      <path
        d="M4.66675 8.16699H23.3334"
        stroke="#EE4719"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 12.833V19.833"
        stroke="#EE4719"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3333 12.833V19.833"
        stroke="#EE4719"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 8.16699L6.99992 22.167C6.99992 22.7858 7.24575 23.3793 7.68334 23.8169C8.12092 24.2545 8.71441 24.5003 9.33325 24.5003H18.6666C19.2854 24.5003 19.8789 24.2545 20.3165 23.8169C20.7541 23.3793 20.9999 22.7858 20.9999 22.167L22.1666 8.16699"
        stroke="#EE4719"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 8.16667V4.66667C10.5 4.35725 10.6229 4.0605 10.8417 3.84171C11.0605 3.62292 11.3572 3.5 11.6667 3.5H16.3333C16.6428 3.5 16.9395 3.62292 17.1583 3.84171C17.3771 4.0605 17.5 4.35725 17.5 4.66667V8.16667"
        stroke="#EE4719"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_210_21055">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FilterSvg = ({ className, onClick }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9998 12.3998C11.1161 12.3998 10.3998 11.6835 10.3998 10.7998C10.3998 9.91615 11.1161 9.1998 11.9998 9.1998C12.8835 9.1998 13.5998 9.91615 13.5998 10.7998C13.5998 11.6835 12.8835 12.3998 11.9998 12.3998ZM8.18293 9.5998C8.69263 7.97696 10.2087 6.7998 11.9998 6.7998C13.7909 6.7998 15.307 7.97696 15.8167 9.5998H25.9998C26.6625 9.5998 27.1998 10.1371 27.1998 10.7998C27.1998 11.4625 26.6625 11.9998 25.9998 11.9998H15.8167C15.307 13.6226 13.7909 14.7998 11.9998 14.7998C10.2087 14.7998 8.69263 13.6226 8.18293 11.9998H5.9998C5.33706 11.9998 4.7998 11.4625 4.7998 10.7998C4.7998 10.1371 5.33706 9.5998 5.9998 9.5998H8.18293ZM19.9998 22.7998C20.8835 22.7998 21.5998 22.0835 21.5998 21.1998C21.5998 20.3162 20.8835 19.5998 19.9998 19.5998C19.1162 19.5998 18.3998 20.3162 18.3998 21.1998C18.3998 22.0835 19.1162 22.7998 19.9998 22.7998ZM23.8167 19.9998C23.307 18.377 21.7909 17.1998 19.9998 17.1998C18.2088 17.1998 16.6926 18.377 16.1829 19.9998H5.9998C5.33706 19.9998 4.7998 20.5371 4.7998 21.1998C4.7998 21.8625 5.33706 22.3998 5.9998 22.3998H16.1829C16.6926 24.0226 18.2088 25.1998 19.9998 25.1998C21.7909 25.1998 23.307 24.0226 23.8167 22.3998H25.9998C26.6625 22.3998 27.1998 21.8625 27.1998 21.1998C27.1998 20.5371 26.6625 19.9998 25.9998 19.9998H23.8167Z"
      fill="#008C8C"
    />
  </svg>
);

export const UserSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_176_16687)">
      <path
        d="M16 14.6667C18.9455 14.6667 21.3333 12.2789 21.3333 9.33333C21.3333 6.38781 18.9455 4 16 4C13.0544 4 10.6666 6.38781 10.6666 9.33333C10.6666 12.2789 13.0544 14.6667 16 14.6667Z"
        stroke="#008C8C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 28V25.3333C8 23.9188 8.5619 22.5623 9.5621 21.5621C10.5623 20.5619 11.9188 20 13.3333 20H18.6667C20.0812 20 21.4377 20.5619 22.4379 21.5621C23.4381 22.5623 24 23.9188 24 25.3333V28"
        stroke="#008C8C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_176_16687">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
