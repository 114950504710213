export const payingData = [
  {
    img: require("../assets/img/click.png"),
    type: "click",
    active: false,
  },
  {
    img: require("../assets/img/oson.png"),
    type: "oson",
    active: false,
  },
  {
    img: require("../assets/img/payme.png"),
    type: "payme",
    active: false,
  },
  {
    img: require("../assets/img/qiwi.png"),
    type: "qiwi",
    active: false,
  },
];

export const cardMaskFormat = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const regions = [
  { id: 1, name: "Qoraqalpog'iston" },
  { id: 2, name: "Xorazm" },
  { id: 3, name: "Navoiy " },
  { id: 4, name: "Buxoro" },
  { id: 5, name: "Samarqand" },
  { id: 6, name: "Qashqadaryo" },
  { id: 7, name: "Surxondaryo" },
  { id: 8, name: "Jizzax" },
  { id: 9, name: "Sirdaryo" },
  { id: 10, name: "Toshkent viloyati" },
  { id: 11, name: "Namangan" },
  { id: 12, name: "Farg'ona" },
  { id: 13, name: "Andijon" },
  { id: 14, name: "Toshkent shahar" },
];
