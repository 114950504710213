import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

import { BackTop } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useLocation } from "react-router-dom";
import { SITE_LANG } from "./Connection/constants";
import { WebSocketGlobal } from "./Pages/Chat/WebSocketGlobal";
import HomePage from "./Pages/HomePage";
import SearchWorker from "./Pages/SearchWorker";
import WriteBulletin from "./Pages/WriteBulletin";
import WriteResume from "./Pages/WriteResume";
import Login from "./Pages/Login";
import NavBar from "./Components/NavBar";
import WorksFull from "./Components/WorksFull";
import Advertise from "./Components/Advertise";
import WorkerFull from "./Components/WorkerFull";
import FavouriteAdverts from "./Pages/FavouriteAdverts";
import UsersAdverts from "./Pages/UsersAdverts";
import FavouriteResume from "./Pages/FavouriteResume";
import Advertize from "./Pages/Advertize";
import Notice from "./Pages/Notice";
import NotFound from "./Pages/NotFound";
import Complaint from "./Pages/Complaint";
import Suggestion from "./Pages/Suggestion";
import Footer from "./Components/Footer";
import Chat from "./Pages/Chat/Chat";
import NoticeSettings from "./Pages/NoticeSettings";
import AdvertShare from "./Pages/AdvertShare";

function App() {
  if (!localStorage.getItem(SITE_LANG)) localStorage.setItem(SITE_LANG, "uz");

  const location = useLocation();
  const searchUrl = location.pathname.split("/")[3];
  const searchUrl2 = location.pathname.split("/")[4];
  const searchUrl3 = location.pathname.split("/")[2];

  const data = [
    {
      page: HomePage,
      path: "/searchworks",
    },
    {
      page: SearchWorker,
      path: "/searchworker",
    },
    {
      page: Advertize,
      path: "/advertize",
    },
    {
      page: FavouriteAdverts,
      path: "/favourite/adverts",
    },
    {
      page: FavouriteResume,
      path: "/favourite/resume",
    },
    {
      page: UsersAdverts,
      path: "/user/adverts",
    },
    {
      page: WriteBulletin,
      path: "/writebulletin",
    },
    {
      page: WriteResume,
      path: "/writeresume",
    },
    {
      page: Advertise,
      path: "/advertise",
    },
    {
      page: AdvertShare,
      path: "/advert",
    },
    {
      page: Notice,
      path: "/notice",
    },
    {
      page: NoticeSettings,
      path: "/notice/settings",
    },
    {
      page: Chat,
      path: "/message",
    },
    {
      page: Login,
      path: "/signin",
    },
    {
      page: Suggestion,
      path: "/suggestion",
    },
    {
      page: Complaint,
      path: `/complain/${searchUrl3}`,
    },
    {
      page: WorksFull,
      path: `/searchworks/work/${searchUrl}`,
    },
    {
      page: WorkerFull,
      path: `/searchworker/worker/${searchUrl}`,
    },
    {
      page: WriteBulletin,
      path: `/user/adverts/edit/${searchUrl2}`,
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="App">
        <NavBar />
        <div className="app_content">
          <Routes key="routes">
            <Route index element={<HomePage />} key="indexpage" />
            {data.map((item) => (
              <Route
                exact
                path={item.path}
                element={<item.page />}
                key={item.path}
              />
            ))}
            <Route path="*" element={<NotFound />} key="notfoundpage" />
          </Routes>
        </div>
        <Footer />
        <BackTop key="backtop">
          <div className="arrow_up__content">
            <ArrowUpOutlined />
          </div>
        </BackTop>
      </div>
      <WebSocketGlobal />
    </>
  );
}

export default App;
