import React, { useState } from "react";
import "../Styles/EditProfile.css";
import { Form, Image, Input, Modal, Upload } from "antd";
import { toast } from "react-toastify";
import { EditUserConfig } from "../Connection/server/config/CrudUrls";
import { ReactComponent as CameraSvg } from "../Images/Svg/camera.svg";
import buttonImg from "../Images/buttonIcon.png";

function EditProfile({ data, setData, langObj }) {
  const [form] = Form.useForm();
  const [urlImage, setUrlImage] = useState(data.photo);
  const [imgFile, setFile] = useState(data.photo);
  const [created, setCreated] = useState(data.photo);
  const closeModal = () => {
    Modal.destroyAll();
  };
  const editUser = () => {
    form.validateFields().then((value) => {
      const { password, name } = value;
      let obj = new FormData();

      if (!created) obj.append("photo", imgFile);
      obj.append("phone_number", data.phone_number);
      obj.append("password", password);
      obj.append("name", name);
      obj.append("device_code", data.device_code);
      EditUserConfig(data.id, obj).then((res) => {
        let object = {
          password: password,
          ...res.data,
        };
        setData(object);
        localStorage.setItem("user", JSON.stringify(object));
        closeModal();
        toast.success("Profile tahrirlandi", { autoClose: 2000 });
      });
    });
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setUrlImage(reader.result);
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  //Image upload
  const handleChange = ({ file }) => {
    setFile(file);
    getBase64(file);
    setCreated(false);
  };
  const mainData = [
    {
      label: langObj.ism_familya,
      name: "name",
    },
    {
      label: langObj.telefon_raqamingiz,
      name: "phone_number",
      disabled: true,
    },
    {
      label: langObj.email,
      name: "email",
    },
  ];
  const changePassword = () => {
    window.location.href = "/signin";
  };
  return (
    <div className="editProfile_section">
      <div className="profile_drower_header">
        <img
          src={buttonImg}
          alt="error button"
          onClick={closeModal}
          style={{ cursor: "pointer", display: "block" }}
        />
        {langObj.profilni_tahrirlash}
      </div>

      <div className="editProfile_img">
        <Image width={200} src={urlImage} />
        <Upload
          listType="picture-card"
          onChange={handleChange}
          beforeUpload={() => false}
          maxCount={1}
          accept=".jpg, .jpeg, .png"
        >
          <div className="editProfile_editSvg">
            <CameraSvg />
          </div>
        </Upload>
      </div>

      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        className="editProfile_content"
        initialValues={{
          name: data.name,
          phone_number: data.phone_number,
        }}
      >
        {mainData.map((item, index) => {
          return (
            <Form.Item
              name={item.name}
              label={item.label}
              key={index.toString()}
            >
              <Input bordered={false} disabled={item.disabled} />
            </Form.Item>
          );
        })}
      </Form>

      <p className="editProfile_change_password" onClick={changePassword}>
        {langObj.parolni_ozgartirish}
      </p>
      <br />
      <br />
      <div className="buttons-section writeBulletin_buttons">
        <div className="btn-box editprofile_main_button">
          <div className="btn-container">
            <button
              onClick={editUser}
              className="btn btn-moving-gradient btn-moving-gradient--green"
            >
              {langObj.tasdiqlash}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
