import React from "react";
import "../Styles/Complain.css";

import { useSelector } from "react-redux";
import { Form, Input, Rate } from "antd";
import { toast } from "react-toastify";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { MakeSuggestionConfig } from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";

function Suggestion({ closeSuggestModal }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [form] = Form.useForm();
  const suggestionFunc = () => {
    form.validateFields().then((res) => {
      let obj = res;
      obj.design_rate === 1
        ? (obj.design_rate = "worst")
        : obj.design_rate === 2
        ? (obj.design_rate = "poor")
        : obj.design_rate === 3
        ? (obj.design_rate = "average")
        : obj.design_rate === 1
        ? (obj.design_rate = "good")
        : (obj.design_rate = "excellent");
      MakeSuggestionConfig(obj).then((res) => {
        form.resetFields();
        closeSuggestModal();
        toast.success(langObj.taklifingiz_yuborildi, {
          autoClose: 2000,
        });
      });
    });
  };
  const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
  };
  return (
    <div className="complain_section">
      <h1 className="container_title suggestion_title">
        {langObj.taklif_berish}
      </h1>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        key="suggestion_form"
        className="complain_form "
      >
        <Form.Item
          name="comfortable_rate"
          label={langObj.sayt_qulayligini_baholang}
        >
          <Rate />
        </Form.Item>
        <Form.Item name="design_rate" label={langObj.sayt_dizaynini_baholang}>
          <Rate character={({ index }) => customIcons[index + 1]} />
        </Form.Item>
        <Form.Item
          name="text"
          key="suggestion_text"
          label={langObj.taklif_matnini_kiriting}
          rules={[{ required: true, message: langObj.taklif_matni_error }]}
        >
          <Input.TextArea rows={8} className="writeBulletin_description" />
        </Form.Item>
      </Form>
      <div className="buttons-section full_width">
        <div className="btn-box editprofile_main_button">
          <div className="btn-container">
            <button
              onClick={suggestionFunc}
              className="btn btn-moving-gradient btn-moving-gradient--green"
            >
              {langObj.yuborish}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Suggestion;
