import { Skeleton } from "antd";
import React from "react";

function Loading({ count }) {
  return (
    <div className="works_section">
      {Array.from(Array(count).keys()).map((item) => (
        <div className="works_content" key={item}>
          <Skeleton active />
        </div>
      ))}
    </div>
  );
}

export default Loading;
