import React, { useEffect } from "react";
import "../Styles/WriteBulletin.css";
import { Form, Modal, Input, Checkbox, InputNumber, message } from "antd";

function FilterContent({ searchParams, langObj, handleMakeParams }) {
  const [form] = Form.useForm();
  const onFinish = () => {
    form.validateFields().then((value) => {
      if (+value.to_sum__lte >= +value.from_sum__gte || !value.to_sum__lte) {
        Modal.destroyAll();
        for (let item in value) {
          if (item === "sex") {
            console.log(value[item]);
            if (value[item].length === 1) {
              if (value[item][0]) handleMakeParams("sex", value[item]);
              else handleMakeParams("sex", "");
            } else {
              handleMakeParams("sex", "");
            }
          } else {
            handleMakeParams(item, value[item]);
          }
        }
      } else {
        message.error(
          "Maksimal ish xaqi minimal ish xaqidan ko'p bo'lishi kerak"
        );
      }
    });
  };
  const getFunc = () => {
    form.setFieldsValue({
      from_sum__gte: searchParams.get("from_sum__gte"),
      to_sum__lte: searchParams.get("to_sum__lte"),
      from_age__gte: searchParams.get("from_age__gte"),
      to_age__lte: searchParams.get("to_age__lte"),
      sex: [searchParams.get("sex") ? searchParams.get("sex") : ""],
      is_has_diploma: searchParams.get("is_has_diploma"),
    });
  };
  useEffect(() => {
    getFunc();
  }, []);

  const leftData = [
    {
      label: langObj.ish_haqqi,
      difficult: true,
      style: { marginBottom: 0 },
      itemStyle: { display: "inline-block", width: "180px" },
      item1: {
        name: "from_sum__gte",
        element: (
          <InputNumber
            min={1}
            placeholder="Min"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
      },
      item2: {
        name: "to_sum__lte",
        element: (
          <InputNumber
            min={1}
            placeholder="Max"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
      },
    },
    {
      label: langObj.yosh,
      difficult: true,
      style: { marginBottom: 0 },
      itemStyle: { display: "inline-block", width: "180px" },
      item1: {
        name: "from_age__gte",
        element: <Input placeholder="Min" type="number" />,
      },
      item2: {
        name: "to_age__lte",
        element: <Input placeholder="Max" type="number" />,
      },
    },
    {
      name: "sex",
      message: false,
      element: (
        <Checkbox.Group>
          <div
            className="checkbox_row writeResume_universitet__checked"
            style={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
          >
            <div className="writeBulletin_jins">{langObj.jinsi}</div>
            <div className="checkbox_row">
              <Checkbox
                value="woman"
                style={{ lineHeight: "30px" }}
                key="genderWoman"
              >
                {langObj.ayol}
              </Checkbox>
              <Checkbox
                value="man"
                style={{ lineHeight: "30px" }}
                key="genderMan"
              >
                {langObj.erkak}
              </Checkbox>
            </div>
          </div>
        </Checkbox.Group>
      ),
    },
    {
      name: "is_has_diploma",
      valuePropName: "checked",
      class: "writeResume_universitet__checked",
      element: <Checkbox>{langObj.diplom}</Checkbox>,
    },
  ];

  return (
    <>
      <div className="filterContent">
        <div className="filtercontent_main">
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            key="filtercontentform1"
            className="filterContent_form"
          >
            <h1>{langObj.filter_malumotlari}</h1>
            {leftData.map((item, index) => {
              return (
                <div key={index.toString()}>
                  {item.difficult ? (
                    <>
                      <p className="label_p">{item.label}</p>
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          name={item.item1.name}
                          className={item.itemClass}
                          key={item.item1.name}
                        >
                          {item.item1.element}
                        </Form.Item>
                        <span
                          style={{
                            display: "inline-block",
                            width: "24px",
                            lineHeight: "32px",
                            textAlign: "center",
                          }}
                        >
                          -
                        </span>
                        <Form.Item
                          name={item.item2.name}
                          className={item.itemClass}
                          key={item.item2.name}
                        >
                          {item.item2.element}
                        </Form.Item>
                      </div>
                    </>
                  ) : (
                    <Form.Item
                      name={item.name}
                      label={item.label}
                      className={item.class}
                      key={item.name}
                      style={item.style}
                      valuePropName={item.valuePropName}
                      rules={[
                        { required: item.message, message: item.message },
                      ]}
                    >
                      {item.element}
                    </Form.Item>
                  )}
                </div>
              );
            })}
          </Form>
        </div>
        <div className="btn-box filter_confirm_button">
          <div className="btn-container">
            <button
              onClick={onFinish}
              className="btn btn-moving-gradient btn-moving-gradient--green "
            >
              {langObj.filter}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FilterContent;
