import React, { useEffect, useState } from "react";
import Notice from "../Pages/Notice";
import Burger from "./Burger";
import Profile from "../Pages/Profile";
import "../Styles/NavBar.css";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip, Drawer } from "antd";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBurger,
  changeDrower,
  changeNotice,
  changeRender,
  changeStatus,
} from "../Redux/Actions";
import { token } from "../Connection/server/host";
import { SITE_LANG } from "../Connection/constants";
import { langData } from "../Languages";
import mainLogo from "../Images/mainLogo.png";
import { ReactComponent as MenuSvg1 } from "../Images/Svg/menu1.svg";
import { ReactComponent as MenuSvg2 } from "../Images/Svg/menu2.svg";
import { ReactComponent as MenuSvg3 } from "../Images/Svg/menu3.svg";
import { ReactComponent as MenuSvg4 } from "../Images/Svg/menu4.svg";
import { ReactComponent as AdverSvg1 } from "../Images/Svg/menu2.1.svg";
import { ReactComponent as AdverSvg2 } from "../Images/Svg/menu2.2.svg";
import { ReactComponent as AdverSvg3 } from "../Images/Svg/menu2.3.svg";
import { ReactComponent as AdverSvg4 } from "../Images/Svg/menu2.4.svg";
import { ReactComponent as AdverSvg5 } from "../Images/Svg/menu4.3.svg";
import noticeIcon from "../Images/noticeIcon.png";
import buttonImg from "../Images/buttonIcon.png";
import { GetUserResumeConfig } from "../Connection/server/config/CrudUrls";

function NavBar() {
  const { Header } = Layout;
  const dispatch = useDispatch();
  const location = useLocation();
  const drawerVisible = useSelector((state) => state.drawer);
  const burgerVisible = useSelector((state) => state.burger);
  const noticeVisible = useSelector((state) => state.notice);
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [visibleTooltip2, setVisibleTooltip2] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem(SITE_LANG));
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];

  let aktive = [""];
  if (location.pathname === "/searchworks") {
    aktive = ["menu1"];
  } else if (
    location.pathname === "/writebulletin" ||
    location.pathname === "/favourite/adverts" ||
    location.pathname === "/user/adverts"
  ) {
    aktive = ["menu2"];
  } else if (location.pathname === "/searchworker") {
    aktive = ["menu3"];
  } else if (
    location.pathname === "/writeresume" ||
    location.pathname === "/favourite/resume"
  ) {
    aktive = ["menu4"];
  }
  const [menu1, setMenu1] = useState({
    svg: MenuSvg4,
    text: langObj.elon_berish,
  });
  const [menu2, setMenu2] = useState({
    svg: MenuSvg2,
    text: langObj.resume,
  });
  const changeLang = (lang) => {
    setLanguage(lang);
    localStorage.setItem(SITE_LANG, lang);
    window.location.reload();
  };
  const [resumeMenu, setResumeMenu] = useState(false);
  const getResume = () => {
    if (token) {
      GetUserResumeConfig().then((res) => {
        setResumeMenu(
          res.data.results.length > 0
            ? langObj.resume_tahrirlash
            : langObj.resume_yozish
        );
      });
    }
  };
  useEffect(() => {
    getResume();
  }, []);
  const changeVisibleProfile = () => {
    dispatch(changeDrower());
  };
  const changeVisibleBurger = () => {
    dispatch(changeBurger());
  };
  const changeVisibleNotice = () => {
    if (token) {
      dispatch(changeNotice());
    } else {
      window.location.href = "/signin";
    }
  };
  const showProfile = () => {
    if (token) {
      dispatch(changeDrower());
    } else {
      window.location.href = "/signin";
    }
  };
  const warningFunc = () => {
    toast.warning(langObj.warning, { autoClose: 2000 });
  };
  const navbarFirstTooltip = [
    {
      id: 10,
      title: langObj.ish_eloni_yozish,
      svg: AdverSvg1,
      path: "writebulletin",
      click: true,
    },
    {
      id: 11,
      title: langObj.saqlangan_ishlar,
      svg: AdverSvg2,
      path: "favourite/adverts",
    },
    {
      id: 12,
      title: langObj.mening_ish_elonlarim,
      svg: AdverSvg3,
      path: "user/adverts",
    },
    {
      id: 13,
      title: langObj.ish_elonini_reklama_qilish,
      svg: AdverSvg4,
      path: location.pathname,
      warning: true,
    },
  ];
  const navbarSecondTooltip = [
    {
      id: 30,
      title: resumeMenu ? resumeMenu : langObj.resume_yozish,
      svg: AdverSvg1,
      path: "writeresume",
    },
    {
      id: 31,
      title: langObj.saqlangan_resume,
      svg: AdverSvg2,
      path: "favourite/resume",
    },
    {
      id: 32,
      title: langObj.resume_jonatish,
      svg: AdverSvg5,
      path: location.pathname,
      warning: true,
    },
    {
      id: 33,
      title: langObj.resumeni_reklama_qilish,
      svg: AdverSvg4,
      path: location.pathname,
      warning: true,
    },
  ];

  return (
    <div className="navbar_section">
      <div className="navbar_content">
        <div className="navbar_logo">
          <Link
            to="/"
            onClick={() => {
              aktive = ["menu1"];
              dispatch(changeRender());
            }}
          >
            <img className="navbar_logo__img" src={mainLogo} alt="error logo" />
          </Link>
        </div>
        <div className="navbar_right__content">
          <Header
            className="navbar_main__header"
            style={langObj.menu !== "Menu" ? { width: 790 } : {}}
          >
            <Menu
              mode="horizontal"
              // defaultSelectedKeys={["menu1"]}
              selectedKeys={aktive}
            >
              <Menu.Item key="menu1">
                <Link
                  className="navbar_links"
                  to="searchworks"
                  key="navbarmenu1link"
                  onClick={() => dispatch(changeStatus("work"))}
                >
                  <MenuSvg1 />
                  {langObj.ish_qidirish}
                </Link>
              </Menu.Item>
              <Menu.Item key="menu2">
                <Tooltip
                  visible={visibleTooltip}
                  onVisibleChange={(ev) => setVisibleTooltip(ev)}
                  placement="bottom"
                  color="white"
                  key="navbarfirsttooltip"
                  trigger="click" 
                  title={
                    <Menu mode="inline">
                      {navbarFirstTooltip.map((res, index) => {
                        return (
                          <Menu.Item
                            key={res.id}
                            danger
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              className="navbar_sublinks"
                              to={token ? res.path : "/signin"}
                              key={res.id + "aa"}
                              onClick={() => {
                                res.warning
                                  ? warningFunc()
                                  : setVisibleTooltip(false);
                                setMenu1({
                                  svg: res.svg,
                                  text: res.title,
                                });
                              }}
                            >
                              <res.svg />
                              {res.title}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                >
                  <menu1.svg />
                  {menu1.text.length > 12
                    ? menu1.text.slice(0, 12) + "..."
                    : menu1.text}
                  <DownOutlined className="navbar_toltip__icon" />
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="menu3">
                <Link
                  className="navbar_links"
                  to="searchworker"
                  key="navbarmenu3link"
                  onClick={() => dispatch(changeStatus("worker"))}
                >
                  <MenuSvg3 />
                  {langObj.ishchi_qidirish}
                </Link>
              </Menu.Item>
              <Menu.Item key="menu4">
                <Tooltip
                  visible={visibleTooltip2}
                  onVisibleChange={(ev) => setVisibleTooltip2(ev)}
                  placement="bottom"
                  color="white"
                  key="navbarsecondtooltip"
                  trigger="click"
                  title={
                    <Menu mode="inline">
                      {navbarSecondTooltip.map((res, index) => {
                        return (
                          <Menu.Item
                            key={res.id}
                            danger
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              className="navbar_sublinks"
                              to={token ? res.path : "/signin"}
                              key={res.id + "aa"}
                              onClick={() => {
                                res.warning
                                  ? warningFunc()
                                  : setVisibleTooltip2(false);
                                setMenu2({
                                  svg: res.svg,
                                  text: res.title,
                                });
                              }}
                            >
                              <res.svg />
                              {res.title}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                >
                  <menu2.svg />
                  {menu2.text.length > 12
                    ? menu2.text.slice(0, 10) + "..."
                    : menu2.text}
                  <DownOutlined className="navbar_toltip__icon" />
                </Tooltip>
              </Menu.Item>
            </Menu>
          </Header>
          {/* Bildirshnomalar */}
          <Tooltip placement="bottom" title={langObj.bildirishnomalar}>
            <div className="navbar_notice__content">
              {/* <Link to="/notice" key="notice_link"> */}
              <img
                onClick={warningFunc}
                src={noticeIcon}
                alt="error img"
                style={{ height: "100%", cursor: "pointer" }}
              />
              {/* </Link> */}
            </div>
          </Tooltip>
          <Drawer
            title=""
            width={420}
            closable={false}
            onClose={changeVisibleNotice}
            visible={noticeVisible}
          >
            <img
              src={buttonImg}
              alt="error button"
              onClick={changeVisibleNotice}
              style={{ cursor: "pointer", display: "block" }}
            />
            <Notice />
          </Drawer>
          {/* Prfoile section */}
          <div className="navbar_profile">
            <Tooltip placement="bottom" title={langObj.profil}>
              <UserOutlined
                className="navbar_profile__icon"
                onClick={showProfile}
              />
            </Tooltip>
            <Drawer
              title=""
              width={360}
              closable={false}
              onClose={changeVisibleProfile}
              visible={drawerVisible}
            >
              <div className="profile_drower_header">
                <img
                  src={buttonImg}
                  alt="error button"
                  onClick={changeVisibleProfile}
                  style={{ cursor: "pointer", display: "block" }}
                />
                {langObj.profil}
              </div>
              <Profile />
            </Drawer>
          </div>
          {/* Language section */}
          <div className="navbar_language">
            <div className="navbar_language__content">
              <div
                onClick={() => changeLang("uz")}
                className={
                  language === "uz"
                    ? "navbar_language__box navbar_langiage__aktive"
                    : "navbar_language__box"
                }
              >
                Uz
              </div>
              <div className="navbar_language__span">/</div>
              <div
                onClick={() => changeLang("kir")}
                className={
                  language === "kir"
                    ? "navbar_language__box navbar_langiage__aktive"
                    : "navbar_language__box"
                }
              >
                Уз
              </div>
              <div className="navbar_language__span">/</div>
              <div
                onClick={() => changeLang("ru")}
                className={
                  language === "ru"
                    ? "navbar_language__box navbar_langiage__aktive"
                    : "navbar_language__box"
                }
              >
                Ру
              </div>
            </div>
          </div>
          {/* Burger / menu in mobile */}
          <div className="navbar_burger">
            <MenuUnfoldOutlined
              className="navbar_burger__icon"
              onClick={changeVisibleBurger}
            />
            <Drawer
              title=""
              width={330}
              closable={false}
              onClose={changeVisibleBurger}
              visible={burgerVisible}
            >
              <div className="profile_drower_header">
                <img
                  src={buttonImg}
                  alt="error button"
                  onClick={changeVisibleBurger}
                  style={{ cursor: "pointer", display: "block" }}
                />
                {langObj.menu}
              </div>
              <Burger />
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;

// U8XUX48XYF
// Y7UYC4MX
