import { Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { langData } from "../../Languages";
import "../../Styles/Chat.css";
import ChatList from "./ChatList";
import ChatMessage from "./ChatMessage";

function Chat() {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [searchParams, setSearchParams] = useSearchParams();
  const senderId = searchParams.get("sender_id");
  const [render, setRender] = useState(1);

  const clearSenderIdFunc = () => {
    searchParams.delete("sender_id");
    searchParams.delete("page_size");
    searchParams.delete("sender_name");
    setSearchParams(searchParams);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item className="link">{langObj.profil}</Breadcrumb.Item>
        {senderId ? (
          <>
            <span className="chat_breadcrumb_visible">
              <Breadcrumb.Item className="link" onClick={clearSenderIdFunc}>
                {langObj.writers}
              </Breadcrumb.Item>
            </span>
            <Breadcrumb.Item>{langObj.messages}</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item>{langObj.writers}</Breadcrumb.Item>
        )}
      </Breadcrumb>
      <div className="chat_section">
        <div className="chat_content">
          <div className={`chat_left ${senderId && "chat_visible"}`}>
            <ChatList render={render} />
          </div>
          <div className={`chat_right ${!senderId && "chat_visible"}`}>
            <ChatMessage setRender={setRender} render={render} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
