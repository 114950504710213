import React from "react";
import InputRange from "react-input-range";
import { useState } from "react/cjs/react.development";
import "../Styles/Advertise.css";
import "react-input-range/lib/css/index.css";

function Advertise() {
  const [value, setValue] = useState({
    min: 0,
    max: 5000,
  });
  const [valueTime, setValueTime] = useState({
    min: 0,
    max: 6,
  });
  const changeDiapazon = (range) => { 
    setValue(range);
  };
  const changeDiapazon2 = (range) => { 
    setValueTime(range);
  };
  return (
    <div className="advertise_section">
      <p>30,000 so’m 6 kun</p>
      Jami sarf
      <p>3,000-5,000</p>
      Tahmini qo’shimcha ko’rsatilish soni
      <div className="advertize_ranges">
        <p>Summa</p>
        <InputRange
          // draggableTrack
          minValue={0}
          maxValue={20000}
          formatLabel={(value) => `${value} so'm`}
          step={1000}
          onChange={(value) => changeDiapazon(value)}
          value={value}
        />
        <br />
        <p>Davomiyligi</p>
        <InputRange
          // draggableTrack
          minValue={0}
          maxValue={30}
          formatLabel={(value) => `${value} kun`}
          step={1}
          onChange={(value) => changeDiapazon2(value)}
          value={valueTime}
        />
      </div>
      <br />
      <div className="buttons-section writeBulletin_buttons">
        <div className="btn-box">
          <div className="btn-container">
            <button className="btn btn-moving-gradient btn-moving-gradient--red">
              E’lon berish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advertise;
