import React from "react";
import animationJson from "../../assets/lottie/payment-success.json";
import Lottie from "react-lottie";

function PayingStep3({ langObj }) {
  const animationData = {
    loop: false,
    autoplay: true,
    animationData: animationJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={animationData} height={180} width={180} />
      <h3>{langObj.payment_success}</h3>
    </div>
  );
}

export default PayingStep3;
