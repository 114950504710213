import { RightOutlined } from "@ant-design/icons";
import { Alert, Button, Form, message, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CheckCodeConfig,
  SendResetSmsConfig,
} from "../../Connection/server/config/CrudUrls";
import { langData } from "../../Languages";
import GetMoreButton from "../Button/GetMoreButton";
import OtpInput from "../Input/OtpInput";

function SignupSmsForm({ setData, setKey, data, render, key, reset }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [form] = Form.useForm();
  const { Countdown } = Statistic;
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deadline, setDeadline] = useState(Date.now() + 1000 * 60 * 2);

  const changeValue = (val) => {
    form.setFieldsValue({ code: val });
  };

  const onFinish = () => {
    setDisable(false);
  };

  const resendFunc = () => {
    setDeadline(Date.now() + 1000 * 60 * 2);
    setDisable(true);
    SendResetSmsConfig(data).then((res) => {
      showMessage();
    });
  };

  const showMessage = () => {
    message.info(
      `${langObj.sizning} ${data.phone_number} ${langObj.sms_qayta}`,
      5
    );
  };

  const handleSubmit = (val) => {
    let obj = {
      phone_number: data.phone_number,
      code: val.code,
    };

    setData(obj);
    setLoading(true);
    CheckCodeConfig(obj)
      .then((res) => {
        setKey("3");
        setData({
          phone_number: data.phone_number,
          token: res.data.token,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(false);
    form.resetFields();
  }, [render, key]);

  return (
    <>
      {reset && (
        <>
          <h2>{langObj.tasdiqlash_kodi}</h2>
          <br />
        </>
      )}
      <div className="login-main-button-back" onClick={() => setKey("1")}>
        <RightOutlined />
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Alert
          message={`${langObj.sizning2} ${data.phone_number} ${langObj.kod_yuborildi}`}
          type="info"
        />
        <br />
        <Form.Item
          label={langObj.smsni_kiritish}
          name="code"
          rules={[
            {
              required: true,
              message: langObj.sms_message,
            },
            {
              min: 5,
              message: langObj.sms_message2,
            },
          ]}
        >
          <OtpInput changeValue={changeValue} />
        </Form.Item>
        <div className="login-sms-resend">
          <Button disabled={disable} type="link" onClick={resendFunc}>
            {langObj.resend_sms}
          </Button>
          <Countdown format="mm:ss" value={deadline} onFinish={onFinish} />
        </div>
        <br />

        <GetMoreButton text={langObj.tasdiqlash} loading={loading} />
      </Form>
    </>
  );
}

export default SignupSmsForm;
