import {
  CreateFunc,
  DeleteFunc,
  GetFunc,
  EditFunc,
  GetFuncByData,
} from "../helper";

//Login section
export const SendSmsConfig = (data) => {
  return CreateFunc("users/users/send_code/", data);
};
export const SendResetSmsConfig = (data) => {
  return CreateFunc("users/users/reset_password_send_code/", data);
};
export const CheckCodeConfig = (data) => {
  return CreateFunc("users/users/check_code/", data);
};
export const RegisterUserConfig = (data) => {
  return CreateFunc("users/users/register/", data);
};
export const LoginConfig = (data) => {
  return CreateFunc("users/login", data);
};
export const ResetConfig = (data) => {
  return CreateFunc("users/users/reset_password/", data);
};
export const DeviceConfig = (data) => {
  return CreateFunc("adverts/devices", data);
};

//Adverts section
export const GetAdvertsConfig = (url = "") => {
  return GetFunc(`adverts/advert/${url}`);
};
export const GetAdvertsByDataConfig = () => {
  return GetFuncByData("adverts/advert/");
};
export const GetAdvertsByIdConfig = (id) => {
  return GetFunc(`adverts/${id}/`);
};
export const CreateAdvertsConfig = (data) => {
  return CreateFunc("adverts/", data);
};
export const EditAdvertsConfig = (id, data) => {
  return EditFunc(`adverts/${id}/`, data);
};
export const GetUsersAdvertsConfig = (page_size = 20) => {
  return GetFunc(`users/users/adverts/?page_size=${page_size}`);
};
export const GetFavouriteAdvertsConfig = (page_size = 20) => {
  return GetFunc(`adverts/favorite_adverts/?page_size=${page_size}`);
};
export const AddFavouriteAdvertConfig = (id) => {
  return GetFunc(`adverts/${id}/add-favorite/`);
};
export const DeleteFavouriteAdvertConfig = (id) => {
  return DeleteFunc(`adverts/${id}/delete-favorite/`);
};
export const DeleteAdvertConfig = (id) => {
  return DeleteFunc(`adverts/${id}/`);
};
// Resume section
export const GetResumeConfig = (url = "") => {
  return GetFunc(`adverts/resume/${url}`);
};
export const GetUserResumeConfig = () => {
  return GetFunc("users/users/resume/");
};
export const GetFavouriteResumeConfig = (page_size = 20) => {
  return GetFunc(`adverts/favorite_resumes/?page_size=${page_size}`);
};

// Locations section
export const GetRegionsConfig = () => {
  return GetFunc("adverts/region?page_size=20");
};
export const GetRegionsRestConfig = () => {
  return GetFunc("adverts/region?page=2");
};
export const GetDistrictsConfig = (id) => {
  return GetFunc(`adverts/district?region=${id}&page_size=50`);
};
export const GetDistrictsRestConfig = (id) => {
  return GetFunc(`adverts/district?page=2&region=${id}`);
};

//User section
export const GetUserConfig = (id) => {
  return GetFunc(`users/users/${id}/`);
};
export const EditUserConfig = (id, data) => {
  return EditFunc(`users/users/${id}/`, data);
};
export const DeleteUserConfig = (id) => {
  return DeleteFunc(`/users/users/${id}/`);
};
// Advertisement section
export const GetAdvertisementConfig = () => {
  return GetFunc("adverts/ads?page_size=100");
};

//Complain and suggestion section
export const MakeComplainConfig = (data) => {
  return CreateFunc("/adverts/complaint", data);
};
export const MakeSuggestionConfig = (data) => {
  return CreateFunc("/adverts/suggestion", data);
};

//Notification section
export const GetNotificationsConfig = () => {
  return GetFunc("adverts/notice");
};
export const GetUserNotificationConfig = () => {
  return GetFunc("users/notification");
};
export const CreateUserNotificationConfig = (data) => {
  return CreateFunc("users/notification", data);
};

//Chat section
export const GetChatMessagesConfig = (id, size) => {
  return GetFunc(`/chats/${id}/?page_size=${size}`);
};
export const GetChatListsConfig = (user) => {
  return GetFunc(`/chats/user-chat-list/?page_size=100${user}`);
};
export const EditMessageConfig = (id, data) => {
  return EditFunc(`chats/${id}/`, data, "PATCH");
};
export const DeleteMessageConfig = (id) => {
  return DeleteFunc(`/chats/${id}/`);
};
export const DeleteMessagePartnerConfig = (id) => {
  return DeleteFunc(`/chats/${id}/delete-chat-list/`);
};

//Payment section
export const PaymentStep1Config = (data) => {
  return CreateFunc("/payments/click-cardtoken", data);
};
export const PaymentStep2Config = (data) => {
  return CreateFunc("/payments/click-cardtokenverify", data);
};
