import React from "react";
import Advert from "../Components/Advert";

function AdvertShare() {
  const data = {
    id: 1285,
    name: "Dasturchi(Nodejs, Python)",
    job_type: "permanent",
    about:
      "Samarqand shahrida yangidan tashkil bo'layotgan IT akademiya. Dars bergan yoki berayotgan pedagog dasturchilar kerak. Ishxona nomi Future Valley. Bog'lanish uchun telegram: @Future_valley",
    from_sum: 2000000,
    to_sum: 7500000,
    is_saved: false,
  };

  const getFunc = () => {
    console.log("bla bla");
  };

  return (
    <div className="advert_share">
      <div className="advert_share__box">
        <Advert item={data} share getFunc={getFunc} />
      </div>
    </div>
  );
}

export default AdvertShare;
