export const changeDrower = () => {
  return {
    type: "changeCategory",
  };
};
export const changeBurger = () => {
  return {
    type: "changeBurger",
  };
};
export const changeNotice = () => {
  return {
    type: "changeNotice",
  };
};
export const changeStatus = (data) => {
  return {
    type: "changestatus",
    payload: data,
  };
};
export const changeAdvertData = (data) => {
  return {
    type: "changeAdvertData",
    payload: data,
  };
};
export const changeLanguage = (data) => {
  return {
    type: "changeLanguage",
    payload: data,
  };
};
export const changeRender = () => {
  return {
    type: "changeRender",
  };
};
