import React, { useEffect, useState } from "react";
import "../Styles/WriteBulletin.css";
import {
  Breadcrumb,
  Form,
  Select,
  Input,
  Checkbox,
  Radio,
  TimePicker,
  Modal,
  Collapse,
  InputNumber,
  message,
} from "antd";
import {
  CreateAdvertsConfig,
  DeleteAdvertConfig,
  EditAdvertsConfig,
  GetDistrictsConfig,
  GetDistrictsRestConfig,
  GetRegionsConfig,
  GetUserResumeConfig,
} from "../Connection/server/config/CrudUrls";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { changeStatus } from "../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { langData } from "../Languages";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { userData } from "../Connection/server/host";
import BasicButton from "../Components/Button/BasicButton";

function WriteResume() {
  const lan = useSelector((state) => state.lang);
  const { Panel } = Collapse;
  const navigate = useNavigate();
  let langObj = langData[lan];
  const dispatch = useDispatch();
  const { Option } = Select;
  const [leftForm] = Form.useForm();
  const [rightForm] = Form.useForm();
  const format = "HH:mm";
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [resumeId, setId] = useState("");
  const [checked, setChecked] = useState(false);
  const getResume = () => {
    GetUserResumeConfig().then((res) => {
      let arr = res.data.results;
      let obj = arr[0];
      if (arr.length > 0) {
        let {
          about,
          achievement,
          district,
          experience,
          from_age,
          from_sum,
          from_time,
          id,
          is_has_diploma,
          is_negotiate,
          job_type,
          keyword,
          name,
          region,
          sex,
          to_age,
          to_sum,
          to_time,
          working_days,
          addition_number,
          university,
        } = obj;
        setId(id);

        leftForm.setFieldsValue({
          district,
          experience,
          from_sum,
          from_time: moment(from_time, "HH:mm"),
          is_negotiate,
          sex: sex,
          job_type,
          name,
          region,
          to_sum,
          to_time: moment(to_time, "HH:mm"),
          working_days: working_days ? working_days : [],
          from_age,
          to_age,
        });
        rightForm.setFieldsValue({
          about,
          achievement,
          university,
          is_has_diploma,
          keyword: keyword.join().replace(/,/gi, ", "),
          addition_number: addition_number
            ? addition_number
            : userData.phone_number,
        });
        changeRegion(region, "no");
      }
    });
  };
  const sendResume = () => {
    let error = 1;
    leftForm.validateFields().then((value) => {
      rightForm.validateFields().then((value2) => {
        error = 20;
        let obj = { ...value, ...value2, type: "resume" };
        let { from_time, keyword, to_time, working_days } = obj;
        obj.keyword = keyword
          .replace(/,/gi, "")
          .split(" ")
          .filter((el) => el.length > 0);
        obj.from_time = from_time._d.toString().split(" ")[4];
        obj.to_time = to_time._d.toString().split(" ")[4];
        if (working_days.length === 0) {
          obj.working_days = null;
        } else
          obj.working_days = obj.working_days.sort(function(a, b) {
            return a - b;
          });
        if (+obj.to_sum >= +obj.from_sum) {
          delete obj["keywordArr"];
          if (resumeId) {
            EditAdvertsConfig(resumeId, obj).then((res) => {
              getResume();
              navigate("/searchworker");
              toast.success(langObj.reusme_tahrirlandi, { autoClose: 2000 });
            });
          } else {
            CreateAdvertsConfig(obj).then((res) => {
              getResume();
              toast.success(langObj.resume_saqlandi, { autoClose: 2000 });
              navigate("/searchworker");
            });
          }
        } else {
          message.error(
            "Maksimal ish xaqi minimal ish xaqidan ko'p bo'lishi kerak"
          );
        }
      });
    });
    setTimeout(() => {
      if (error === 1) toast.error(langObj.dataError, { autoClose: 2000 });
    }, 100);
  };
  const getRegions = () => {
    GetRegionsConfig().then((res) => {
      let arr = res.data.results.reverse();
      setRegions(arr);
    });
  };
  const changeRegion = (id, no) => {
    if (no !== "no") leftForm.setFieldsValue({ district: null });
    GetDistrictsConfig(id).then((res) => {
      let arr = res.data.results;
      if (res.data.next) {
        GetDistrictsRestConfig(id).then((value) => {
          let arr2 = value.data.results;
          let arr3 = arr2.concat(arr);
          setDistricts(arr3);
        });
      } else {
        setDistricts(arr);
      }
    });
  };
  const deleteFavourite = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      okText: "Ha",
      cancelText: "Bekor qilish",
      content: (
        <div className="profile_delete__content">
          <p>{langObj.reumeni_ochirishni_istaysizmi}</p>
          <div className="profile_delete__buttons">
            <button onClick={closeModal}>{langObj.bekor_qilish}</button>
            <button
              onClick={() => {
                closeModal();
                DeleteAdvertConfig(resumeId).then((res) => {
                  getResume();
                  leftForm.resetFields();
                  rightForm.resetFields();
                  window.location.reload();
                  toast.success(langObj.resume_ochirildi, {
                    autoClose: 2000,
                  });
                });
              }}
            >
              {langObj.ha}
            </button>
          </div>
        </div>
      ),
    });
  };
  const closeModal = () => {
    Modal.destroyAll();
  };
  useEffect(() => {
    getResume();
    getRegions();
  }, []);
  const workDay_data = [
    langObj.day[1],
    langObj.day[2],
    langObj.day[3],
    langObj.day[4],
    langObj.day[5],
    langObj.day[6],
    langObj.day[7],
  ];
  const leftData = [
    {
      label: langObj.ish_nomi,
      name: "name",
      message: langObj.ish_nomi_error,
      element: <Input />,
      placeholder: langObj.ish_nomi_placeholder,
    },
    {
      label: langObj.ish_turi,
      name: "job_type",
      message: langObj.ish_turi_error,
      element: (
        <Radio.Group className="writeBulletin_workType" size="large">
          <Radio value="one-time">{langObj.bir_martalik}</Radio>
          <Radio value="permanent">{langObj.doimiy}</Radio>
          <Radio value="online">{langObj.onlayn}</Radio>
          <Radio value="half-time">{langObj.yarim_stavka}</Radio>
        </Radio.Group>
      ),
    },
    {
      location: true,
    },
    {
      label: langObj.toliq_adres,
      name: "address",
      element: <Input />,
    },
    {
      half: true,
      label: langObj.ish_kunlari,
      name: "working_days",
      class: "writeBulletin_workDay",
      style: { marginBottom: "-5px" },
      message: false,
      element: (
        <Checkbox.Group>
          <div className="checkbox_row">
            {workDay_data.map((item, index) => {
              return (
                <div className="checkbox_col">
                  <Checkbox
                    value={index + 1}
                    style={{ lineHeight: "30px" }}
                    key={index.toString() + "workday"}
                  >
                    {item}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </Checkbox.Group>
      ),
    },
    {
      half: true,
      label: langObj.ish_vaqti,
      difficult: true,
      style: { marginBottom: 0 },
      itemStyle: { display: "inline-block" },
      item1: {
        name: "from_time",
        element: (
          <TimePicker
            format={format}
            className="writeBulletin_timePicker"
            size="large"
          />
        ),
      },
      item2: {
        name: "to_time",
        element: (
          <TimePicker
            format={format}
            className="writeBulletin_timePicker"
            size="large"
          />
        ),
      },
    },
    {
      label: langObj.ish_haqqi,
      difficult: true,
      style: { marginBottom: 0 },
      itemClass: "writeBulletin_wotkPrice__inputs",
      handleRequired: true,
      item1: {
        name: "from_sum",
        element: (
          <InputNumber
            min={1}
            placeholder="Min"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
        message: langObj.min_error,
      },
      item2: {
        name: "to_sum",
        element: (
          <InputNumber
            min={1}
            placeholder="Max"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
        message: langObj.max_error,
      },
    },
    {
      name: "is_negotiate",
      valuePropName: "checked",
      class: "writeResume_universitet__checked",
      element: <Checkbox>{langObj.kelishiladi}</Checkbox>,
    },
    {
      label: langObj.tajriba,
      name: "experience",
      element: (
        <Radio.Group className="writeBulletin_workType" size="large">
          <Radio value="optional">{langObj.kerak_emas}</Radio>
          <Radio value="primary">{langObj.boshlangich}</Radio>
          <Radio value="middle">{langObj.ortacha}</Radio>
          <Radio value="high">{langObj.yuqori}</Radio>
        </Radio.Group>
      ),
    },
    {
      half: true,
      name: "sex",
      label: langObj.jinsi,
      message: false,
      element: (
        <Radio.Group
          className="writeBulletin_workType"
          size="large"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Radio value="woman">{langObj.ayol}</Radio>
          <Radio value="man">{langObj.erkak}</Radio>
        </Radio.Group>
      ),
    },
    {
      half: true,
      label: langObj.yosh,
      name: "from_age",
      style: { marginBottom: 0 },
      element: <Input type="number" />,
    },
  ];
  const rightData = [
    {
      name: "is_has_diploma",
      valuePropName: "checked",
      class: "writeResume_universitet__checked",
      element: <Checkbox>{langObj.diplom}</Checkbox>,
    },
    {
      name: "about",
      label: langObj.ozingiz_haqingizda,
      message: langObj.ozingiz_haqingizda_error,
      element: (
        <Input.TextArea rows={5} className="writeBulletin_description" />
      ),
    },
    {
      name: "achievement",
      label: langObj.erishgan_yutuqlarim,
      element: (
        <Input.TextArea rows={5} className="writeBulletin_description" />
      ),
    },
    {
      name: "status",
      label: langObj.hosirgi_status,
      element: (
        <Radio.Group className="writeBulletin_workType" size="large">
          <Radio value="unemployed">{langObj.ishsiz}</Radio>
          <Radio value="employed">{langObj.ishim_bor}</Radio>
          <Radio value="working-two-jobs">
            {langObj.ikkita_ishda_ishlashim_mumkin}
          </Radio>
          <Radio value="want-opportunity">{langObj.ishim_bor_lekin}</Radio>
        </Radio.Group>
      ),
    },
    {
      label: langObj.telefon_raqamingiz,
      name: "addition_number",
      element: <Input />,
    },
    {
      label: langObj.qidiruvdagi_sozlar,
      name: "keyword",
      message: langObj.qidiruvdagi_sozlar_error,
      element: <Input />,
    },
  ];
  return (
    <>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link
            to="/searchworker"
            key="writeresumeLink"
            onClick={() => dispatch(changeStatus("worker"))}
          >
            {langObj.ishchi_qidirish}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {resumeId ? langObj.resume_tahrirlash : langObj.resume_yozish}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="form_container">
        <h1 className="container_title">
          {resumeId ? langObj.resume_tahrirlash : langObj.resume_yozish}
        </h1>
        <div className="writeBulletin_section">
          <div className="writeBulletin_content">
            <Form
              form={leftForm}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              key="leftresumeform"
              className="form_display"
              initialValues={{
                workType: "permanent",
                experience: "optional",
                from_time: moment("08:00", format),
                to_time: moment("16:00", format),
                working_days: [],
                sex: "",
              }}
            >
              {leftData.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={item.half ? "half_width" : "full_width"}
                  >
                    {item.location ? (
                      <div
                        className="writeBulletin_location"
                        key="writeResume_location"
                      >
                        <div>
                          <Form.Item
                            name="region"
                            key="writebulletinregion"
                            label={langObj.viloyat}
                            rules={[
                              {
                                required: true,
                                message: langObj.viloyat_error,
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              onChange={changeRegion}
                              placeholder={langObj.viloyat}
                              className="filter_select"
                            >
                              {regions.map((res, index) => {
                                return (
                                  <Option
                                    className="filter_option"
                                    value={res.id}
                                    key={res.name}
                                  >
                                    {res.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div span={12}>
                          <Form.Item
                            name="district"
                            key="writebulletindistrict"
                            label={langObj.tuman}
                            rules={[
                              {
                                required: true,
                                message: langObj.tuman_error,
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              placeholder={langObj.tuman}
                              className="filter_select"
                            >
                              {districts.map((res, index) => {
                                return (
                                  <Option
                                    className="filter_option"
                                    value={res.id}
                                    key={res.name}
                                  >
                                    {res.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    ) : item.difficult ? (
                      <div className={item.label}>
                        <p
                          className={
                            item.handleRequired
                              ? "label_p required_label"
                              : "label_p"
                          }
                        >
                          {item.label}
                        </p>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            name={item.item1.name}
                            className={item.itemClass}
                            key={item.item1.name}
                            rules={[
                              {
                                required: item.item1.message,
                                message: item.item1.message,
                              },
                            ]}
                          >
                            {item.item1.element}
                          </Form.Item>
                          <span
                            style={{
                              display: "inline-block",
                              width: "24px",
                              lineHeight: "32px",
                              textAlign: "center",
                              paddingTop: "8px",
                            }}
                          >
                            -
                          </span>
                          <Form.Item
                            name={item.item2.name}
                            className={item.itemClass}
                            key={item.item2.name}
                            rules={[
                              {
                                required: item.item2.message,
                                message: item.item2.message,
                              },
                            ]}
                          >
                            {item.item2.element}
                          </Form.Item>
                        </div>
                      </div>
                    ) : (
                      <Form.Item
                        name={item.name}
                        label={item.label}
                        className={item.class}
                        key={item.name}
                        style={item.style}
                        valuePropName={item.valuePropName}
                        rules={[
                          { required: item.message, message: item.message },
                        ]}
                      >
                        {item.element}
                      </Form.Item>
                    )}
                  </div>
                );
              })}
            </Form>
          </div>

          <div className="writeBulletin_content__right">
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              key="rightresumeform"
              className="form_display"
              initialValues={{
                status: "Ishsiz",
                addition_number: userData.phone_number,
              }}
              form={rightForm}
            >
              <Collapse ghost className="full_width">
                <Panel
                  header={
                    <div
                      className="collapse_header"
                      onClick={() => setChecked(!checked)}
                    >
                      {checked ? (
                        <span className="checked_span">
                          <CheckOutlined />
                        </span>
                      ) : (
                        <span className="unchecked_span"></span>
                      )}
                      {langObj.universitet}
                    </div>
                  }
                >
                  <Form.Item
                    key="university_form"
                    name="university"
                    className="writeResume_universitet"
                    style={{ marginBottom: "0px !important" }}
                  >
                    <Input
                      bordered={false}
                      placeholder={langObj.universitet_placeholder}
                    />
                  </Form.Item>
                  <hr className="collapse_hr" />
                </Panel>
              </Collapse>
              {rightData.map((item, index) => {
                return (
                  <div
                    key={`${index}writeresumeSecondSection`}
                    className={item.half ? "half_width" : "full_width"}
                  >
                    {item.difficult ? (
                      <div key={item.label}>
                        <p className="label_p">{item.label}</p>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            key={item.item1.name}
                            name={item.item1.name}
                            className={item.itemClass}
                          >
                            {item.item1.element}
                          </Form.Item>
                          <span
                            style={{
                              display: "inline-block",
                              width: "24px",
                              lineHeight: "32px",
                              textAlign: "center",
                              paddingTop: "8px",
                            }}
                          >
                            -
                          </span>
                          <Form.Item
                            key={item.item2.name}
                            name={item.item2.name}
                            className={item.itemClass}
                          >
                            {item.item2.element}
                          </Form.Item>
                        </div>
                      </div>
                    ) : (
                      <Form.Item
                        name={item.name}
                        label={item.label}
                        className={item.class}
                        key={item.name}
                        style={item.style}
                        valuePropName={item.valuePropName}
                        rules={[
                          { required: item.message, message: item.message },
                        ]}
                      >
                        {item.element}
                      </Form.Item>
                    )}
                  </div>
                );
              })}

              <Form.List name="keywordArr" className="full_width">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        className="full_width"
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            placeholder={langObj.qidiruvdagi_placeholder}
                            className="keyword_width"
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      </Form.Item>
                    ))}

                    <BasicButton
                      text={langObj.qidiruvdagi_button}
                      onClick={() => add()}
                      style={{ justifyContent: "flexStart", marginTop: 0 }}
                    />
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
        <div className="writeResume_footer">
          {resumeId ? (
            <div className="buttons-section">
              <div className="btn-box">
                <div className="btn-container">
                  <button
                    onClick={deleteFavourite}
                    className="btn btn-moving-gradient btn-moving-gradient--red"
                  >
                    {langObj.deleteResume}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="buttons-section">
            <div className="btn-box">
              <div className="btn-container">
                <button
                  onClick={sendResume}
                  className="btn btn-moving-gradient btn-moving-gradient--green"
                >
                  {resumeId
                    ? langObj.resume_tahrirlash
                    : langObj.resume_yaratish}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WriteResume;
