import React, { useState } from "react";
import "../../Styles/otp-input.css";

function OtpInput({ changeValue }) {
  const [otp, setOtp] = useState(new Array(5).fill(""));

  const handleChange = (element, index) => {
    // if (isNaN(element.value)) return false;
    const otpArr = [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ];
    setOtp(otpArr);
    changeValue(otpArr.join(""));

    //Focus nex input
    if (index !== 4) {
      element.nextSibling.focus();
    }
  };

  return (
    <div className="otp-input">
      {otp.map((item, index) => {
        return (
          <input
            className="otp-filed"
            type="text"
            name="otp"
            maxLength={1}
            key={index}
            value={item}
            onChange={(e) => handleChange(e.target, index)}
            onFocus={(e) => e.target.select()}
          />
        );
      })}
    </div>
  );
}

export default OtpInput;
