import React from "react";
import "../Styles/Footer.css";
import mainLogo from "../Images/mainLogo.png";
import app1 from "../Images/Svg/googleplay.wine.svg";
import app2 from "../Images/App_Store_Badge.svg.webp";
import { useSelector } from "react-redux";
import { langData } from "../Languages";

function Footer() {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const mainData = [
    {
      svg: (
        <svg
          style={{ marginRight: "10px" }}
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.659 4.62292C17.7961 4.5099 18 4.61562 18 4.79427V12.25C18 13.2161 17.2441 14 16.3125 14H1.6875C0.755859 14 0 13.2161 0 12.25V4.79792C0 4.61562 0.200391 4.51354 0.341016 4.62656C1.12852 5.26094 2.17266 6.06667 5.75859 8.76823C6.50039 9.32969 7.75195 10.5109 9 10.5036C10.2551 10.5146 11.5312 9.30781 12.2449 8.76823C15.8309 6.06667 16.8715 5.25729 17.659 4.62292ZM9 9.33333C9.81563 9.34792 10.9898 8.26875 11.5805 7.82396C16.2457 4.31302 16.6008 4.00677 17.6766 3.13177C17.8805 2.96771 18 2.7125 18 2.44271V1.75C18 0.783854 17.2441 0 16.3125 0H1.6875C0.755859 0 0 0.783854 0 1.75V2.44271C0 2.7125 0.119531 2.96406 0.323437 3.13177C1.39922 4.00312 1.7543 4.31302 6.41953 7.82396C7.01016 8.26875 8.18437 9.34792 9 9.33333Z"
            fill="white"
          />
        </svg>
      ),
      text: "Simyogochuz@gmail.com",
    },
    {
      svg: (
        <svg
          style={{ marginRight: "10px" }}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0008 12.7925L13.5507 11.2419C13.7595 11.0356 14.0236 10.8944 14.3111 10.8355C14.5985 10.7765 14.8968 10.8022 15.1699 10.9096L17.0589 11.6641C17.3349 11.7762 17.5715 11.9675 17.739 12.2138C17.9065 12.4602 17.9973 12.7507 18 13.0486V16.5098C17.9984 16.7124 17.9558 16.9127 17.8748 17.0985C17.7938 17.2842 17.6761 17.4517 17.5287 17.5907C17.3813 17.7298 17.2073 17.8376 17.0171 17.9076C16.827 17.9776 16.6247 18.0083 16.4223 17.9981C3.18528 17.1743 0.514341 5.96015 0.00921507 1.66831C-0.0142332 1.45755 0.00719085 1.24421 0.0720778 1.04233C0.136965 0.840448 0.243844 0.654598 0.385684 0.497007C0.527524 0.339416 0.701111 0.213654 0.895024 0.127997C1.08894 0.0423395 1.29878 -0.00127313 1.51075 2.82909e-05H4.85289C5.15113 0.000911431 5.44228 0.0910343 5.6889 0.258805C5.93552 0.426576 6.12634 0.664322 6.23679 0.941464L6.99102 2.83126C7.10191 3.10335 7.1302 3.4021 7.07236 3.69019C7.01452 3.97827 6.87312 4.24292 6.66581 4.45108L5.11583 6.00168C5.11583 6.00168 6.00845 12.0449 12.0008 12.7925Z"
            fill="white"
          />
        </svg>
      ),
      text: "+99890 820 85 00",
    },
    {
      svg: (
        <svg
          style={{ marginRight: "13px", marginLeft: "3px" }}
          width="12"
          height="17"
          viewBox="0 0 12 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.01761 0C2.83132 0 0.247803 2.52564 0.247803 5.64127C0.247803 6.4458 0.423712 7.211 0.733311 7.9035L6.11612 16.6294L11.3019 7.9035C11.6208 7.1934 11.7856 6.42185 11.7851 5.64127C11.7851 2.52564 9.20391 0 6.01761 0ZM6.01761 9.64248C5.54144 9.6417 5.07009 9.54554 4.63051 9.35951C4.19092 9.17348 3.79172 8.90121 3.45572 8.55828C3.11973 8.21536 2.85353 7.80849 2.67234 7.36094C2.49115 6.91338 2.39852 6.43392 2.39975 5.94997C2.39883 5.46616 2.49167 4.9869 2.67299 4.53956C2.8543 4.09221 3.12053 3.68555 3.45648 3.34277C3.79243 3 4.19152 2.72784 4.63096 2.54183C5.0704 2.35581 5.54158 2.25959 6.01761 2.25865C6.97901 2.26086 7.90021 2.6509 8.57881 3.34305C9.25741 4.0352 9.63789 4.97285 9.63664 5.94997C9.6382 6.92729 9.25786 7.86525 8.57923 8.55766C7.90059 9.25007 6.97921 9.64027 6.01761 9.64248ZM8.33609 5.90229C8.33671 6.21246 8.27719 6.51972 8.16093 6.80651C8.04468 7.09329 7.87397 7.35399 7.65855 7.5737C7.44314 7.79341 7.18724 7.96784 6.90548 8.087C6.62372 8.20617 6.32162 8.26775 6.01644 8.26822C5.40039 8.26664 4.81016 8.01656 4.37543 7.57293C3.9407 7.1293 3.69703 6.52841 3.69796 5.90229C3.6975 5.59238 3.7571 5.28541 3.87337 4.9989C3.98963 4.7124 4.16028 4.45197 4.37557 4.2325C4.59086 4.01302 4.84658 3.8388 5.12812 3.71976C5.40966 3.60073 5.71151 3.53922 6.01644 3.53875C7.29706 3.53875 8.33609 4.59835 8.33609 5.90229Z"
            fill="white"
          />
        </svg>
      ),
      text: langObj.footer_manzil,
    },
  ];
  return (
    <div className="footer_section">
      <div className="footer_box">
        <div className="footer_content">
          <div className="footer_informs">
            <div className="navbar_logo">
              <img
                className="navbar_logo__img"
                src={mainLogo}
                alt="error logo"
              />
            </div>
            <p>{langObj.footer_about}</p>
          </div>
          <div className="footer_connection">
            <h3>{langObj.biz_bilan_boglanish}</h3>
            <ul>
              {mainData.map((item, index) => {
                return (
                  <li key={index}>
                    {item.svg}
                    <p>{item.text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="footer_app__content">
            <h3>{langObj.ilovalarimiz_orqali_foydalaning}</h3>
            <div className="footer_app__icons">
              <a
                href="https://play.google.com/store/apps/details?id=com.kivi.simyogoch_app_new"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={app1}
                  alt="error img"
                  className="footer_app__icons_img"
                />
              </a>
              <a
                href="https://apps.apple.com/uz/app/new-journey-inc/id1548968230"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={app2} alt="error img" style={{ cursor: "pointer" }} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          2022 <span>TM</span> OOO "SIMYOG'OCH NJ"{"  "}
          <a
            href="https://simyogoch-privacy-policy.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {langObj.foydalanish_shartlari}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
