import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChatSvg } from "../../Images/Svg/chat.svg";

function ChatIcon({ author, worker }) {
  const navigate = useNavigate();
  const id = author ? author.id : 0;
  const name = author ? author.name : "";
  const sendFunc = () => {
    navigate(`/message?sender_id=${id}&sender_name=${name}`);
  };
  return (
    <ChatSvg
      onClick={sendFunc}
      style={{ cursor: "pointer" }}
      className={worker ? "chat_icon_color" : ""}
    />
  );
}

export default ChatIcon;
