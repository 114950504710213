import React, { useEffect, useState } from "react";
import SigninForm from "./SigninForm";
import Signup from "./Signup";

import { Tabs } from "antd";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function SigninSignup({ setKeyMain, keyMain, render }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const { TabPane } = Tabs;
  const [tab, setTab] = useState("1");

  useEffect(() => {
    setTab("1");
  }, [render]);

  return (
    <div className="login-main">
      <h2>{langObj.registr_or}</h2>

      {/* Tab section */}
      <Tabs
        defaultActiveKey="1"
        activeKey={tab}
        onChange={(val) => setTab(val)}
      >
        <TabPane tab={langObj.enter_to} key="1">
          <SigninForm
            setKeyMain={setKeyMain}
            keyMain={keyMain}
            tab={tab}
            render={render}
          />
        </TabPane>
        <TabPane tab={langObj.royxatdan_otish} key="2">
          <Signup tab={tab} render={render} setTab={setTab} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default SigninSignup;
