import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchIconSvg } from "../../assets/svg";
import { GetChatListsConfig } from "../../Connection/server/config/CrudUrls";
import { useSearchParams } from "react-router-dom";
import { WEB_SOCKET_URL } from "../../Connection/constants";
import { token } from "../../Connection/server/host";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";
import useWebSocket from "react-use-websocket";

function ChatList({ render }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const { sendMessage, lastMessage } = useWebSocket(
    `${WEB_SOCKET_URL}?auth=${token}`
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const getFunc = (user) => {
    let url = "";
    if (user) url = `&user=${user}`;
    GetChatListsConfig(url).then((res) => {
      handleMakeData(res.data);
      setAllData(res.data);
    });
  };

  const handleMakeData = (arr) => {
    const arr1 = [];
    const arr2 = [];
    let arr3 = [];
    arr.forEach((item) => {
      if (item.is_online) arr1.push(item);
      else arr2.push(item);
    });
    arr3 = arr1.concat(arr2);
    setData(arr3);
  };

  useEffect(() => {
    getFunc();
    if (lastMessage !== null) {
      const cameData = JSON.parse(lastMessage.data);
      if (cameData.is_online) getFunc();
    }
  }, [lastMessage, render]);

  const changeUser = (id, name) => {
    handleMakeParams("sender_id", id);
    handleMakeParams("sender_name", name);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleKeyDown = (ev) => {
    if (ev.key === "Enter") handleSearch();
  };
  const handleSearch = () => {
    if (searchValue) {
      let arr = [];
      allData.forEach((item) => {
        if (
          item.name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
          arr.push(item);
      });
      handleMakeData(arr);
    } else handleMakeData(allData);
  };
  const handleMakeParams = (key, value) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };
  const handlePrettyText = (text) => {
    if (text.length < 30) return text;
    else return text.slice(0, 30) + "...";
  };
  return (
    <div className="chatlist">
      <div className="chatlist_header">
        <div className="chatlist_header_input">
          <SearchIconSvg onClick={handleSearch} />
          <Input
            placeholder={langObj.searchx}
            bordered={false}
            size="small"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className="chatlist_content">
        {data.map((item) => {
          return (
            <div
              onClick={() => changeUser(item.id, item.name)}
              className={
                item.id === +searchParams.get("sender_id")
                  ? "chatlist_div chatlist_div_active"
                  : "chatlist_div"
              }
            >
              <div
                className={`chatlist_img ${item.is_online &&
                  "chatlist_img_online"}`}
              >
                {item.photo ? (
                  <img src={item.photo} alt="error img" />
                ) : (
                  <div className="chatmessage_person_img_name chatlist_person_img_name">
                    {item.name[0]}
                  </div>
                )}
              </div>
              <div className="chatlist_name">
                <h3>{item.name}</h3>
                <p>{handlePrettyText(item.last_message)}</p>
              </div>
              {/* <div className="chatlist_date">
                <p className="chatlist_date_p">Fev 12</p>
                <span
                  className={
                    item.unread_message ? "chatlist_aktive" : "chatlist_passive"
                  }
                >
                  {item.unread_message}
                </span>
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChatList;
