import React from "react";
import { ReactComponent as ShareSvg } from "../Images/Svg/share.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  TelegramIcon,
  EmailIcon,
  FacebookMessengerIcon,
} from "react-share";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { langData } from "../Languages";
import { toast } from "react-toastify";
import { token } from "../Connection/server/host";
import { useNavigate } from "react-router-dom";
import clipboardPng from "../Images/clipboard4.png";

function Share({ path }) {
  const navigate = useNavigate();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const checkToken = () => {
    !token && navigate("/signin");
  };

  return (
    <div className="share_section">
      <Tooltip
        placement="topRight"
        trigger="click"
        title={
          <div className="share_icons">
            <TelegramShareButton
              url={path}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={40} round />
            </TelegramShareButton>
            <FacebookShareButton
              url={path}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton
              url={path}
              className="Demo__some-network__share-button"
            >
              <FacebookMessengerIcon size={40} round />
            </FacebookMessengerShareButton>
            <EmailShareButton
              url={path}
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={40} round />
            </EmailShareButton>

            <img
              src={clipboardPng}
              alt="copy"
              onClick={() => {
                navigator.clipboard.writeText(path);
                toast.success(langObj.manzil_vaqtincha_saqlandi, {
                  autoClose: 2000,
                });
              }}
            />
          </div>
        }
      >
        <ShareSvg onClick={checkToken} style={{ cursor: "pointer" }} />
      </Tooltip>
    </div>
  );
}

export default Share;
