export const langData = {
  uz: {
    elon_malumotlarini_kiriting: "E'lon ma'lumotlarini kiriting",
    elonni_tahrirlash: "Ish e'lonini tahrirlash",
    foydalanish_shartlari: "Foydalanish shartlari",
    bunaqasi_yoq: "Bunaqasi yo'q",
    favour_empty: "Sizda saqlangan e'lonlar mavjud emas",
    favour_resume_empty: "Sizda saqlangan resumelar mavjud emas",
    user_empty: "Sizning e'lonlaringiz mavjud emas",
    aferta1:
      "Tugmani bosish orqali men shaxsiy ma'lumotlarni qayta ishlashga va",
    aferta2: "Platformadan foydalanish shartlariga",
    aferta3: "roziman",
    mlrd: " mlrd",
    mln: " mln",
    sum: " so'm",
    ming: " ming",
    deleteResume: "Resumeni o'chirish",
    batafsil_title1: "E'lon ma'lumotlari",
    batafsil_title2: "Resume ma'lumotlari",
    dataError: "Ma'lumotlar to'liq emas",
    menu: "Menu",
    warning: "Bu qism tez orada qo'shiladi!",
    manzil_vaqtincha_saqlandi: "Manzil vaqtincha xotiraga saqlandi!",
    warning_max: "Max narx mindan kichik!",
    ish_qidirish: "Ish e'lonlari",
    ish_eloni_yozish: "Ish e'loni yozish",
    saqlangan_ishlar: "Saqlangan ishlar",
    mening_ish_elonlarim: "Bergan e'lonlarim",
    ish_elonini_reklama_qilish: "Ish e'lonini reklama qilish",
    elon_berish: "E'lon berish",
    elon_berish2: "E'lon berish",
    ishchi_qidirish: "Ishchi qidirish",
    resume: "Resume",
    resume_yozish: "Resume yozish",
    saqlangan_resume: "Saqlangan resume",
    resume_jonatish: "Resume jo'natish",
    resumeni_reklama_qilish: "Resumeni reklama qilish",
    bildirishnomalar: "Bildirshnomalar",
    profil: "Profil",
    ism_familya: "Ism Familiya",
    parol: "Parol",
    filter: "Tasdiqlash",
    filter_malumotlari: "Filtr",
    viloyat: "Viloyat",
    tuman: "Tuman",
    ish_haqqi: "Ish haqqi (so'm)",
    yosh: "Yosh",
    jinsi: "Jins",
    erkak: "Erkak",
    ayol: "Ayol",
    diplom: "Diplom",
    edit: "Tahrirlash",
    resume_saqlanganlarga_qoshldi: "Resume saqlanganlarga qo'shildi",
    resume_saqlanganlardan_ochirildi: "Resume saqlanganlardan o'chirildi",
    resumeni_ochirishni_istaysizmi:
      "Resumeni tanlanganlardan o'chirishni istaysizmi?",
    ha: "Ha",
    bekor_qilish: "Bekor qilish",
    xizmat_haqqi: "Xizmat haqqi",
    ish_turi: "Ish turi",
    batafsil: "Batafsil",
    koproq_korish: "Ko'proq ko'rish",
    bir_martalik: "Bir martalik",
    doimiy: "Doimiy",
    yarim_stavka: "Yarim stavka",
    onlayn: "Onlayn",
    elon_saqlanganlarga_qoshildi: "E'lon saqlanganlarga qo'shildi",
    elonni_saqlanganlardan_ochirishni_istaysizmi:
      "E'lonni saqlanganlardan o'chirishni istaysizmi?",
    elon_saqlanganlardan_ochirildi: "E'lon saqlanganlardan o'chirildi",
    tajriba: "Tajriba",
    kerak_emas: "Shart emas",
    boshlangich: "Boshlang'ich",
    ortacha: "O'rtacha",
    yuqori: "Yuqori",
    day: {
      1: "Du",
      2: "Se",
      3: "CH",
      4: "Pa",
      5: "Ju",
      6: "SH",
      7: "Ya",
    },
    hozirgi_status: "Hozirgi status",
    erishgan_yutuqlarim: "Erishgan yutuqlarim",
    telefon_raqami: "Telefon raqami",
    telefon_raqamingiz: "Telefon raqamingiz",
    diplom_kerak: "Dimlom kerak",
    diplom_kerak_emas: "Dimlom kerak emas",
    reklama: "Reklama",
    reklama_beruvchi: "Saytga o'tish",
    shikoyat_jonatildi: "Shikoyat muvaffaqiyatli jo'natildi.",
    shikoyat_qilish: "Shikoyat qilish",
    shikoyat_qilinayotgan_elon_matni: "Shikoyat qilinayotgan e'lon matni",
    shikoyat_matni: "Izoh qoldiring",
    nima_uchun_shikoyat_qilayapsiz: "Yozing...",
    elonni_ochirishni_istaysizmi: "E'lonni o'chirishni istaysizmi?",
    elon_ochirildi: "E'lon o'chirildi",
    reumeni_ochirishni_istaysizmi: "Rezumeni o'chirishni istaysizmi?",
    resume_ochirildi: "Rezume o'chirildi",

    kod_jonatish: "Ko'd jo'natish",
    kod_kiriting: "Ko'dni kiriting",
    tasdiqlash: "Tasdiqlsh",
    telfon_raqami_kiriting: "Telefon raqamingizni kiriting",
    email: "Email",

    sms_jonatildi: "Sms jo'natildi",
    kod_tasdiqlandi: "Ko'd tasdiqlandi",
    royxatdan_otdingiz: "Siz muvaffaqiyatli ro'yxatdan o'tdingiz",
    royxatdan_otmagansiz: "Siz ro'yxatdan o'tmagansiz",
    parol_xato: "Parolni xato kiritdingiz",
    parolni_ozgartirish: "Parolni o’zgartirish",
    parol_ozgartirildi: "Parol muvaffaqiyatli o'zgartirildi",
    kirish: "Kirish",
    raqam_error: "Iltmos avval telefon raqamingizni kiriting",
    parolni_kiriting: "Parolni kiriting",
    parolni_qayta_kiriting: "Parolni qayta kiriting",
    parol_error: "Iltimos parolni kiriting",
    repassword_error: "Iltimos parolni qayta kiriting!",
    parolni_unutdingizmi: "Parolni unitdingizmi?",
    parol_mos_emas: "Siz kiritgan parollar mos emas!",
    royxatdan_otish: "Ro'yxatdan o'tish",
    ismingizni_kiriting: "Ismingizni kiriting",
    ism_error: "Iltmos avval ismingizni kiriting",
    smsni_kiritish: "Sms kodni kiritish",
    sms_error: "Iltmos avval sms kodni kiriting",
    ozgartirish: "O'zgartirish",
    qayta_tiklash: "Qayta tiklash",
    parol_esdan_chiqdimi: "Parolingiz esdan chiqdimi?",
    parol_esdan_chiqish_text:
      "Telefon raqamingizni yozing va biz sizga tasdiqlash kodini yuboramiz. Shundan soʼng yangi parol oʼrnatishingiz mumkin.",
    notFound: "Kechirasiz! Sahifa topilmadi!",

    reklama_qilish: "Reklama berish",
    profilni_tahrirlash: "Profilni tahrirlash",
    tungi_rejim: "Tungi rejim",
    profilni_ochirish: "Profilni o’chirish",

    yozishmalar: "Yozishmalar",
    taklif_berish: "Taklif berish",
    rostdan_ochirib: "Rostan o’chirib ",
    tashlaylikmi: "tashlaylikmi",
    yoge_hazilku: "Yog’e hazil-ku",
    profil_ochirildi: "Shaxsiy profile o'chirildi",

    taklifingiz_yuborildi: "Sizning taklifingiz yuborildi.",
    sayt_qulayligini_baholang: "Sayt qulayligini baholang",
    sayt_qulayligi_error: "Iltimos sayt qulayligini baholang",
    sayt_dizaynini_baholang: "Sayt dizayinini baholang",
    sayt_dizayni_error: "Iltimos sayt dizaynini baholang",
    taklif_matnini_kiriting: "Taklifingiz matnini kiriting",
    taklif_matni_error: "Iltimos taklif matnini kiriting",
    yuborish: "Yuborish",

    ish_eloni_tahrirlandi: "Ish e'loni tahrirlandi",
    ish_eloni_qoshildi: "Ish e'loni qo'shildi",
    viloyat_error: "Iltimos viloyatni tanlang",
    tuman_error: "Iltimos tumanni tanlang",
    ish_nomi: "Ish nomi",
    ish_nomi_error: "Iltimos ish nomini kiriting",
    ish_nomi_placeholder: "O'qituvchi, Developer",
    ish_turi_error: "Iltimos ish turlaridan birini tanlang",
    toliq_adres: "To'liq manzil",
    ish_kunlari: "Ish kunlari",
    ish_vaqti: "Ish vaqti",
    kelishiladi: "Kelishiladi",
    ish_haqida: "Ish haqida",
    ish_haqida_error: "Iltimos ish haqida ma'lumotlarni kiriting",
    ish_haqida_placeholder: "Ishni tasvirlang, talablarigizni yozing",
    qidiruvdagi_sozlar: `Qidiruvdagi so'zlar ( Pastdagi "So'z qo'shish" tugmasi orqali har bir qidiruvga oid so'zni alohida joyga kiriting )`,
    qidiruvdagi_sozlar_error: "Iltimos qidiruvga oid so'zlarni kiriting",
    qidiruvdagi_placeholder: "Qidiruvga tegishli so'zni kiriting",
    qidiruvdagi_button: "So'z qo'shish",
    min_error: "Minimal summani kiriting",
    max_error: "Maximal summani kiriting",

    reusme_tahrirlandi: "Resume tahrirlandi",
    resume_saqlandi: "Resume saqlandi",
    resume_tahrirlash: "Resume tahrirlash",
    resume_yaratish: "Resume yaratish",
    universitet: "Universitet",
    universitet_placeholder: "TATU",
    ozingiz_haqingizda: "O'zingiz haqingizda",
    ozingiz_haqingizda_error:
      "Iltimos o'zingiz haqidagi ma'lumotlarni kiriting",
    hosirgi_status: "Hozirgi status",
    telefon_qilsin: "Telefon qilsin",
    telefon_qilsin_error: "Iltimos telefon qilsinmi belgilang",

    ishsiz: "Ishsiz",
    ishim_bor: "Ishim bor",
    ikkita_ishda_ishlashim_mumkin: "2 ta ishda ishlay olaman",
    ishim_bor_lekin: "Ishim bor lekin imkoniyatlarga yo’q demayman",

    footer_manzil:
      "Toshkent shahar, Yakkasaroy tumani, Mirobod ko'chasi, 9-dom",
    footer_about:
      " Sim yog'och bu - o'zingizga kerak bo'lgan ishni yoxud ishchini topish uchun qulay platforma hisoblanadi.",
    biz_bilan_boglanish: "Biz bilan bog'lanish",
    ilovalarimiz_orqali_foydalaning:
      "Bizning xizmatlarimizdan bizning ilovlarimiz orqali foydalaning",
    chiqish: "Chiqish",
    tizimdan_chiqmoqchimisz: "Tizimdan chiqmoqchimisz?",
    sizning: "Sizning",
    sms_qayta: "telefon raqamingizga sms qayta jo'natiladi",
    tasdiqlash_kodi: "Tasdiqlash kodini kiriting",
    sizning2: "Sizning",
    kod_yuborildi: "raqamingizga tasdiqlash kodi yuborildi",
    sms_message: "Sms kodni kiriting",
    sms_message2: "Sms kodni to'liq kiriting",
    resend_sms: "Kodni qayta yuborish",
    number_message: "Iltimos telefon raqamni to'liq kiriting",
    change: "O‘zgartirish",
    password_changed: "Parolingiz muvaffaqiyatli o'zgartirildi",
    enter_new_password: "Yangi parol kiriting",
    password_error: "Parol eng kamida 6 ta belgidan iborat bo'lishi kerak!",
    privacy: "Foydalanish shartlarini tasdiqlang",
    registr_or: "Kirish yoki ro‘yxatdan o‘tish",
    enter_to: "Tizimga kirish",
    enter_password: "Foydalanuvchi parolini kiriting",
    enter_password2: "Parolini kiriting",
    start_notice: "Bildirishnomani ishga tushirish",
    notice_text1:
      "O’zingiz istagan ish nomini belgilab kiriting va kundalik ravishda birinchilandan bo’lib yangi e’lonni ko’ring",
    notice_text2:
      " To’lov bir martalik va bir oy davomida bildirishnomani ishlatishingiz mumkin",
    no_problem: "Bo’ldi, gap yoq",
    home: "Bosh sahifa",
    notice: "Bildirishnomalar",
    notice_settings: "Bildirishnoma sozlamalari",
    search_words: "Qidiruvdagi so’zlar *",
    search_words_message: "Qidiruvga oid so'zlarni kiriting",
    search: "Qidirish *",
    search_message: "Bildirishnoma turini tanlang",
    search_work: "Ish qidirish",
    search_worker: "Ishchi qidirish",
    save_settings: "Sozlamalarni saqlash",
    select_payment: "To’lov tizimini tanlang",
    by_plastic_card: "Plastik karta orqali",
    enter_card_number: "Karta raqamni kiriting",
    card_number: "Karta raqam",
    card_number_message: "Karta raqamini to'liq kiriting",
    expire_date: "Amal qilish muddati",
    expire_date_message: "Amal qilish muddatini kiriting",
    enter_fully: "To'liq kiriting",
    cvc: "CVC",
    cvc_message: "CVC ni kiriting",
    confirm_payment: "To’lovni tasdiqlash",
    payment_success: "To’lov muvaffaqiyatli amalga oshirildi!",
    payment_alert: "",
    payment_alert2:
      "raqamiga tasdiqlash kodi yuborildi. Iltimos to'lovni tasdiqlang",
    want_delete_message: "Habarni o'chirishni istaysizmi?",
    delete: "O'chirish",
    writers: "Yozishuvchilar",
    messages: "Yozishmalar",
    searchx: "Qidirish",
    you: "Siz",
    you_want_with:
      "bilan yozishgan barcha yozishmalaringizni o'chirib yuborishni istaysizmi?",
    today: "Bugun",
    select_writer: "Chatni boshlash uchun yozishuvchini tanlang",
    write_message: "Xabar yozing...",
  },
  // Kril section
  kir: {
    elon_malumotlarini_kiriting: "Эълон маълумотларини киритинг",
    elonni_tahrirlash: "Иш эълонини таҳрирлаш",
    foydalanish_shartlari: "Фойдаланиш шартлариi",
    bunaqasi_yoq: "Бунақаси йўқ",
    favour_empty: "Сизда сақланган эълонлар мавжуд эмас",
    favour_resume_empty: "Сизда сақланган ресумелар мавжуд эмас",
    user_empty: "Сизнинг эълонларингиз мавжуд эмас",
    aferta1: "Тугмани босиш орқали мен шахсий маълумотларни қайта ишлашга ва",
    aferta2: "Платформадан фойдаланиш шартларига",
    aferta3: "розиман",
    mlrd: "млрд",
    mln: " млн",
    sum: " сўм",
    ming: " минг",
    deleteResume: "Ресумени ўчириш",
    batafsil_title1: "Эълон маълумотлари",
    batafsil_title2: "Ресуме маълумотлари",
    tasdiqlash: "Тасдиқлаш",
    dataError: "Маълумотлар тўлиқ эмас",
    menu: "Мену",
    warning: "Бу қисм тез орада қўшилади!",
    manzil_vaqtincha_saqlandi: "Манзил вақтинча хотирага сақланди!",
    warning_max: "Мах нарх миндан кичик!",
    ish_qidirish: "Иш эълонлари",
    ish_eloni_yozish: "Иш эълони ёзиш",
    saqlangan_ishlar: "Сақланган ишлар",
    mening_ish_elonlarim: "Берган эълонларим",
    ish_elonini_reklama_qilish: "Иш эълонини реклама қилиш",
    elon_berish: "Эълон бериш",
    elon_berish2: "Эълон бериш",
    ishchi_qidirish: "Ишчи қидириш",
    resume: "Ресуме",
    resume_yozish: "Ресуме ёзиш",
    saqlangan_resume: "Сақланган ресуме",
    resume_jonatish: "Ресуме жўнатиш",
    resumeni_reklama_qilish: "Ресумени реклама қилиш",
    bildirishnomalar: "Билдиршномалар",
    profil: "Профил",
    ism_familya: "Исм Фамилия",
    parol: "Парол",
    filter: "Тасдиқлаш",
    filter_malumotlari: "Филтр",
    viloyat: "Вилоят",
    tuman: "Туман",
    ish_haqqi: "Иш ҳаққи (сўм)",
    yosh: "Ёш",
    jinsi: "Жинс",
    erkak: "Эркак",
    ayol: "Аёл",
    diplom: "Диплом",
    edit: "Таҳрирлаш",
    resume_saqlanganlarga_qoshldi: "Ресуме сақланганларга қўшилди",
    resume_saqlanganlardan_ochirildi: "Ресуме сақланганлардан ўчирилди",
    resumeni_ochirishni_istaysizmi:
      "Ресумени танланганлардан ўчиришни истайсизми?",
    ha: "Ҳа",
    bekor_qilish: "Бекор қилиш",
    xizmat_haqqi: "Хизмат ҳаққи",
    ish_turi: "Иш тури",
    batafsil: "Батафсил",
    koproq_korish: "Кўпроқ кўриш",
    bir_martalik: "Бир марталик",
    doimiy: "Доимий",
    yarim_stavka: "Ярим ставка",
    onlayn: "Онлайн",
    elon_saqlanganlarga_qoshildi: "Эълон сақланганларга қўшилди",
    elonni_saqlanganlardan_ochirishni_istaysizmi:
      "Эълонни сақланганлардан ўчиришни истайсизми?",
    elon_saqlanganlardan_ochirildi: "Эълон сақланганлардан ўчирилди",
    tajriba: "Тажриба",
    kerak_emas: "Шарт эмас",
    boshlangich: "Бошланғич",
    ortacha: "Ўртача",
    yuqori: "Юқори",
    day: {
      1: "Ду",
      2: "Се",
      3: "Чо",
      4: "Па",
      5: "Жу",
      6: "Ша",
      7: "Я",
    },
    hozirgi_status: "Ҳозирги статус",
    erishgan_yutuqlarim: "Эришган ютуқларим",
    telefon_raqami: "Телефон рақами",
    telefon_raqamingiz: "Телефон рақамингиз",
    diplom_kerak: "Димлом керак",
    diplom_kerak_emas: "Димлом керак эмас",
    reklama: "Реклама",
    reklama_beruvchi: "Сайтга ўтиш",
    shikoyat_jonatildi: "Шикоят муваффақиятли жўнатилди.",
    shikoyat_qilish: "Шикоят қилиш",
    shikoyat_qilinayotgan_elon_matni: "Шикоят қилинаётган эълон матни",
    shikoyat_matni: "Изоҳ қолдиринг",
    nima_uchun_shikoyat_qilayapsiz: "Ёзинг...",
    elonni_ochirishni_istaysizmi: "Эълонни ўчиришни истайсизми?",
    elon_ochirildi: "Эълон ўчирилди",
    reumeni_ochirishni_istaysizmi: "Резумени ўчиришни истайсизми?",
    resume_ochirildi: "Резуме ўчирилди",

    kod_jonatish: "Кўд жўнатиш",
    kod_kiriting: "Кўдни киритинг",
    tasdiqlash: "Тасдиқлш",
    telfon_raqami_kiriting: "Телефон рақамингизни киритинг",
    email: "Эмаил",

    sms_jonatildi: "Смс жўнатилди",
    kod_tasdiqlandi: "Кўд тасдиқланди",
    royxatdan_otdingiz: "Сиз муваффақиятли рўйхатдан ўтдингиз",
    royxatdan_otmagansiz: "Сиз рўйхатдан ўтмагансиз",
    parol_xato: "Паролни хато киритдингиз",
    parolni_ozgartirish: "Паролни ўзгартириш",
    parol_ozgartirildi: "Парол муваффақиятли ўзгартирилди",
    kirish: "Кириш",
    raqam_error: "Илтмос аввал телефон рақамингизни киритинг",
    parolni_kiriting: "Паролни киритинг",
    parolni_qayta_kiriting: "Паролни қайта киритинг",
    parol_error: "Илтимос паролни киритинг",
    repassword_error: "Илтимос паролни қайта киритинг!",
    parolni_unutdingizmi: "Паролни унитдингизми?",
    parol_mos_emas: "Сиз киритган пароллар мос эмас!",
    royxatdan_otish: "Рўйхатдан ўтиш",
    ismingizni_kiriting: "Исмингизни киритинг",
    ism_error: "Илтмос аввал исмингизни киритинг.",
    smsni_kiritish: "Смс кодни киритиш",
    sms_error: "Илтмос аввал смс кодни киритинг",
    ozgartirish: "Ўзгартириш",
    qayta_tiklash: "Қайта тиклаш",
    parol_esdan_chiqdimi: "Паролингиз эсдан чиқдими?",
    parol_esdan_chiqish_text:
      "Телефон рақамингизни ёзинг ва биз сизга тасдиқлаш кодини юборамиз. Шундан соънг янги парол оърнатишингиз мумкин.",
    notFound: "Кечирасиз! Саҳифа топилмади!",

    reklama_qilish: "Реклама бериш",
    profilni_tahrirlash: "Профилни таҳрирлаш",
    tungi_rejim: "Тунги режим",
    profilni_ochirish: "Профилни ўчириш",

    yozishmalar: "Ёзишмалар",
    taklif_berish: "Таклиф бериш",
    rostdan_ochirib: "Ростан ўчириб ",
    tashlaylikmi: "ташлайликми?",
    yoge_hazilku: "Ёғэ ҳазил-ку",
    profil_ochirildi: "Шахсий профиле ўчирилди",

    taklifingiz_yuborildi: "Сизнинг таклифингиз юборилди.",
    sayt_qulayligini_baholang: "Сайт қулайлигини баҳоланг",
    sayt_qulayligi_error: "Илтимос сайт қулайлигини баҳоланг",
    sayt_dizaynini_baholang: "Сайт дизайинини баҳоланг",
    sayt_dizayni_error: "Илтимос сайт дизайнини баҳоланг",
    taklif_matnini_kiriting: "Таклифингиз матнини киритинг",
    taklif_matni_error: "Илтимос таклиф матнини киритинг",
    yuborish: "Юбориш",

    ish_eloni_tahrirlandi: "Иш эълони таҳрирланди",
    ish_eloni_qoshildi: "Иш эълони қўшилди",
    viloyat_error: "Илтимос вилоятни танланг",
    tuman_error: "Илтимос туманни танланг",
    ish_nomi: "Иш номи",
    ish_nomi_error: "Илтимос иш номини киритинг",
    ish_nomi_placeholder: "Ўқитувчи, Девелопер",
    ish_turi_error: "Илтимос иш турларидан бирини танланг",
    toliq_adres: "Тўлиқ манзил",
    ish_kunlari: "Иш кунлари",
    ish_vaqti: "Иш вақти",
    kelishiladi: "Келишилади",
    ish_haqida: "Иш ҳақида",
    ish_haqida_error: "Илтимос иш ҳақида маълумотларни киритинг",
    ish_haqida_placeholder: "Ишни тасвирланг, талабларигизни ёзинг",
    qidiruvdagi_sozlar: `Қидирувдаги сўзлар ( Пастдаги "Сўз қўшиш" тугмаси орқали ҳар бир қидирувга оид сўзни алоҳида жойга киритинг )`,
    qidiruvdagi_sozlar_error: "Илтимос қидирувга оид сўзларни киритинг",
    qidiruvdagi_placeholder: "Қидирувга тегишли сўзни киритинг",
    qidiruvdagi_button: "Сўз қўшиш",
    min_error: "Минимал суммани киритинг",
    max_error: "Махимал суммани киритинг",

    reusme_tahrirlandi: "Ресуме таҳрирланди",
    resume_saqlandi: "Ресуме сақланди",
    resume_tahrirlash: "Ресуме таҳрирлаш",
    resume_yaratish: "Ресуме яратиш",
    universitet: "Университет",
    universitet_placeholder: "ТАТУ",
    ozingiz_haqingizda: "Ўзингиз ҳақингизда",
    ozingiz_haqingizda_error: "Илтимос ўзингиз ҳақидаги маълумотларни киритинг",
    hosirgi_status: "Ҳозирги статус",
    telefon_qilsin: "Телефон қилсин",
    telefon_qilsin_error: "Илтимос телефон қилсинми белгиланг",

    ishsiz: "Ишсиз",
    ishim_bor: "Ишим бор",
    ikkita_ishda_ishlashim_mumkin: "2 та ишда ишлай оламан",
    ishim_bor_lekin: "Ишим бор лекин имкониятларга йўқ демайман",

    footer_manzil: "Тошкент шаҳар, Яккасарой тумани, Миробод кўчаси, 9-дом",
    footer_about:
      " Симёғоч бу - ўзингизга керак бўлган ишни ёхуд ишчини топиш учун қулай платформа ҳисобланади.",
    biz_bilan_boglanish: "Биз билан боғланиш",
    ilovalarimiz_orqali_foydalaning:
      "Бизнинг хизматларимиздан бизнинг иловларимиз орқали фойдаланинг",
    chiqish: "Чиқиш",
    tizimdan_chiqmoqchimisz: "Тизимдан чиқмоқчимисиз?",
    sizning: "Sizning",
    sms_qayta: "telefon raqamingizga sms qayta jo'natiladi",
    tasdiqlash_kodi: "Tasdiqlash kodini kiriting",
    sizning2: "Сизнинг",
    kod_yuborildi: "рақамингизга тасдиқлаш коди юборилди",
    sms_message: "Смс кодни киритинг",
    sms_message2: "Смс кодни тўлиқ киритинг",
    resend_sms: "Кодни қайта юбориш",
    number_message: "Илтимос телефон рақамни тўлиқ киритинг",
    change: "Ўзгартириш",
    password_changed: "Паролингиз муваффақиятли ўзгартирилди",
    enter_new_password: "Янги парол киритинг",
    password_error: "Парол энг камида 6 та белгидан иборат бўлиши керак!",
    privacy: "Фойдаланиш шартларини тасдиқланг",
    registr_or: "Кириш ёки рўйхатдан ўтиш",
    enter_to: "Тизимга кириш",
    enter_password: "Фойдаланувчи паролини киритинг",
    enter_password2: "Паролини киритинг",
    start_notice: "Билдиришномани ишга тушириш",
    notice_text1:
      "Ўзингиз истаган иш номини белгилаб киритинг ва кундалик равишда биринчиландан бўлиб янги эълонни кўринг",
    notice_text2:
      " Тўлов бир марталик ва бир ой давомида билдиришномани ишлатишингиз мумкин",
    no_problem: "Бўлди, гап йўқ",
    home: "Бош саҳифа",
    notice: "Билдиришномалар",
    notice_settings: "Билдиришнома созламалари",
    search_words: "Қидирувдаги сўзлар *",
    search_words_message: "Қидирувга оид сўзларни киритинг",
    search: "Қидириш *",
    search_message: "Билдиришнома турини танланг",
    search_work: "Иш қидириш",
    search_worker: "Ишчи қидириш",
    save_settings: "Созламаларни сақлаш",
    select_payment: "Тўлов тизимини танланг",
    by_plastic_card: "Пластик карта орқали",
    enter_card_number: "Карта рақамни киритинг",
    card_number: "Карта рақам",
    card_number_message: "Карта рақамини тўлиқ киритинг",
    expire_date: "Амал қилиш муддати",
    expire_date_message: "Амал қилиш муддатини киритинг",
    enter_fully: "Тўлиқ киритинг",
    cvc: "CВC",
    cvc_message: "CВC ни киритинг",
    confirm_payment: "Тўловни тасдиқлаш",
    payment_success: "Тўлов муваффақиятли амалга оширилди!",
    payment_alert: "",
    payment_alert2:
      "рақамига тасдиқлаш коди юборилди. Илтимос тўловни тасдиқланг",
    want_delete_message: "Ҳабарни ўчиришни истайсизми?",
    delete: "Ўчириш",
    writers: "Ёзишувчилар",
    messages: "Ёзишмалар",
    searchx: "Қидириш",
    you: "Сиз",
    you_want_with:
      "билан ёзишган барча ёзишмаларингизни ўчириб юборишни истайсизми?",
    today: "Бугун",
    select_writer: "Чатни бошлаш учун ёзишувчини танланг",
    write_message: "Хабар ёзинг...",
  },
  // Russion section
  ru: {
    write_message: "Напиши сообщение...",
    select_writer: "Выберите корреспондента, чтобы начать чат",
    today: "Сегодня",
    you: "Вы хотите удалить всю свою переписку с",
    you_want_with: "?",
    searchx: "Поиск",
    messages: "Переписка",
    writers: "Писатели",
    delete: "Удалить",
    want_delete_message: "Вы хотите удалить сообщение?",
    payment_alert: "Код подтверждения отправлен на номер",
    payment_alert2: "Пожалуйста, подтвердите платеж",
    payment_success: "Оплата прошла успешно!",
    confirm_payment: "Подтверждение об оплате",
    cvc_message: "Введите код CVC",
    cvc: "CVC",
    enter_fully: "Введите полностью",
    expire_date_message: "Введите дату истечения срока действия",
    expire_date: "Срок годности",
    card_number_message: "Введите номер карты полностью",
    card_number: "Номер карты",
    enter_card_number: "Введите номер карты",
    by_plastic_card: "По пластиковой карте",
    select_payment: "Выберите платежную систему",
    save_settings: "Сохранить настройки",
    search_worker: "Поиск работы",
    search_work: "Поиск работы",
    search_message: "Выберите тип уведомления",
    search: "Поиск *",
    search_words_message: "Введите условия поиска",
    search_words: "Условия поиска *",
    notice_settings: "Настройки уведомлений",
    notice: "Уведомления",
    home: "Домашняя страница",
    no_problem: "Я согласен",
    notice_text2:
      "Плата единоразовая и вы можете пользоваться уведомлением в течение месяца",
    notice_text1:
      "Введите название желаемой вакансии и будьте первым, кто увидит новую рекламу каждый день",
    start_notice: "Активировать уведомление",
    enter_password2: "Введите свой пароль",
    enter_password: "Введите пароль пользователя",
    enter_to: "Авторизоваться",
    registr_or: "Войти или зарегистрироваться",
    privacy: "Подтвердите условия использования",
    password_error: "Пароль должен содержать не менее 6 символов!",
    enter_new_password: "Введите новый пароль",
    password_changed: "Ваш пароль был успешно изменен",
    change: "Изменять",
    number_message: "Пожалуйста, введите полный номер телефона",
    resend_sms: "Отправить код повторно",
    sms_message2: "Введите полный СМС-код",
    sms_message: "Введите SMS-код",
    kod_yuborildi: "",
    sizning2: "Код подтверждения был отправлен на ваш номер",
    tasdiqlash_kodi: "Введите код подтверждения",
    sizning: "SMS будет повторно отправлено на ваш номер телефона",
    sms_qayta: "",
    elon_malumotlarini_kiriting: "Введите рекламную информацию",
    elonni_tahrirlash: "Изменить объявление о вакансии",
    foydalanish_shartlari: "Условия эксплуатации",
    bunaqasi_yoq: "Такого нет",
    favour_empty: "У вас нет сохраненных объявлений",
    favour_resume_empty: "У вас нет сохраненных резюме",
    user_empty: "Ваша реклама недоступна",
    aferta1:
      "Нажимая на кнопку, я даю согласие на обработку персональных данных и ",
    aferta2: "условия использования Платформы",
    aferta3: "",
    mlrd: " млрд",
    mln: " млн",
    sum: " сум",
    ming: " мин",
    deleteResume: "Удалить резюме",
    batafsil_title1: "Рекламная информация",
    batafsil_title2: "Информация о резюме",
    dataError: "Информация неполная",
    menu: "Меню",
    warning: "Этот раздел скоро будет добавлен!",
    manzil_vaqtincha_saqlandi: "Адрес сохранен!",
    warning_max: "Максимальная цена меньше моей!",
    ish_qidirish: "Поиск работы",
    ish_eloni_yozish: "Создать уведомления",
    saqlangan_ishlar: "Сохраненные работы",
    mening_ish_elonlarim: "Мои объявления",
    ish_elonini_reklama_qilish: "Рекламировать объявление о работе",
    elon_berish2: "Подать объявление",
    elon_berish: "Подать",
    ishchi_qidirish: "Поиск работника",
    resume: "Продолжить",
    resume_yozish: "Написать резюме",
    saqlangan_resume: "Сохраненные резюме",
    resume_jonatish: "Отправить резюме",
    resumeni_reklama_qilish: "Рекламировать резюме",
    bildirishnomalar: "Уведомления",
    profil: "Профиль",
    ism_familya: "Имя Фамилия",
    parol: "Пароль",
    filter: "Подтверждение",
    filter_malumotlari: "Фильтр",
    viloyat: "Провинция",
    tuman: "Район",
    ish_haqqi: "Зарплата (сум)",
    yosh: "Возраст",
    jinsi: "Пол",
    erkak: "Мужской",
    ayol: "Женщина",
    diplom: "Диплом",
    edit: "Редактировать",
    resume_saqlanganlarga_qoshldi: "Резюме добавлено в сохраненное",
    resume_saqlanganlardan_ochirildi: "Резюме удалено из сохраненных",
    resumeni_ochirishni_istaysizmi: "Вы хотите удалить резюме из выбранных?",
    ha: "Да",
    bekor_qilish: "Отмена",
    xizmat_haqqi: "Зарплата",
    ish_turi: "Тип работы",
    batafsil: "Более",
    koproq_korish: "Узнать больше",
    bir_martalik: "Разовый",
    doimiy: "Постоянный",
    yarim_stavka: "Половина ставки",
    onlayn: "Онлайн",
    elon_saqlanganlarga_qoshildi: "Объявление добавлено в сохраненное",
    elonni_saqlanganlardan_ochirishni_istaysizmi:
      "Хотите удалить объявление из сохраненных?",
    elon_saqlanganlardan_ochirildi: "Объявление было удалено из сохраненных",
    tajriba: "Опыт",
    kerak_emas: "не нужен",
    boshlangich: "начальный",
    ortacha: "средний",
    yuqori: "высокий",
    day: {
      1: "Пн",
      2: "Вт",
      3: "Ср",
      4: "Чт",
      5: "Пт",
      6: "Сб",
      7: "Вс",
    },

    hozirgi_status: "Нынешний статус",
    erishgan_yutuqlarim: "Мои достижения",
    telefon_raqami: "Телефонный номер",
    telefon_raqamingiz: "Ваш номер телефона",
    diplom_kerak: "Нужен диплом",
    diplom_kerak_emas: "Диплом не требуется",
    reklama: "Реклама",
    reklama_beruvchi: "Перейти на сайт",
    shikoyat_jonatildi: "Жалоба была успешно подана.",
    shikoyat_qilish: "Подать жалобу",
    shikoyat_qilinayotgan_elon_matni: "Текст обжалуемого объявления ",
    shikoyat_matni: "Оставить комментарий",
    nima_uchun_shikoyat_qilayapsiz: "Напишите...",
    elonni_ochirishni_istaysizmi: "Вы хотите удалить объявление?",
    elon_ochirildi: "Объявление было удалено",
    reumeni_ochirishni_istaysizmi: "Вы хотите удалить резюме?",
    resume_ochirildi: "Резюме удалено",
    kod_jonatish: "Отправить код",
    kod_kiriting: "Ввести код",
    tasdiqlash: "Подтвердить",
    telfon_raqami_kiriting: "Наберите номер телефона",
    email: "Эл. адрес",

    sms_jonatildi: "SMS было отправлено",
    kod_tasdiqlandi: "Треска подтверждена",
    royxatdan_otdingiz: "Вы успешно зарегистрировались",
    royxatdan_otmagansiz: "Вы не зарегистрированы",
    parol_xato: "Вы неправильно ввели пароль",
    parolni_ozgartirish: "Сменить пароль",
    parol_ozgartirildi: "Пароль успешно изменен",
    kirish: "Вступление",
    raqam_error: "Сначала введите свой номер телефона",
    parolni_kiriting: "Введите пароль",
    parolni_qayta_kiriting: "Введите пароль еще раз",
    parol_error: "Пожалуйста, введите пароль",
    repassword_error: "Пожалуйста, введите еще раз ваш пароль!",
    parolni_unutdingizmi: "Забыли Ваш пароль?",
    parol_mos_emas: "Веденные Вами пароли не совпадают!",
    royxatdan_otish: "Зарегистрироваться",
    ismingizni_kiriting: "Введите ваше имя",
    ism_error: "Сначала введите свое имя",
    smsni_kiritish: "Введите SMS-код",
    sms_error: "Сначала введите смс-код",
    ozgartirish: "Изменение",
    qayta_tiklash: "Восстановление",
    parol_esdan_chiqdimi: "Забыли Ваш пароль?",
    parol_esdan_chiqish_text:
      "Введите свой номер телефона, и мы вышлем вам код подтверждения. Затем вы можете установить новый пароль.",
    notFound: "Прошу прощения! Страница не найдена!",

    reklama_qilish: "Рекламировать",
    profilni_tahrirlash: "Редактировать профиль",
    tungi_rejim: "Ночной режим",
    profilni_ochirish: "Удалить профиль",
    yozishmalar: "Переписка",
    taklif_berish: "Сделай предложение",
    rostdan_ochirib: "Мы действительно",
    tashlaylikmi: "выключим его?",
    yoge_hazilku: "Это шутка",
    profil_ochirildi: "Личный профиль удален",

    taklifingiz_yuborildi: "Ваше предложение отправлено",
    sayt_qulayligini_baholang: "Оцените юзабилити сайта",
    sayt_qulayligi_error: "Пожалуйста, оцените удобство сайта",
    sayt_dizaynini_baholang: "Оцените дизайн сайта",
    sayt_dizayni_error: "Пожалуйста, оцените дизайн сайта",
    taklif_matnini_kiriting: "Введите текст вашего предложения",
    taklif_matni_error: "Пожалуйста, введите текст цитаты",
    yuborish: "Отправлять",

    ish_nomi: "Название работы",
    ish_kunlari: "Дни работы",
    ish_boshlanish_vaqti: "Начало работы",
    toliq_adres: "Полный адрес",
    ish_vaqti: "Дата работы",
    kelishiladi: "Договорная",
    ish_haqida: "О себе",
    ishsiz: "Безработный",
    ishim_bor: "Есть работа",
    ikkita_ishda_ishlashim_mumkin: "Могу работать на двух работах",
    ishim_bor_lekin: "Есть работа, но не откажусь от возможностей",
    nomer: "Номер",

    ish_eloni_tahrirlandi: "Объявление о работе было отредактировано",
    ish_eloni_qoshildi: "Вакансия добавлена",
    viloyat_error: "Пожалуйста, выберите регион",
    tuman_error: "Пожалуйста, выберите район",
    ish_nomi_error: "Пожалуйста, введите название вакансии",
    ish_nomi_placeholder: "Преподаватель, Разработчик",
    ish_turi_error: "Пожалуйста, выберите один из типов работы",
    ish_haqida_error: "Пожалуйста, введите информацию о вакансии",
    ish_haqida_placeholder: "Опишите работу, напишите ваши требования",
    qidiruvdagi_sozlar: `Слова в поиске ( каждое искомое слово вводите в отдельном месте с помощью кнопки "Добавить слово" ниже )`,
    qidiruvdagi_sozlar_error: "Пожалуйста, введите условия поиска",
    qidiruvdagi_placeholder: "Введите поисковый запрос",
    qidiruvdagi_button: "Добавить слово",
    min_error: "Введите минимальную сумму",
    max_error: "Введите максимальную сумму",

    reusme_tahrirlandi: "Резюме отредактировано",
    resume_saqlandi: "Резюме сохранено",
    resume_tahrirlash: "Продолжить редактирование",
    resume_yaratish: "Создать резюме",
    universitet: "Университет",
    universitet_placeholder: "ТАТУ",
    ozingiz_haqingizda: "О себе",
    ozingiz_haqingizda_error: "Пожалуйста, введите информацию о себе",
    hosirgi_status: "Текущий статус",
    telefon_qilsin: "Пусть он позвонит",
    telefon_qilsin_error: "Укажите, звонить ли",

    footer_manzil:
      "Ташкент город, Яккасарайский район, улица Мирабадская, дом 9",
    footer_about:
      "Sim yog'och - удобная платформа для поиска нужной работы или сотрудника.",
    biz_bilan_boglanish: "Связаться с нами",
    ilovalarimiz_orqali_foydalaning:
      "Пользуйтесь нашими услугами через наши приложения",
    chiqish: "Выйти",
    tizimdan_chiqmoqchimisz: "Выйти?",
  },
};
