import React from "react";
import "../Styles/Login.css";
import LoginPage from "../Components/login/Login";

function Login() {
  return (
    <div className="login_section">
      <div className="login_container">
        <LoginPage />
      </div>
    </div>
  );
}

export default Login;
