import React from "react";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function SeeMoreButton() {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  return (
    <div className="works-btn works-btn-arrow-slide" style={{ float: "right" }}>
      <button className="works-btn-arrow-slide-cont works-btn-arrow-slide-cont--green">
        <span className="works-btn-arrow-slide-circle" aria-hidden="true">
          <span className="works-btn-arrow-slide-icon works-btn-arrow-slide-arrow"></span>
        </span>
        <span className="works-btn-arrow-slide-text">{langObj.batafsil}</span>
      </button>
    </div>
  );
}

export default SeeMoreButton;
