import React, { useEffect } from "react";
import "../Styles/Works.css";

import Advert from "../Components/Advert";
import Empty from "./Empty";
import Advertisement from "./Advertisement";
import Loading from "./Loading";
import GetMoreButton from "./Button/GetMoreButton";

import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { langData } from "../Languages";

function Works({
  data,
  handleMakeParams,
  getAdverts,
  loading,
  loading2,
  hasMore,
}) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];

  //Get functions
  const getFirstFunc = () => {
    if (location.pathname === "/" || location.pathname === "/searchworks")
      getAdverts();
  };

  const getMoreFunc = () => {
    let pageSize = +searchParams.get("page_size");
    handleMakeParams("page_size", pageSize ? pageSize + 20 : 40);
    getAdverts(2);
  };

  useEffect(() => {
    getFirstFunc();
  }, [searchParams]);

  return (
    <div className="works_section">
      {loading ? (
        <Loading count={8} />
      ) : data.length > 0 ? (
        data.map((item) => {
          return item.type ? (
            <Advert key={item.id} item={item} getFunc={getAdverts} />
          ) : (
            <Advertisement key={item.id} item={item} />
          );
        })
      ) : (
        <Empty text={langObj.bunaqasi_yoq} />
      )}
      {loading2 && <Loading count={4} />}
      {!loading && !loading2 && hasMore && (
        <GetMoreButton getMoreFunc={getMoreFunc} />
      )}
    </div>
  );
}

export default Works;
