import React, { useEffect, useState } from "react";
import "../Styles/Works.css";
import { Breadcrumb, Image, Modal, Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { changeStatus } from "../Redux/Actions";
import { toast } from "react-toastify";
import {
  AddFavouriteAdvertConfig,
  DeleteFavouriteAdvertConfig,
  GetAdvertsByIdConfig,
  GetDistrictsConfig,
} from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";
import { ReactComponent as HeartSvg } from "../Images/Svg/heart.svg";
import { ReactComponent as HeartFillSvg } from "../Images/Svg/heart-fill.svg";
import { ReactComponent as Full1Svg } from "../Images/Svg/full1.svg";
import { ReactComponent as Full2Svg } from "../Images/Svg/full2.svg";
import { ReactComponent as Full3Svg } from "../Images/Svg/full3.svg";
import { ReactComponent as Full4Svg } from "../Images/Svg/full4.svg";
import { ReactComponent as Full5Svg } from "../Images/Svg/full5.svg";
import { ReactComponent as Full6Svg } from "../Images/Svg/full6.svg";
import { ReactComponent as Full7Svg } from "../Images/Svg/full7.svg";
import { ReactComponent as Full8Svg } from "../Images/Svg/full8.svg";
import { ReactComponent as PhoneCallSvg } from "../Images/Svg/phone-call2.svg";
import { ReactComponent as UserSvg } from "../Images/Svg/user.svg";
import { makeNum } from "../Functions";
import Share from "./Share";
import Complaint from "../Pages/Complaint";
import ChatIcon from "../Pages/Chat/ChatIcon";
import { scrollToTop } from "../utils";
import { userId } from "../Connection/constants";
import { regions } from "../consts";

function WorkerFull() {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const location = useLocation();
  const workerId = location.pathname.split("=")[1];
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [visible, setVisible] = useState();
  const [url, setUrl] = useState("");
  const addFavourite = (id) => {
    AddFavouriteAdvertConfig(id).then((res) => {
      toast.success(langObj.resume_saqlanganlarga_qoshldi, { autoClose: 2000 });
      getWork();
    });
  };
  const deleteFavourite = (id) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      closable: true,
      okText: "Ha",
      cancelText: "Bekor qilish",
      content: (
        <div className="profile_delete__content">
          <p>{langObj.resumeni_ochirishni_istaysizmi}</p>
          <div className="profile_delete__buttons">
            <button className="profile_delete_button2" onClick={closeModal}>
              {langObj.bekor_qilish}
            </button>
            <button
              onClick={() => {
                closeModal();
                DeleteFavouriteAdvertConfig(id).then((res) => {
                  toast.success(langObj.resume_saqlanganlardan_ochirildi, {
                    autoClose: 2000,
                  });
                  getWork();
                });
              }}
            >
              {langObj.ha}
            </button>
          </div>
        </div>
      ),
    });
  };
  const closeModal = () => {
    Modal.destroyAll();
  };
  const getWork = () => {
    GetAdvertsByIdConfig(workerId).then((res) => {
      let obj = res.data;
      let {
        experience,
        from_time,
        job_type,
        keyword,
        sex,
        to_time,
        working_days,
        author,
        from_age,
        from_sum,
        to_sum,
        about,
        achievement,
      } = obj;
      switch (job_type) {
        case "one-time":
          obj.job_type = langObj.bir_martalik;
          break;
        case "online":
          obj.job_type = langObj.onlayn;
          break;
        case "half-time":
          obj.job_type = langObj.yarim_stavka;
          break;
        default:
          obj.job_type = langObj.doimiy;
          break;
      }
      switch (experience) {
        case "optional":
          obj.experience = langObj.kerak_emas;
          break;
        case "primary":
          obj.experience = langObj.boshlangich;
          break;
        case "high":
          obj.experience = langObj.yuqori;
          break;
        default:
          obj.experience = langObj.ortacha;
          break;
      }
      // if (is_has_diploma) obj.is_has_diploma = "Dimlom kerak";
      // else obj.is_has_diploma = "Dimlom kerak emas";
      if (from_age) obj.from_age = from_age;
      else obj.from_age = "";
      if (sex === "woman") obj.sex = langObj.ayol;
      else if (sex === "man") obj.sex = langObj.erkak;
      else obj.sex = langObj.erkak + ", " + langObj.ayol;
      obj.from_time = from_time.slice(0, 5);
      obj.to_time = to_time.slice(0, 5);
      obj.working_days = makeDays(working_days);
      obj.from_sum = makePrice(from_sum);
      obj.to_sum = makePrice(to_sum);
      obj.about = about.split("\n");
      if (obj.achievement) obj.achievement = achievement.split("\n");
      else obj.achievement = null;
      keyword = keyword.map((item) => {
        return "#" + item;
      });
      obj.keyword = keyword.join().replace(/,/gi, ", ");
      if (obj.author) obj["userId"] = obj.author.id;

      let location = "";
      let curRegion = regions.find((el) => el.id === obj.region);
      if (curRegion) location = curRegion.name;
      if (obj.region && obj.district) {
        GetDistrictsConfig(obj.region)
          .then((res) => {
            let { results } = res.data;
            let district = results.find((el) => el.id === obj.district).name;
            console.log(district);
            if (district) {
              if (location) {
                location = location + ", " + district;
                console.log(location);
              } else location = district;
            }
            if (location) obj["regionName"] = location;
            setData(obj);
          })
          .catch(() => {
            if (location) obj["regionName"] = location;
            setData(obj);
          });
      } else {
        if (location) obj["regionName"] = location;
        setData(obj);
      }
      setUrl(author.photo);
    });
  };
  const makeDays = (days) => {
    let word = "";
    if (days) {
      days.forEach((item) => {
        word = word + langObj.day[item] + ", ";
      });
    }
    return word;
  };
  const makePrice = (price) => {
    if (price >= 1000000000) {
      price = makeNum((price / 1000000000).toFixed(1)) + langObj.mlrd;
    } else if (price >= 1000000) {
      price = makeNum((price / 1000000).toFixed(1)) + langObj.mln;
    } else if (price >= 1000) {
      price = makeNum((price / 1000).toFixed(0)) + langObj.ming;
    } else if (price >= 0) {
      price = price + langObj.sum;
    }
    return price;
  };

  useEffect(() => {
    scrollToTop();
    getWork();
  }, []);

  const mainData = [
    {
      img: data.job_type ? Full1Svg : "",
      info: data.job_type ? data.job_type : "",
    },
    {
      img: Full2Svg,
      info:
        String(data.working_days) +
        " " +
        " " +
        data.from_time +
        " - " +
        data.to_time,
    },
    {
      img: Full3Svg,
      info: data.regionName,
    },
    {
      img: Full4Svg,
      info: data.university,
    },
    {
      img: Full5Svg,
      info: langObj.tajriba + " " + data.experience ? data.experience : "",
    },
    {
      img: data.from_age || data.to_age ? Full6Svg : "",
      info: data.from_age,
    },
    {
      img: data.sex ? Full7Svg : "",
      info: data.sex ? data.sex : "",
    },
    {
      img: Full8Svg,
      info: data.keyword,
    },
  ];
  return (
    <>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link
            to="/searchworker"
            key="workerfulllink"
            onClick={() => dispatch(changeStatus("worker"))}
          >
            {langObj.ishchi_qidirish}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{langObj.batafsil}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="form_container">
        <h1 className="container_title">{langObj.batafsil_title2}</h1>
        <div className="worksfull_section workerfull_section">
          <div className="worksfull_content">
            <div
              className="works_name__div"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 40,
              }}
            >
              <div className="worker_img__div">
                {data.author ? (
                  data.author.photo ? (
                    <img
                      src={data.author.photo}
                      alt="error img"
                      className="worksful_owner__img"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisible(true)}
                    />
                  ) : (
                    <div className="worksful_owner__img">
                      <UserSvg />
                    </div>
                  )
                ) : (
                  <div className="worksful_owner__img">
                    <UserSvg />
                  </div>
                )}
              </div>
              <div className="works_name worker_name">
                {data.author ? data.author.name : ""}
                <div className="workerfull_icons">
                  <Popover
                    placement="bottomRight"
                    title={langObj.telefon_raqami}
                    content={
                      <div className="works_price">
                        {data.addition_number
                          ? data.addition_number
                          : data.author
                          ? data.author.phone_number
                          : ""}
                      </div>
                    }
                    trigger="click"
                  >
                    <PhoneCallSvg />
                  </Popover>
                  {data.userId !== +userId && (
                    <ChatIcon author={data.author} worker />
                  )}

                  {data.is_saved ? (
                    <HeartFillSvg onClick={() => deleteFavourite(data.id)} />
                  ) : (
                    <HeartSvg onClick={() => addFavourite(data.id)} />
                  )}
                  <Share
                    path={`http://www.simyogoch.uz/searchworker/worker/id=${data.id}`}
                  />
                  <Complaint Id={data.id} />
                </div>
              </div>
            </div>
            <div className="works_name__div">
              <div className="works_name">{data.name}</div>
              <div className="works_time">{data.created_date}</div>
            </div>
            <div
              className="works_price_div"
              style={{ display: "inline-block", marginBottom: 20 }}
            >
              <div className="works_price">
                {data.from_sum + " - " + data.to_sum}
              </div>
            </div>
            {data.about &&
              data.about.map((item) => {
                return item ? (
                  <p className="worksfull_about">{item}</p>
                ) : (
                  <p></p>
                );
              })}
            <div className="works_price_div">
              <div className="works_price" style={{ marginBottom: "10px" }}>
                {langObj.erishgan_yutuqlarim}
              </div>
            </div>
            {data.achievement &&
              data.achievement.map((item) => {
                return item ? (
                  <p className="worksfull_about">{item}</p>
                ) : (
                  <p></p>
                );
              })}
          </div>
          <div className="worksfull_details">
            {mainData.map((item, index) => {
              return (
                item.info && (
                  <div className="worksfull_details__content" key={index}>
                    <div className="worksfull_details__img">
                      {item.img ? <item.img /> : ""}
                    </div>
                    <div className="worksfull_details__info">{item.info}</div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
      <Image
        width={200}
        style={{ display: "none" }}
        src={url}
        preview={{
          visible,
          src: url,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
}

export default WorkerFull;
