import React, { useEffect } from "react";
import "../Styles/Notice.css";
import Empty from "../Components/Empty";
import {
  GetAdvertsConfig,
  GetUserConfig,
} from "../Connection/server/config/CrudUrls";
import { useState } from "react";
import Advert from "../Components/Advert";
import GetMoreButton from "../Components/Button/GetMoreButton";
import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { ModalCancelSvg } from "../assets/svg";
import { Breadcrumb, Modal } from "antd";
import animationJson from "../assets/lottie/bell-notification.json";
import Lottie from "react-lottie";
import BasicButton from "../Components/Button/BasicButton";
import Paying from "../Components/Payment/Paying";
import { Link } from "react-router-dom";
import { userId } from "../Connection/constants";
import { useDispatch, useSelector } from "react-redux";
import { langData } from "../Languages";
import { changeStatus } from "../Redux/Actions";

function Notice() {
  const animationData = {
    loop: false,
    autoplay: true,
    animationData: animationJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [data, setData] = useState();
  const [activated, setActivated] = useState(false);
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const getNotices = () => {
    GetAdvertsConfig("?page_size=20").then((res) => {
      // setData(res.data.results);
    });
    GetUserConfig(userId).then((res) => {
      res.data && setActivated(res.data.is_active_notification);
    });
  };
  useEffect(() => {
    getNotices();
  }, []);

  const openModal = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      content: (
        <div className="notice_modal_content">
          <ModalCancelSvg onClick={closeModal} className="notice_modal_svg" />
          <Lottie options={animationData} height={180} width={180} />
          <h2>{langObj.start_notice}</h2>
          <p>{langObj.notice_text1}</p>
          <p>{langObj.notice_text2}</p>
          <h3>8.000 {langObj.sum}</h3>
          <BasicButton text={langObj.no_problem} onClick={acceptFunc} />
        </div>
      ),
    });
  };

  const acceptFunc = () => {
    closeModal();
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      width: 400,
      className: "payment_modal",
      content: (
        <div className="notice_modal_content">
          <ModalCancelSvg onClick={closeModal} className="notice_modal_svg2" />
          <Paying langObj={langObj} />
        </div>
      ),
    });
  };

  const closeModal = () => {
    Modal.destroyAll();
  };

  return (
    <>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link
            to="/searchworks"
            key="writeresumeLink"
            onClick={() => dispatch(changeStatus("work"))}
          >
            {langObj.home}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{langObj.bildirishnomalar}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="notice_header">
        <h1 className="container_title">{langObj.bildirishnomalar}</h1>
        {data && activated && (
          <Link to="/notice/settings">
            <SettingOutlined className="notice_setting_svg" />
          </Link>
        )}
      </div>
      {!data && <Empty text="" />}
      {!activated && (
        <GetMoreButton text={langObj.start_notice} onClick={openModal} />
      )}
      {activated && !data && (
        <Link to="/notice/settings">
          <GetMoreButton text={langObj.notice_settings} />
        </Link>
      )}

      <div className="notice_section works_section">
        {data &&
          data.map((item) => <Advert item={item} getFunc={getNotices} />)}
      </div>
    </>
  );
}

export default Notice;
