import React from "react";
import nodataGif from "../Images/Gif/nodata.gif";

function Empty({ text }) {
  return (
    <div>
      <div className="nodata_wrap">
        <h2 className="Gif_title">{text}</h2>
        <img src={nodataGif} alt="Ma'lumolar topilmadi" />
      </div>
    </div>
  );
}

export default Empty;
