import { SITE_LANG } from "../../Connection/constants";

const changeLanguageReducer = (
  state = localStorage.getItem(SITE_LANG),
  action
) => {
  switch (action.type) {
    case "changeLanguage":
      return action.payload;
    default:
      return state ? state : "uz";
  }
};
export default changeLanguageReducer;
