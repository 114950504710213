import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function GetMoreButton({ getMoreFunc, text, onClick, className, loading }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "20px",
      }}
      className={className ? className : ""}
    >
      <div className="buttons-section">
        <div className="btn-box">
          <div className="btn-container">
            <button
              onClick={getMoreFunc}
              className={`btn btn-moving-gradient btn-moving-gradient--green ${loading &&
                "btn-loading"}`}
            >
              {loading && <LoadingOutlined />}
              {text ? text : langObj.koproq_korish}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetMoreButton;
