import React, { useState } from "react";
import "../../Styles/Paying.css";
import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { payingData } from "../../consts";
import { PaymentStep1Config } from "../../Connection/server/config/CrudUrls";

import BasicButton from "../Button/BasicButton";

function PayingStep1({ setKey, setResponse, langObj }) {
  const [form] = Form.useForm();
  const [data, setData] = useState([...payingData]);
  const [isCvc, setCvc] = useState(false);

  const changeType = (type) => {
    const arr = payingData;
    arr.find((el) => el.type === type).active = true;
    arr.forEach((item) => {
      if (item.type === type) item.active = true;
      else item.active = false;
    });
    setData([...arr]);
  };

  const handleChange = (e, name) => {
    let { value } = e.target;
    if (name === "card_number") {
      if (value[0] === "4") setCvc(true);
      else setCvc(false);
    }
  };

  const submitFunc = () => {
    form.validateFields().then((value) => {
      let obj = {
        card_number: value.card_number.replaceAll(" ", ""),
        expire_date: value.expire.replaceAll(" ", "").replace("|", ""),
        object_type: "card",
      };
      PaymentStep1Config(obj).then((res) => {
        setKey();
        console.log(res);
        setResponse(res.data.response);
      });
    });
  };

  return (
    <div className="paying_section">
      {/* <p>{langObj.select_payment}</p>
      <div className="paying_types_wrap">
        {data.map((item) => (
          <div
            onClick={() => changeType(item.type)}
            key={item.type}
            className={
              item.active ? "paying_type paying_type_active" : "paying_type"
            }
          >
            <img src={item.img} alt="error img" />
          </div>
        ))}
      </div>
      <br />
      <br /> */}
      <p>{langObj.by_plastic_card}</p>
      <Form form={form} layout="vertical" className="paying_form">
        <Form.Item
          label={langObj.enter_card_number}
          name="card_number"
          rules={[
            {
              required: true,
              message: langObj.enter_card_number,
            },
            {
              pattern: /^\d{4} \d{4} \d{4} \d{4}$/,
              message: langObj.card_number_message,
            },
          ]}
        >
          <MaskedInput
            onChange={(e) => handleChange(e, "card_number")}
            mask={"0000 0000 0000 0000"}
          />
        </Form.Item>

        <Form.Item
          label={langObj.expire_date}
          name="expire"
          className="paying_form2"
          rules={[
            {
              required: true,
              message: langObj.expire_date_message,
            },
            {
              pattern: /^\d{2} \| \d{2}$/,
              message: langObj.enter_fully,
            },
          ]}
        >
          <MaskedInput
            onChange={(e) => handleChange(e, "expire")}
            mask={"00 | 00"}
          />
        </Form.Item>

        {isCvc && (
          <Form.Item
            label={langObj.cvc}
            name="cvc"
            className="paying_form3"
            rules={[
              {
                required: true,
                message: langObj.cvc_message,
              },
              {
                pattern: /^\d{3}$/,
                message: langObj.enter_fully,
              },
            ]}
          >
            <MaskedInput mask={"000"} />
          </Form.Item>
        )}

        <BasicButton
          text={langObj.yuborish}
          onClick={submitFunc}
          fullWidth
          html
        />
      </Form>
    </div>
  );
}

export default PayingStep1;
