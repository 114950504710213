import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeDrower } from "../Redux/Actions";
import { Modal, Drawer } from "antd";
import { Link } from "react-router-dom";
import "../Styles/Profile.css";
import { deleteCookie } from "../Connection/functions/useCookies";
import { TOKEN } from "../Connection/constants";
import { toast } from "react-toastify";
import { userData } from "../Connection/server/host";
import {
  DeleteUserConfig,
  GetUserConfig,
} from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";

import buttonImg from "../Images/buttonIcon.png";
import profileImg from "../Images/profileImg.png";
import EditProfile from "../Components/EditProfile";
import deleteImg from "../Images/deleteSvg.png";

import { ReactComponent as MenuSvg1 } from "../Images/Svg/profile1.svg";
import { ReactComponent as MenuSvg2 } from "../Images/Svg/profile2.svg";
import { ReactComponent as MenuSvg3 } from "../Images/Svg/profile3.svg";
import { ReactComponent as MenuSvg4 } from "../Images/Svg/profile4.svg";
import { ReactComponent as MenuSvg5 } from "../Images/Svg/profile5.svg";
import { ReactComponent as MenuSvg6 } from "../Images/Svg/profile6.svg";
import { ReactComponent as RigthSvg } from "../Images/Svg/right.svg";
import { ReactComponent as MenuSvg7 } from "../Images/Svg/profile7.svg";
import Suggestion from "./Suggestion";
import { UserSvg } from "../assets/svg";

function Profile() {
  const lan = useSelector((state) => state.lang);
  const [isModalVisible, setModalVisible] = useState(false);
  let langObj = langData[lan];
  const mainData = [
    {
      svg: MenuSvg2,
      name: langObj.yozishmalar,
      path: "message",
    },
    {
      svg: MenuSvg3,
      name: langObj.reklama_qilish,
      link: "https://t.me/samandar_th",
    },
    {
      svg: MenuSvg5,
      name: langObj.profilni_ochirish,
      key: "delete",
      delete: true,
      drawer: true,
    },
    {
      svg: MenuSvg6,
      name: langObj.taklif_berish,
      key: "suggestion",
      drawer: true,
      delete: true,
    },
    {
      svg: MenuSvg7,
      name: langObj.chiqish,
      key: "logout",
      delete: true,
      drawer: true,
    },
  ];
  const dispatch = useDispatch();
  const [childrenDrawer, setChildren] = useState(false);
  const [data, setData] = useState({});
  const getUser = () => {
    if (userData.id)
      GetUserConfig(userData.id).then((res) => {
        setData(res.data);
      });
  };
  useEffect(() => {
    getUser();
  }, [userData.id]);
  const showChildrenDrawer = () => {
    dispatch(changeDrower());
    Modal.success({
      visible: true,
      content: <EditProfile data={data} setData={setData} langObj={langObj} />,
      width: "375px",
      maskClosable: true,
      okText: "Filter",
      onCancel: closeChildModal,
    });
  };
  const closeChildModal = () => {
    Modal.destroyAll();
    dispatch(changeDrower());
  };
  const warningFunc = () => {
    toast.warning(langObj.warning, { autoClose: 2000 });
  };
  const onChildrenDrawerClose = () => {
    setChildren(false);
  };
  const showDeleteModal = (key) => {
    if (key === "suggestion") {
      setModalVisible(true);
    } else {
      Modal.success({
        maskClosable: true,
        okText: "",
        content: (
          <div className="profile_delete__content">
            <img src={deleteImg} alt="error img" />
            {key === "delete" ? (
              <p>
                {langObj.rostdan_ochirib} <br /> {langObj.tashlaylikmi}
              </p>
            ) : (
              <p>{langObj.tizimdan_chiqmoqchimisz}</p>
            )}

            <div className="profile_delete__buttons">
              <button className="profile_delete_button1" onClick={closeModal}>
                {langObj.yoge_hazilku}
              </button>
              <button
                className="profile_delete_button2"
                onClick={() => deleteFunc(key)}
              >
                {langObj.ha}
              </button>
            </div>
          </div>
        ),
      });
    }
  };
  const deleteFunc = (key) => {
    if (key === "delete") {
      DeleteUserConfig(userData.id).then((res) => {
        deleteCookie(TOKEN);
        deleteCookie("userId");
        localStorage.removeItem("user");
        window.location.href = "/";
        toast.success(langObj.profil_ochirildi, {
          autoClose: 2000,
        });
      });
    } else if (key === "logout") {
      deleteCookie(TOKEN);
      deleteCookie("userId");
      localStorage.removeItem("user");
      window.location.href = "/";
    }
  };
  const closeModal = () => {
    Modal.destroyAll();
  };

  return (
    <div className="profile_section">
      <div className="profile_infroms">
        {data.photo ? (
          <img
            src={data.photo}
            alt="error img"
            className="profile_informs__img"
          />
        ) : (
          <div className="profile_informs_svg">
            <UserSvg />
          </div>
        )}
        <div className="profile_informs_right">
          <p>{data.name}</p>
          {userData.phone_number}
        </div>
        <MenuSvg1
          onClick={showChildrenDrawer}
          className="profile_informs_right_svg"
        />
      </div>
      {mainData.map((item, index) => {
        return (
          <div className="profile_content" key={index}>
            {<item.svg />}
            {item.link ? (
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className="profile_content__box"
              >
                <div
                  className="profile_content__menu"
                  onClick={() =>
                    item.delete ? showDeleteModal(item.key) : warningFunc()
                  }
                >
                  {item.name}
                </div>
                <div
                  style={{ marginLeft: "auto" }}
                  className="profile_contetn__right"
                >
                  <RigthSvg />
                </div>
              </a>
            ) : item.drawer ? (
              <div className="profile_content__box">
                <div
                  className="profile_content__menu"
                  onClick={() =>
                    item.delete ? showDeleteModal(item.key) : warningFunc()
                  }
                >
                  {item.name}
                </div>
                <div
                  style={{ marginLeft: "auto" }}
                  className="profile_contetn__right"
                >
                  <RigthSvg />
                </div>
              </div>
            ) : (
              <Link
                to={item.path}
                className="profile_content__box"
                key={index + "link"}
                onClick={() => dispatch(changeDrower())}
              >
                <div className="profile_content__menu">{item.name}</div>
                <div
                  style={{ marginLeft: "auto" }}
                  className="profile_contetn__right"
                >
                  <RigthSvg />
                </div>
              </Link>
            )}
          </div>
        );
      })}
      <Drawer
        title=""
        width={400}
        closable={false}
        onClose={onChildrenDrawerClose}
        visible={childrenDrawer}
      >
        <img
          src={buttonImg}
          alt="error button"
          onClick={onChildrenDrawerClose}
          style={{ cursor: "pointer", display: "block" }}
        />
        <EditProfile data={data} setData={setData} />
      </Drawer>
      <Modal
        title=""
        footer=""
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        width={375}
        className="complain_modal"
      >
        <Suggestion closeSuggestModal={() => setModalVisible(false)} />
      </Modal>
    </div>
  );
}

export default Profile;
