import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBurger } from "../Redux/Actions";
import { Link } from "react-router-dom";
import "../Styles/Profile.css";
import { toast } from "react-toastify";
import { token } from "../Connection/server/host";
import { GetUserResumeConfig } from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";

import { ReactComponent as RigthSvg } from "../Images/Svg/right.svg";
import { ReactComponent as MenuSvg1 } from "../Images/Svg/menu1.svg";
import { ReactComponent as MenuSvg3 } from "../Images/Svg/menu3.svg";
import { ReactComponent as AdverSvg1 } from "../Images/Svg/menu2.1.svg";
import { ReactComponent as AdverSvg2 } from "../Images/Svg/menu2.2.svg";
import { ReactComponent as AdverSvg3 } from "../Images/Svg/menu2.3.svg";
import { ReactComponent as AdverSvg4 } from "../Images/Svg/menu2.4.svg";
import { ReactComponent as AdverSvg5 } from "../Images/Svg/menu4.3.svg";

function Burger() {
  const [resumeMenu, setResumeMenu] = useState(false);
  const getResume = () => {
    if (token) {
      GetUserResumeConfig().then((res) => {
        setResumeMenu(
          res.data.results.length > 0
            ? langObj.resume_tahrirlash
            : langObj.resume_yozish
        );
      });
    }
  };
  useEffect(() => {
    getResume();
  }, []);
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const mainData = [
    {
      name: langObj.ish_qidirish,
      svg: MenuSvg1,
      path: "searchworks",
    },
    {
      name: langObj.ish_eloni_yozish,
      svg: AdverSvg1,
      path: "writebulletin",
    },
    {
      name: langObj.saqlangan_ishlar,
      svg: AdverSvg2,
      path: "favourite/adverts",
    },
    {
      name: langObj.mening_ish_elonlarim,
      svg: AdverSvg3,
      path: "user/adverts",
    },
    {
      name: langObj.ish_elonini_reklama_qilish,
      svg: AdverSvg4,
      warning: true,
    },
    {
      name: langObj.ishchi_qidirish,
      svg: MenuSvg3,
      path: "searchworker",
    },
    {
      name: resumeMenu ? resumeMenu : langObj.resume_yozish,
      svg: AdverSvg1,
      path: "writeresume",
    },
    {
      name: langObj.saqlangan_resume,
      svg: AdverSvg2,
      path: "favourite/resume",
    },
    {
      name: langObj.resume_jonatish,
      svg: AdverSvg5,
      warning: true,
    },
    {
      name: langObj.resumeni_reklama_qilish,
      svg: AdverSvg4,
      warning: true,
    },
  ];
  const dispatch = useDispatch();
  const warningFunc = () => {
    toast.warning(langObj.warning, { autoClose: 2000 });
  };

  return (
    <div className="profile_section burger_section">
      {mainData.map((item, index) => {
        return (
          <div className="profile_content" key={index}>
            {<item.svg className="burger_svg" />}

            {item.warning ? (
              <div className="profile_content__box">
                <div
                  className="profile_content__menu"
                  onClick={() => warningFunc()}
                >
                  {item.name}
                </div>
                <div
                  style={{ marginLeft: "auto" }}
                  className="profile_contetn__right"
                >
                  <RigthSvg />
                </div>
              </div>
            ) : (
              <Link
                to={item.path}
                className="profile_content__box"
                key={index + "link"}
                onClick={() => dispatch(changeBurger())}
              >
                <div className="profile_content__menu">{item.name}</div>
                <div
                  style={{ marginLeft: "auto" }}
                  className="profile_contetn__right"
                >
                  <RigthSvg />
                </div>
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Burger;
