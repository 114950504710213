import React from "react";

function BasicButton({ text, onClick, fullWidth, className, style }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "20px",
        ...style,
      }}
      className={className}
    >
      <div
        className="buttons-section"
        style={fullWidth ? { width: "100%" } : {}}
      >
        <div className="btn-box">
          <div className="btn-container">
            <button
              onClick={onClick}
              className="btn btn-moving-gradient btn-moving-gradient--green"
              style={fullWidth ? { width: "100%" } : {}}
            >
              {text}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicButton;
