import { getCookie } from "../functions/useCookies";

const IP_ADDRESS = "api.simyogoch.uz";
export const TOKEN = "SIMYOGOCH_TOKEN";
export const API_URL = `https://${IP_ADDRESS}/api/v1/`;
export const API_LOGIN = `https://${IP_ADDRESS}/api/v1/`;
export const SITE_LANG = "SIMYOGOCH_LANGUAGE";
export const WEB_SOCKET_URL = `ws://api.simyogoch.uz:8000/api/v1/ws/chat`;
export const userId = getCookie("userId");
export const maskFormat = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];
