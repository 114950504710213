//Generate number to monthes
export const findMonth = (month, lan) => {
  let ru = lan === "ru";
  switch (month) {
    case "01":
      return ru ? "Январь" : "Yanvar";
    case "02":
      return ru ? "Февраль" : "Fevral";
    case "03":
      return ru ? "Март" : "Mart";
    case "04":
      return ru ? "Апреля" : "Aprel";
    case "05":
      return ru ? "Май" : "May";
    case "06":
      return ru ? "Июнь" : "Iyun";
    case "07":
      return ru ? "Июль" : "Iyul";
    case "08":
      return ru ? "Август" : "Avgust";
    case "09":
      return ru ? "Сентябрь" : "Sentyabr";
    case "10":
      return ru ? "Октябрь" : "Oktyabr";
    case "11":
      return ru ? "Ноябрь" : "Noyabr";
    case "12":
      return ru ? "Декабрь" : "Dekabr";
    default:
      break;
  }
};

//Make messages data
export const makeMessagesData = (data, lan) => {
  console.log(lan);
  //Find current date
  const date = new Date();
  const month = makeComplateDate(date.getMonth() + 1);
  const currentDay = makeComplateDate(date.getDate());
  const currentDate = date.getFullYear() + "-" + month + "-" + currentDay;
  //

  const arr = [];
  let day = "";
  data.reverse().forEach((item) => {
    let date = item.created_date;
    if (date.slice(8, 10) !== day) {
      let month = findMonth(date.slice(5, 7), lan);
      day = date.slice(8, 10);
      arr.push({
        date: month + " " + day,
        today: date.slice(0, 10) === currentDate,
      });
    }
    arr.push(item);
  });
  return arr;
};

//Find Message sender name
export const findMessageSenderName = (data, id) => {
  let arr = [];
  arr.push(data[0].receiver);
  arr.push(data[0].sender);
  let name = arr.find((el) => el.id === id).name;
  return name;
};

//Make complate date
export const makeComplateDate = (date) => {
  if (String(date).length === 1) return "0" + date;
  else return date;
};

//Make scroll to top
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
