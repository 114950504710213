import { CalendarOutlined, EyeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { GetAdvertisementConfig } from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";
import { changeStatus } from "../Redux/Actions";
import { scrollToTop } from "../utils";

function Advertize() {
  const [searchParams] = useSearchParams();
  let addId = searchParams.get("id");
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const getFunc = () => {
    if (addId) {
      GetAdvertisementConfig().then((res) => {
        const { results } = res.data;
        results.forEach((item) => {
          if (item.id === +addId) setData(item);
        });
      });
    } else setData({});
  };

  useEffect(() => {
    scrollToTop();
    getFunc();
  }, [addId]);

  return (
    <div className="advertize_section">
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link
            to="/searchworks"
            key="writebulletinLink"
            onClick={() => dispatch(changeStatus("work"))}
          >
            {langObj.ish_qidirish}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{langObj.reklama}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="advertize_content" style={{ paddingBottom: "20px" }}>
        <p className="works_advertize__date" style={{ gap: 5 }}>
          <CalendarOutlined />
          {data.created_date}
          <EyeOutlined style={{ marginLeft: "20px" }} />
          {data.views > 1000 ? Math.round(data.views / 1000) + "K" : data.views}
        </p>
        <h1 className="advertize_header">{data.title}</h1>
        <div className="advertize_img">
          <img src={data.image} alt="error img" />
        </div>
        <p className="advertize_text">{data.description}</p>

        <a
          style={{ color: "white", float: "right", marginTop: 10 }}
          target="_blank"
          rel="noopener noreferrer"
          href={data.link}
        >
          <div className="buttons-section">
            <div className="btn-box">
              <div className="btn-container">
                <button className="btn btn-moving-gradient btn-moving-gradient--green">
                  {langObj.reklama_beruvchi}
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Advertize;
