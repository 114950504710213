import React from "react";
import "../Styles/Worker.css";

import {
  AddFavouriteAdvertConfig,
  DeleteFavouriteAdvertConfig,
} from "../Connection/server/config/CrudUrls";
import Share from "./Share";
import Complaint from "../Pages/Complaint";
import SeeMoreButton from "./Button/SeeMoreButton";
import jobImg from "../Images/worker.jpg";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EyeOutlined } from "@ant-design/icons"; 
import { langData } from "../Languages";
import { ConfirmModal } from "../Functions";
import { ReactComponent as HeartSvg } from "../Images/Svg/heart.svg";
import { ReactComponent as HeartFillSvg } from "../Images/Svg/heart-fill.svg";
import { getCookie } from "../Connection/functions/useCookies";
import { TOKEN } from "../Connection/constants";

function Resume({ item, getFunc }) {
  const token = getCookie(TOKEN);
  const navigate = useNavigate();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];

  //Add to favourities
  const addFavourite = (id) => {
    AddFavouriteAdvertConfig(id).then((res) => {
      toast.success(langObj.resume_saqlanganlarga_qoshldi, { autoClose: 2000 });
      getFunc();
    });
  };

  //Delete from favourities
  const deleteFavourite = (itemId) => {
    ConfirmModal(
      langObj.resumeni_ochirishni_istaysizmi,
      langObj.ha,
      langObj.bekor_qilish,
      () => {
        DeleteFavouriteAdvertConfig(itemId).then((res) => {
          toast.success(langObj.resume_saqlanganlardan_ochirildi, {
            autoClose: 2000,
          });
          getFunc();
        });
      }
    );
  };

  return (
    <div className="works_content" key={`${item.id}worker_firstdiv`}>
      <div
        className="works_name__div"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="worker_img__div">
          <img
            src={
              item.author
                ? item.author.photo
                  ? item.author.photo
                  : jobImg
                : jobImg
            }
            alt="error img"
            className={
              item.author
                ? item.author.photo
                  ? "worker_img__border worker_img"
                  : "worker_img"
                : "worker_img"
            }
          />
        </div>
        <div className="works_name worker_name">
          {item.author ? item.author.name : ""}
          <div className="works_lover__div">
            {item.is_saved ? (
              <HeartFillSvg
                onClick={() => {
                  token ? deleteFavourite(item.id) : navigate("/signin");
                }}
              />
            ) : (
              <HeartSvg
                onClick={() => {
                  token ? addFavourite(item.id) : navigate("/signin");
                }}
              />
            )}
            <Share
              path={`http://www.simyogoch.uz/searchworker/worker/id=${item.id}`}
            />
            <Complaint Id={item.id} />
          </div>
        </div>
      </div>

      <div className="works_name__div">
        <div className="works_name">{item.name}</div>
        <div className="works_time">{item.created_date} 22- dekabr 12:00</div>
      </div>

      <div className="works_price_div">
        {item.from_sum + " - " + item.to_sum}
        {" / "}
        {item.job_type === "one-time"
          ? langObj.bir_martalik
          : item.job_type === "online"
          ? langObj.onlayn
          : item.job_type === "half-time"
          ? langObj.yarim_stavka
          : langObj.doimiy}
      </div>

      <div className="works_description__div">
        {item.about.slice(0, 80)}
        {item.about.length > 80 ? "..." : ""}
      </div>

      <div className="works_footer">
        <div className="works_views">
          <Link
            to={token ? `/searchworker/worker/id=${item.id}` : "/signin"}
            key={item.id.toString()}
          >
            <SeeMoreButton />
          </Link>
          <div>
            <EyeOutlined />
            {item.views > 1000
              ? Math.round(item.views / 1000) + "K"
              : item.views}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
