import React, { useEffect, useRef, useState } from "react";

import {
  DeleteOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ReactComponent as EmojiSvg } from "../../Images/Svg/emoji.svg";
import { ReactComponent as UploadSvg } from "../../Images/Svg/upload.svg";
import { ReactComponent as SendSvg } from "../../Images/Svg/send.svg";

import { WEB_SOCKET_URL } from "../../Connection/constants";
import {
  DeleteMessagePartnerConfig,
  EditMessageConfig,
  GetChatMessagesConfig,
} from "../../Connection/server/config/CrudUrls";
import { token } from "../../Connection/server/host";
import { useSearchParams } from "react-router-dom";

import useWebSocket from "react-use-websocket";
import { Button, Modal, Skeleton } from "antd";
import { findMessageSenderName, makeMessagesData } from "../../utils";
import Message from "../../Components/chat/Message";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function ChatMessage({ render, setRender }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const { sendMessage, lastMessage } = useWebSocket(
    `${WEB_SOCKET_URL}?auth=${token}`
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const senderId = searchParams.get("sender_id");
  const [name, setName] = useState(searchParams.get("sender_name"));
  const [message, setMessage] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);
  const [editData, setEditData] = useState(null);

  const endingRef = useRef();
  const inputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [hasmore, setHasmore] = useState(false);

  //Get messages from websocket section
  useEffect(() => {
    if (lastMessage !== null) {
      const cameData = JSON.parse(lastMessage.data);
      if (+cameData.from_chat_id === +senderId) {
        getFunc("focus");
      }
    }
  }, [lastMessage, setMessageHistory]);
  //Get messages from websocket section
  const forceFocusEnding = () => {
    endingRef.current.focus();
  };
  const forceFocusInput = () => {
    inputRef.current.focus();
  };

  //Get messages section
  const getFunc = (focus) => {
    if (senderId) {
      const currentSize = +searchParams.get("page_size") || 20;
      console.log(currentSize);
      GetChatMessagesConfig(senderId, currentSize).then((res) => {
        const { results } = res.data;
        if (results) {
          if (results.length < res.data.count) setHasmore(true);
          else setHasmore(false);

          if (results.length > 0) {
            const name = findMessageSenderName(results, +senderId);
            const data = makeMessagesData(results, lan);
            setMessageHistory(data);
            setName(name);
            if (focus === "focus") forceFocusEnding();
          } else {
            setMessageHistory([]);
            setName("");
          }
        }
      });
    }
  };

  const getForDeleteFunc = (Id) => {
    let arr = [];
    messageHistory.forEach((el) => {
      if (el.id !== +Id) arr.push(el);
    });
    setMessageHistory([...arr]);
  };

  useEffect(() => {
    getFunc("focus");
    handleMakeParams("page_size", 20);
  }, [senderId]);
  //Get messages section

  //Get more messages section
  const getMoreFunc = () => {
    const currentSize = +searchParams.get("page_size");
    handleMakeParams("page_size", currentSize + 20);
    getFunc();
  };
  //Get more messages section

  //Sending message section
  const handleKeyDown = (ev) => {
    if (ev.key === "Enter") handleMessage();
  };
  const handleChange = (ev) => {
    setMessage(ev.target.value);
  };
  const handleMessage = () => {
    if (message) {
      if (editData) {
        let obj = JSON.stringify({
          message: message,
        });
        EditMessageConfig(editData.id, obj).then((res) => {
          let arr = messageHistory;
          arr.find((el) => el.id === editData.id).message = message;
          setMessageHistory([...arr]);
        });
      } else {
        sendMessage(
          JSON.stringify({
            chat_id: +senderId,
            message: message,
            type: "message",
          })
        );
        console.log(
          JSON.stringify({
            chat_id: +senderId,
            message: message,
            type: "message",
          })
        );
        messageHistory.length === 0 && setRender(render + 1);
        setTimeout(() => {
          getFunc("focus");
        }, 300);
      }
      setMessage("");
      setEditData(null);
    }
  };
  //Sending message section

  //Use params section
  const handleMakeParams = (key, value) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };
  //Use params section

  //Delete user section
  const confirm = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      className: "chatmessage_confirm_modal",
      content: (
        <div>
          <h2>
            {langObj.you} <span>{name}</span> {langObj.you_want_with}
          </h2>
          <div className="chatmessage_confirm_buttons">
            <Button onClick={closeModal}>{langObj.bekor_qilish}</Button>
            <Button onClick={handleDelete} type="primary" loading={loading}>
              {langObj.ha}
            </Button>
          </div>
        </div>
      ),
    });
  };

  const closeModal = () => {
    Modal.destroyAll();
  };

  const handleDelete = () => {
    setLoading(true);
    DeleteMessagePartnerConfig(senderId)
      .then((res) => {
        getFunc();
        setRender(render + 1);
        searchParams.delete("sender_id");
        searchParams.delete("sender_name");
        searchParams.delete("page_size");
      })
      .finally(() => {
        closeModal();
        setLoading(false);
      });
  };
  //Delete user section

  return (
    <>
      {senderId ? (
        <div className="chatMessage">
          <div className="chatmessage_header">
            {name ? name : searchParams.get("sender_name")}
            <DeleteOutlined onClick={confirm} />
          </div>
          <div className="chatmessage_content">
            {hasmore && (
              <div className="chatmessage_hasmore">
                <DoubleRightOutlined
                  onClick={getMoreFunc}
                  className="chatmessage_hasmore_button"
                />
              </div>
            )}

            {loading && (
              <div>
                <div className="chatmessage_loading_left">
                  <Skeleton.Input
                    active
                    size="large"
                    className="chatmessage_loading_mine"
                  />
                </div>
                <div>
                  <Skeleton.Avatar active size="large" shape="circle" />
                  <Skeleton.Input
                    active
                    size="large"
                    className="chatmessage_loading_notmine"
                  />
                </div>
              </div>
            )}

            {messageHistory.map((item, index) => {
              return (
                <>
                  {item.date ? (
                    <div className="chatmessage_content_date_wrap" key={index}>
                      <div className="chatmessage_content_date">
                        {item.today ? langObj.today : item.date}
                      </div>
                    </div>
                  ) : (
                    <Message
                      key={index + "wwww"}
                      data={item}
                      senderId={+senderId}
                      setMessage={setMessage}
                      setEditData={setEditData}
                      getForDeleteFunc={(val) => getForDeleteFunc(val)}
                      forceFocusInput={forceFocusInput}
                    />
                  )}
                </>
              );
            })}
            <input ref={endingRef} className="chatmessage_ending_ref" />
          </div>
          <div className="chatmessage_footer">
            <div className="chatmessage_write">
              <span>
                <EmojiSvg />
              </span>

              <input
                placeholder={langObj.write_message}
                value={message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={inputRef}
              />

              <UploadSvg />
              <SendSvg onClick={handleMessage} />
            </div>
          </div>
        </div>
      ) : (
        <div className="chatMessage chatmessage_empty">
          <span>{langObj.select_writer}</span>
        </div>
      )}
    </>
  );
}

export default ChatMessage;
