import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import SignupForm from "./SignupForm";
import CheckSmsForm from "./CheckSmsForm";
import SetPasswordForm from "./SetPasswordForm";

function Signup({ tab, render }) {
  const { TabPane } = Tabs;
  const [key, setKey] = useState("1");
  const [data, setData] = useState({});

  useEffect(() => {
    setKey("1");
  }, [tab]);

  return (
    <Tabs defaultActiveKey="1" className="singup-tab" activeKey={key}>
      <TabPane tab="" key="1">
        <SignupForm
          setData={(val) => setData(val)}
          setKey={() => setKey("2")}
          tab={tab}
        />
      </TabPane>
      <TabPane tab="" key="2">
        <CheckSmsForm
          setData={(val) => setData(val)}
          setKey={(key) => setKey(key)}
          key={key}
          data={data}
          render={render}
        />
      </TabPane>
      <TabPane tab="" key="3">
        <SetPasswordForm
          data={data}
          setKey={(key) => setKey(key)}
          key={key}
          render={render}
        />
      </TabPane>
    </Tabs>
  );
}

export default Signup;
