import { message } from "antd";
import axios from "axios";
import { API_URL, SITE_LANG, TOKEN } from "../constants";
import { deleteCookie, getCookie } from "../functions/useCookies";

export const token = getCookie(TOKEN);
export const userData = JSON.parse(localStorage.getItem("user"));

export let host = API_URL;

export let headers = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: token ? `Bearer ${token}` : "",
  Language: localStorage.getItem(SITE_LANG),
};

export let axiosInstance = axios.create({
  baseURL: `${host}/`,
  headers,
  timeout: 100000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      let obj = error.response.data;
      let { status } = error.response;
      if (status === 403 || status === 401) {
        deleteCookie(TOKEN);
        message.error("Iltimos avval tizimga kiring");
        window.location.href = "/signin";
      } else if (status === 404) {
        message.error(
          "Ma'lumotlar topilmadi, iltimos qayta tekshirib ko'ring",
          5
        );
      } else if (status === 500) {
        message.error(
          "Server bilan bog'liq xatolik yuz berdi. Bu haqda ma'sul xodimlarga habar bering",
          5
        );
      } else {
        console.log(obj);
        if (obj.message) {
          message.error(obj.message, 10);
        } else if (obj.error) {
          message.error(obj.error, 10);
        } else if (obj.length > 0) {
          obj.forEach((item) => {
            message.error(item);
          });
        } else {
          for (let key in obj) {
            obj[key].forEach((item) => {
              message.error(item);
            });
          }
        }
      }
    }
    throw error;
  }
);
