import React from "react";

import Share from "./Share";
import Complaint from "../Pages/Complaint";
import {
  AddFavouriteAdvertConfig,
  DeleteAdvertConfig,
  DeleteFavouriteAdvertConfig,
} from "../Connection/server/config/CrudUrls";
import SeeMoreButton from "./Button/SeeMoreButton";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EyeOutlined } from "@ant-design/icons";
import { langData } from "../Languages";
import { ReactComponent as HeartSvg } from "../Images/Svg/heart.svg";
import { ReactComponent as HeartFillSvg } from "../Images/Svg/heart-fill.svg";
import { ReactComponent as EditIcon } from "../Images/Svg/edit.svg";
import { ReactComponent as TrashIcon } from "../Images/Svg/trash.svg";
import { ConfirmModal } from "../Functions";
import { getCookie } from "../Connection/functions/useCookies";
import { TOKEN } from "../Connection/constants";

function Advert({ item, getFunc, user, share }) {
  const token = getCookie(TOKEN);
  const navigate = useNavigate();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];

  //Add to favourities
  const addFavourite = (id) => {
    AddFavouriteAdvertConfig(id).then((res) => {
      toast.success(langObj.elon_saqlanganlarga_qoshildi, {
        autoClose: 2000,
      });
      getFunc();
    });
  };

  //Delete from favourities
  const deleteFavourite = (itemId) => {
    ConfirmModal(
      langObj.elonni_saqlanganlardan_ochirishni_istaysizmi,
      langObj.ha,
      langObj.bekor_qilish,
      () => {
        DeleteFavouriteAdvertConfig(itemId).then((res) => {
          toast.success(langObj.elon_saqlanganlardan_ochirildi, {
            autoClose: 2000,
          });
          getFunc();
        });
      }
    );
  };

  //Delete form server
  const deleteAdver = (id) => {
    ConfirmModal(
      langObj.elonni_ochirishni_istaysizmi,
      langObj.ha,
      langObj.bekor_qilish,
      () => {
        DeleteAdvertConfig(id).then((res) => {
          toast.success(langObj.elon_ochirildi, { autoClose: 2000 });
          getFunc();
        });
      }
    );
  };

  return (
    <div
      className="works_content"
      key={item.id.toString()}
      style={share ? { width: "100%" } : {}}
    >
      <div className="works_name__div">
        <div className="works_name">
          {item.name.slice(0, 40)}
          {item.name.length > 40 ? "..." : ""}
        </div>
        <div className="works_time">{item.created_date}</div>
      </div>
      <div className="works_lover__div">
        {user ? (
          <Link
            to={`/user/adverts/edit/name=${item.name}&${item.id}`}
            key={item.id + "as"}
            className="share_section"
          >
            <EditIcon />
          </Link>
        ) : item.is_saved === true ? (
          <HeartFillSvg
            onClick={() => {
              token ? deleteFavourite(item.id) : navigate("/signin");
            }}
          />
        ) : (
          <HeartSvg
            onClick={() => {
              token ? addFavourite(item.id) : navigate("/signin");
            }}
          />
        )}
        <Share
          path={`http://www.simyogoch.uz/searchworks/work/id=${item.id}`}
        />
        {user ? (
          <TrashIcon
            style={{ cursor: "pointer" }}
            onClick={() => deleteAdver(item.id)}
          />
        ) : (
          <Complaint Id={item.id} />
        )}
      </div>
      <div className="works_price_div">
        {item.from_sum + " - " + item.to_sum}
        {" / "}
        {item.job_type === "one-time"
          ? langObj.bir_martalik
          : item.job_type === "online"
          ? langObj.onlayn
          : item.job_type === "half-time"
          ? langObj.yarim_stavka
          : langObj.doimiy}
      </div>
      <div className="works_description__div">
        {item.about.slice(0, 150)}
        {item.about.length > 150 ? "..." : ""}
      </div>
      <div className="works_footer">
        <div className="works_views">
          <Link
            to={token ? `/searchworks/work/id=${item.id}` : "/signin"}
            key={item.id + "as"}
          >
            <SeeMoreButton />
          </Link>
          <div>
            <EyeOutlined />
            {item.views > 1000
              ? Math.round(item.views / 1000) + "K"
              : item.views}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advert;
