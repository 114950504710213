import React, { useEffect, useState } from "react";

import { Form, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import { LoginConfig } from "../../Connection/server/config/CrudUrls";
import GetMoreButton from "../Button/GetMoreButton";
import { setCookie } from "../../Connection/functions/useCookies";
import { TOKEN } from "../../Connection/constants";
import { useSelector } from "react-redux";
import { langData } from "../../Languages";

function SigninForm({ setKeyMain, keyMain, tab, render }) {
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (val) => {
    console.log(val);
    const obj = val;
    obj.phone_number =
      "998" +
      val.phone_number
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll(" ", "");
    obj.password = val.password.replaceAll(" ", "");
    console.log(obj);

    setLoading(true);
    LoginConfig(obj)
      .then((res) => {
        // deleteCookie("token");
        // handleLoginFunc(res.data);
        setCookie(TOKEN, res.data.access);
        setCookie("userId", res.data.user_data.id);
        let obj = {
          password: val.password,
          ...res.data.user_data,
        };
        localStorage.setItem("user", JSON.stringify(obj));
        // let obj = {
        //   password: value.password,
        //   ...res.data.user_data,
        // };
        // localStorage.setItem("user", JSON.stringify(obj));

        //Send device informs
        // let deviceData = {
        //   name: res.data.user_data.name,
        //   registration_id: "1:39370089721:web:8587e6460bcddca946ee28",
        //   device_id: res.data.user_data.id,
        //   active: true,
        //   type: "web",
        // };
        // const headers = {
        //   "Content-Type": "application/json; charset=utf-8",
        //   Authorization: res.data.access ? `Bearer ${res.data.access}` : "",
        // };
        // axios
        //   .post(`${host}adverts/devices`, deviceData, { headers })
        //   .then((response) => console.log(response));

        window.location.href = "/searchworks";
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    form.resetFields();
    setLoading(false);
  }, [tab, keyMain, render]);

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item
        label={langObj.telefon_raqamingiz}
        name="phone_number"
        rules={[
          {
            required: true,
            message: langObj.telfon_raqami_kiriting,
          },
          {
            pattern: /^\(\d{2}\) \d{3} \d{2} \d{2}$/,
            message: langObj.number_message,
          },
        ]}
      >
        <MaskedInput prefix="+998" mask={"(00) 000 00 00"} size="large" />
      </Form.Item>

      <Form.Item
        label={langObj.parol}
        name="password"
        rules={[
          {
            required: true,
            message: langObj.enter_password,
          },
          {
            min: 6,
            message: langObj.password_error,
          },
        ]}
      >
        <Input.Password
          placeholder={langObj.enter_password2}
          type="text"
          size="large"
        />
      </Form.Item>

      <div className="login-main-button-reset">
        <p onClick={setKeyMain}>{langObj.parolni_unutdingizmi}</p>
      </div>

      <GetMoreButton text={langObj.kirish} loading={loading} />
    </Form>
  );
}

export default SigninForm;
