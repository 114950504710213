import {
  Breadcrumb,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";
import { Link, useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import GetMoreButton from "../Components/Button/GetMoreButton";
import { useSelector } from "react-redux";
import { langData } from "../Languages";
import {
  CreateUserNotificationConfig,
  GetDistrictsConfig,
  GetRegionsConfig,
  GetUserConfig,
  GetUserNotificationConfig,
} from "../Connection/server/config/CrudUrls";
import { toast } from "react-toastify";
import { userId } from "../Connection/constants";

function NoticeSettings() {
  const [form] = Form.useForm();
  const lan = useSelector((state) => state.lang);
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const { Option } = Select;
  let langObj = langData[lan];

  const getFunc = () => {
    GetUserConfig(userId).then((res) => {
      res.data && !res.data.is_active_notification && navigate("/notice");
    });
    GetRegionsConfig().then((res) => {
      let arr = res.data.results.reverse();
      setRegions(arr);
    });
    GetUserNotificationConfig().then((res) => {
      console.log(res);
      let obj = res.data;
      let { keyword, sex, is_advert, is_resume, ...rest } = obj;
      form.setFieldsValue({
        ...rest,
        keyword: keyword.join(" "),
        sex: sex ? [sex] : [],
        type: [is_advert && "work", is_resume && "worker"],
      });
      obj.region && changeRegion(obj.region);
    });
  };

  const changeRegion = (id, second) => {
    GetDistrictsConfig(id).then((res) => {
      setDistricts(res.data.results);
      second && form.setFieldsValue({ district: null });
    });
  };

  useEffect(() => {
    getFunc();
  }, []);

  const handleSubmit = () => {
    form.validateFields().then((val) => {
      console.log(val);
      let obj = val;
      let gender, search;
      let { keyword, sex, type, ...rest } = obj;
      if (sex.length === 1) gender = sex[0];
      else if (sex.length === 2 || sex.length === 0) gender = null;
      search = keyword
        .replace(/,/gi, "")
        .split(" ")
        .filter((el) => el.length > 0);
      let obj2 = {
        ...rest,
        sex: gender,
        keyword: search,
        is_advert: type.includes("work"),
        is_resume: type.includes("worker"),
      };
      CreateUserNotificationConfig(obj2).then((res) => {
        console.log(res);
        toast.success("Sozlamalar muvaffaqiyatli saqlandi");
      });
    });
  };

  return (
    <>
      <Breadcrumb className="breadcrumb_style">
        <Breadcrumb.Item>
          <Link to="/notice">{langObj.notice}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{langObj.notice_settings}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="form_container">
        <div className="notice_header">
          <h1 className="container_title">{langObj.notice_settings}</h1>
        </div>

        <div className="writeBulletin_section notice_setting">
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            className="form"
            form={form}
          >
            <Form.Item name="region" label={langObj.viloyat} className="half">
              <Select
                size="large"
                onChange={changeRegion}
                placeholder={langObj.viloyat}
              >
                {regions.map((res) => {
                  return (
                    <Option value={res.id} key={res.name}>
                      {res.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="district" label={langObj.tuman} className="half">
              <Select size="large" placeholder={langObj.tuman}>
                {districts.map((res) => {
                  return (
                    <Option value={res.id} key={res.name}>
                      {res.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="job_type"
              label={langObj.ish_turi}
              className="half"
            >
              <Radio.Group className="writeBulletin_workType" size="large">
                <Radio value="one-time">{langObj.bir_martalik}</Radio>
                <Radio value="permanent">{langObj.doimiy}</Radio>
                <Radio value="online">{langObj.onlayn}</Radio>
                <Radio value="half-time">{langObj.yarim_stavka}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="experience"
              label={langObj.tajriba}
              className="half"
            >
              <Radio.Group className="writeBulletin_workType" size="large">
                <Radio value="optional">{langObj.kerak_emas}</Radio>
                <Radio value="primary">{langObj.boshlangich}</Radio>
                <Radio value="middle">{langObj.ortacha}</Radio>
                <Radio value="high">{langObj.yuqori}</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="form half">
              <Form.Item
                name="from_sum"
                label={langObj.ish_haqqi}
                className="half"
              >
                <InputNumber
                  placeholder="Min"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              ~
              <Form.Item name="to_sum" label=" " className="half">
                <InputNumber
                  placeholder="Max"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </div>

            <div className="form half">
              <Form.Item name="from_age" label={langObj.yosh} className="half">
                <InputNumber placeholder="Min" />
              </Form.Item>
              ~
              <Form.Item name="to_age" label=" " className="half">
                <InputNumber placeholder="Max" />
              </Form.Item>
            </div>

            <Form.Item name="sex" label={langObj.jins} className="half">
              <Checkbox.Group>
                <div
                  className="checkbox_row writeResume_universitet__checked"
                  style={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
                >
                  <div className="writeBulletin_jins">{langObj.jinsi}</div>
                  <div className="checkbox_row">
                    <Checkbox
                      value="woman"
                      style={{ lineHeight: "30px" }}
                      key="genderWoman"
                    >
                      {langObj.ayol}
                    </Checkbox>
                    <Checkbox
                      value="man"
                      style={{ lineHeight: "30px" }}
                      key="genderMan"
                    >
                      {langObj.erkak}
                    </Checkbox>
                  </div>
                </div>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              name="keyword"
              label={langObj.search_words}
              className="full"
              rules={[
                {
                  required: true,
                  message: langObj.search_words_message,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="type"
              label={langObj.search}
              className="writeBulletin_workDay"
              rules={[
                {
                  required: true,
                  message: langObj.search_message,
                },
              ]}
            >
              <Checkbox.Group>
                <Checkbox value="work">{langObj.search_work}</Checkbox>
                <Checkbox value="worker">{langObj.search_worker}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>
        <GetMoreButton text={langObj.save_settings} onClick={handleSubmit} />
      </div>
    </>
  );
}

export default NoticeSettings;
