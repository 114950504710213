import { Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MakeComplainConfig } from "../Connection/server/config/CrudUrls";
import { langData } from "../Languages";
import "../Styles/Complain.css";
import { ReactComponent as ComplainSvg } from "../Images/Svg/complain.svg";
import { ReactComponent as CorrectSvg } from "../Images/Svg/correct.svg";
import { token } from "../Connection/server/host";
import { useNavigate } from "react-router-dom";

function Complaint({ Id }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const lan = useSelector((state) => state.lang);
  let langObj = langData[lan];
  const [data, setData] = useState([
    {
      id: 0,
      text: "Shubhali,spam yoki soxta",
      error: false,
    },
    {
      id: 1,
      text: "Axloqsiz so'zlar ishlatilgan",
      error: false,
    },
    {
      id: 2,
      text: "Noqonuniy harakat",
      error: false,
    },
    {
      id: 3,
      text: "Boshqalar",
      error: false,
    },
  ]);

  const complainFunc = () => {
    let arr = [];
    data.forEach((item) => {
      if (item.error && item.id !== 3) arr.push(item.text);
    });
    if (data[3].error) {
      form.validateFields().then((val) => {
        arr.push(val.text);
        complainConfigFunc(arr);
      });
    } else complainConfigFunc(arr);
  };

  const complainConfigFunc = (arr) => {
    let text = arr.join(", ");
    if (arr.length === 0) message.error("Iltimos shikoyat sababini tanlang");
    else
      MakeComplainConfig({ text: text, advert: Id }).then((res) => {
        toast.success(langObj.shikoyat_jonatildi, { autoClose: 2000 });
        handleCancel();
      });
  };

  const changeError = (id) => {
    let arr = data;
    arr[id].error = !arr[id].error;
    setData([...data]);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    if (token) {
      setIsModalVisible(true);
    } else {
      navigate("/signin");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClear = () => {
    data.forEach((item) => {
      item.error = false;
    });
    form.resetFields();
  };

  useEffect(() => {
    handleClear();
  }, [isModalVisible]);

  return (
    <>
      <div className="complain_section">
        <ComplainSvg onClick={showModal} />
        <Modal
          title=""
          visible={isModalVisible}
          onCancel={handleCancel}
          width={375}
          footer=""
          className="complain_modal"
        >
          <h1 className="container_title">{langObj.shikoyat_qilish}</h1>
          <div className="complaint_selections">
            {data.map((item) => {
              return (
                <div
                  onClick={() => changeError(item.id)}
                  className={
                    item.error
                      ? "complain_selector complain_selector_aktive"
                      : "complain_selector"
                  }
                  key={item.text}
                >
                  <p>{item.text}</p>
                  {item.error ? <CorrectSvg /> : <span></span>}
                </div>
              );
            })}
          </div>
          <div className="complain_form">
            <div className="complain_content">
              {data[3].error && (
                <Form form={form} layout="vertical">
                  <Form.Item
                    name="text"
                    label={langObj.shikoyat_matni}
                    rules={[
                      { required: true, message: langObj.shikoyat_matni },
                    ]}
                  >
                    <Input.TextArea
                      rows={6}
                      placeholder={langObj.nima_uchun_shikoyat_qilayapsiz}
                      className="writeBulletin_description complain_textarea"
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
          <div className="buttons-section">
            <div className="btn-box editprofile_main_button">
              <div className="btn-container">
                <button
                  onClick={complainFunc}
                  className="btn btn-moving-gradient btn-moving-gradient--green"
                >
                  {langObj.shikoyat_qilish}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Complaint;
