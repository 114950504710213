import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";
import { WEB_SOCKET_URL } from "../../Connection/constants";
import { GetUserConfig } from "../../Connection/server/config/CrudUrls";
import { token } from "../../Connection/server/host";

export const WebSocketGlobal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { lastMessage } = useWebSocket(`${WEB_SOCKET_URL}?auth=${token}`);

  const showMessage = (message) => {
    if (location.pathname !== "/message" && token) {
      const data = JSON.parse(message.data);
      const id = data.from_chat_id;
      if (id)
        GetUserConfig(id).then((res) => {
          toast.success(
            <div
              className="chatmessage_toast_div"
              onClick={() =>
                navigate(
                  `/message?sender_id=${id}&sender_name=${res.data.name}`
                )
              }
            >
              <div className="chatmessage_toast_img">
                {res.data.photo ? (
                  <img src={res.data.photo} alt="error img" />
                ) : (
                  <div className="chatmessage_person_img_name">
                    {res.data.name[0]}
                  </div>
                )}
              </div>
              <div className="chatmessage_toast_info">
                <h3>{res.data.name}</h3>
                <p>
                  {data.message.slice(0, 50)}
                  {data.message.length > 50 && "..."}
                </p>
              </div>
            </div>,
            {
              autoClose: 100000,
              className: "chatmessage_toast",
              position: "bottom-right",
            }
          );
        });
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      showMessage(lastMessage);
    }
  }, [lastMessage]);

  return <div></div>;
};
