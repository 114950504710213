import React, { useState } from "react";
import { Tabs } from "antd";

import Reset from "./Reset";
import SigninSignup from "./Signin&Signup";

function LoginPage() {
  const { TabPane } = Tabs;
  const [key, setKey] = useState("login");
  const [render, setRender] = useState(1);

  return (
    <Tabs className="singup-tab main-tab" activeKey={key}>
      <TabPane tab="" key="reset">
        <Reset
          setKeyMain={() => setKey("login")}
          keyMain={key}
          render={render}
        />
      </TabPane>
      <TabPane tab="" key="login">
        <SigninSignup
          setKeyMain={() => setKey("reset")}
          keyMain={key}
          render={render} 
        />
      </TabPane>
    </Tabs>
  );
}

export default LoginPage;
