import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import ResetForm from "./ResetForm";
import SetPasswordForm from "./SetPasswordForm";
import CheckSmsForm from "./CheckSmsForm";

function Reset({ setKeyMain, render, keyMain }) {
  const { TabPane } = Tabs;
  const [key, setKey] = useState("1");
  const [data, setData] = useState({});

  useEffect(() => {
    setKey("1");
  }, [render]);

  return (
    <div className="login-main">
      <Tabs className="singup-tab" activeKey={key}>
        <TabPane tab="" key="1">
          <ResetForm
            setData={(val) => setData(val)}
            setKey={() => setKey("2")}
            setKeyMain={() => setKeyMain()}
            render={render}
            keyMain={keyMain}
          />
        </TabPane>
        <TabPane tab="" key="2">
          <CheckSmsForm
            setKey={(key) => setKey(key)}
            setData={(val) => setData(val)}
            data={data}
            key={key}
            render={render}
            reset
          />
        </TabPane>
        <TabPane tab="" key="3">
          <SetPasswordForm
            setKey={(key) => setKey(key)}
            setKeyMain={() => setKeyMain("login")}
            data={data}
            key={key}
            render={render}
            reset
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Reset;
